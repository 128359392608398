import { useState } from "react";
import axiosClient from "../lib/axiosClient"
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import useCookies from "./useCookies";
import { useTranslation } from "react-i18next";
import { useAlerts } from "../context/AlertsContext";


export const useAuth = () => {

    const { t } = useTranslation()

    const { addAlert } = useAlerts();


    const navigate = useNavigate()

    const { removeCookie, setCookie, getCookie } = useCookies()


    // check if found cookie "isAuth" or not
    const isAuth = getCookie("isAuth");


    // State to store 'isLoading' value, for all requests in this page
    const [isLoading, setIsLoading] = useState(false)



    // get & set sanctum tokens
    const csrf = () => axiosClient.get('sanctum/csrf-cookie');


    /**
     * Get auth user data
     */
    const authUser = useSWR(isAuth ? "api/auth/user" : null, () =>
        axiosClient.get(isAuth ? "api/auth/user" : null)
            .then(async res => {
                const userData = res.data;

                return userData;
            })
            .catch(async error => {
                if (error.response.data.message === 'Unauthenticated.') {
                    removeCookie("isAuth");
                    removeCookie("_uln");

                    navigate('/auth/login')
                }
            })
    );


    /**
     * Login
    */
    const login = async ({ setErrors, ...props }) => {
        try {
            setIsLoading(true)

            setErrors({})

            await csrf();

            const { data } = await axiosClient.post('api/auth/login', props);

            if (data?.is_ok === true) {

                // save isAuth cookie
                setCookie("isAuth", true);

                setCookie("_eolla", props.email);

                setCookie("_uln", data?.data?.token ?? "");

                setCookie("currency", data?.data?.currencyCode || 'USD');

                // refresh user data
                authUser.mutate()

                // move to the home page
                navigate('/clients')
                addAlert(data?.message ?? t('Success!'), 'success');
            } else {
                // show danger message
                addAlert(data?.message ?? t('Something was wrong!'), 'error');
            }

        } catch (error) {
            // return validation errors
            if (error?.response?.data?.type === "validation") {
                setErrors(error?.response?.data?.errors ?? {});
            }

            else if (error?.response?.data?.is_ok === false) {
                // show danger message
                addAlert(error?.response?.data?.message ?? t('Something was wrong!'), 'error');
            }

        } finally {
            setIsLoading(false)
        }

        return {};
    }



    /**
     * Register
    */
    const register = async ({ setErrors, ...props }) => {
        try {
            setIsLoading(true)

            setErrors({})

            await csrf();

            const { data } = await axiosClient.post('api/auth/register', props);

            if (data.is_ok === true) {

                addAlert(data?.message ?? t('Success!'), 'success');

                await login({ setErrors, ...props })
            } else {
                addAlert(data?.message ?? t('Something was wrong!'), 'error');
            }

        } catch (error) {
            // return validation errors
            if (error?.response?.data?.type === "validation") {
                setErrors(error?.response?.data?.errors ?? {});
            }

            else if (error?.response?.data?.is_ok === false) {
                addAlert(error?.response?.data?.message ?? t('Something was wrong!'), 'error');
            }

        } finally {
            setIsLoading(false)
        }
    }


    /**
     * Logout
     */
    const logout = async () => {
        try {
            setIsLoading(true)

            await csrf();

            const { data } = await axiosClient.post('api/auth/logout');

            if (data.is_ok === true) {
                removeCookie("isAuth");
                removeCookie("_uln");

                navigate('/auth/login')
            }

        } catch (error) {
            if (error.response.data.message === 'Unauthenticated.') {
                removeCookie("isAuth");
                removeCookie("_uln");
            }

        } finally {
            setIsLoading(false)
        }
    }


    /**
     * check user role
    */
    const isOwner = () => {
        if (authUser?.data) {
            if (authUser?.data?.role === "owner") {
                return true;
            }
        }
        return false;
    }

    const allowedFor = (arrString) => {
        const _roles = arrString?.split(',') ?? [];

        if (authUser?.data) {
            const userRole = authUser?.data?.role;
            return _roles.includes(userRole);
        }
        return false;
    }


    return {
        isAuth,
        authUser,
        csrf,
        login,
        register,
        isLoading,
        logout,
        isOwner,
        allowedFor
    };
}