import { Link } from "react-router-dom";
import { Menu, Stars } from "../../icons";
import AvatarDropDown from "./AvatarDropDown";
import { useAuth } from "../../hooks/useAuth";
import AddNewDropDown from "./AddNewDropDown";
import LanguagesSwitcher from "./LanguagesSwitcher";
import useHtmlAttributes from "../../hooks/useHtmlAttributes";
import { useTranslation } from "react-i18next";
import { isNull } from "../../Helpers/utils";


export default function NavBar({ toogleSideBar }) {

    const { authUser, logout, isLoading } = useAuth();

    const { selectedLang, changeLang } = useHtmlAttributes();

    // handle logout click
    const handleLogout = async () => {
        await logout()
    }

    return (
        <nav className="sticky top-0 w-full lg:min-h-[70px] min-h-[60px] bg-white flex items-center justify-between flex-row border-b border-primaryBorder px-0 py-0 z-[1001]">
            <div className="flex items-center max-lg:px-3">
                <div className="flex items-center max-lg:ltr:pr-3 max-lg:rtl:pl-3 lg:min-w-[200px]">
                    <div className="p-3 max-lg:hidden">
                        <button
                            onClick={toogleSideBar}
                            className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                            <Menu className="lg:w-6 lg:h-6 w-5 h-5" />
                        </button>
                    </div>
                    <Link to={'/'} className="w-fit">
                        <img src="/images/logo_name.png" alt="aratly logo" className="h-[30px] w-auto" />
                    </Link>
                </div>

                <SubscriptionRemander userData={authUser?.data ?? {}} />
            </div>


            <div className="flex items-center gap-2 px-3">
                <div className="flex items-center">
                    {/* <button
                        className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <Message className="lg:w-6 lg:h-6 w-5 h-5" />
                    </button>

                    <button
                        className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <Notification className="lg:w-6 lg:h-6 w-5 h-5" />
                    </button> */}

                    {/* <button
                        onClick={() => alert('true')}
                        className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <div className="rounded-full lg:w-6 lg:h-6 w-5 h-5">
                            <img src="/flags/ar.webp" alt="flag" className="w-full h-full rounded-full" />
                        </div>
                    </button> */}


                    {/* Hide on Small screens */}
                    <AddNewDropDown />


                    <LanguagesSwitcher
                        selectedLang={selectedLang}
                        changeLang={changeLang}
                        buttonStyle="1"
                    />
                </div>

                <AvatarDropDown authUser={authUser} logout={handleLogout} inProgress={isLoading} />
            </div>
        </nav>
    )
}

const SubscriptionRemander = ({ userData }) => {

    const { t } = useTranslation()

    const { role, remaining_trial_days, remaining_subscription_days } = userData ?? {};

    return role === "owner" && (
        <>
            {!isNull(userData) &&
                <Link to={'/settings/subscription'}>
                    {remaining_trial_days >= -14 && remaining_trial_days <= 0 ? (
                        // <div className="h-10 bg-primary rounded-full py-1 ltr:pl-1 rtl:pr-1 lg:ltr:pr-4 ltr:pr-3  lg:rtl:pl-4 rtl:pl-3 flex items-center gap-2 select-none shadow-sm sm:shadow-md active:scale-[.99]">
                        //     <div className="h-full lg:px-3 px-2 rounded-full bg-white text-primary flex items-center justify-center textBody-s1">
                        //         <span>{remaining_trial_days?.toString().replace('-', '')}</span>
                        //     </div>
                        //     <span className="text-white textBody-s2 ltr:pr-3 rtl:pl-3">{t("Days left")}</span>
                        // </div>
                        <></>
                    ) : (
                        <>
                            {!isNull(remaining_subscription_days) && remaining_subscription_days >= -5 ? (
                                <div className="h-10 bg-primary rounded-full py-1 ltr:pl-1 rtl:pr-1 lg:ltr:pr-4 ltr:pr-3  lg:rtl:pl-4 rtl:pl-3 flex items-center gap-2 select-none shadow-sm sm:shadow-md active:scale-[.99]">
                                    <div className="h-full lg:px-2 px-2 rounded-full bg-white text-primary flex items-center justify-center textBody-s1">
                                        <Stars className="w-4 h-4" />
                                    </div>
                                    <span className="text-white textBody-s2 ltr:pr-3 rtl:pl-3">{t("Upgrade")}</span>
                                </div>
                            ) : null}
                        </>
                    )}
                </Link>}
        </>
    )
}