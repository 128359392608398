import { isNull } from "../../../Helpers/utils"



export default function Textarea({
    title="Title",
    placeholder="",
    value="",
    change= () => {},
    error=null,
    isOptional = false,
}) {

    const borderStyle = !isNull(error) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90 outline-primary/80';

    return (
        <label className="flex flex-col gap-2">
            <span className="textBody-s1">{title} {isOptional && <i className="textBody-xs3">(Optional)</i>}</span>
            <textarea
                className={`p-3 rounded-lg transition-colors duration-300 resize-none h-24 max-h-24 min-h-24 ${borderStyle}`}
                placeholder={placeholder}
                onChange={change}
                value={value}
            />
            {!isNull(error) && <p className="textBody-s2 text-red-600">{error}</p>}
        </label>
    )
}