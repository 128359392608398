import { Delete1, Spinner } from "../../../../../icons";
import { getFullName, isNull, n, renderImage } from "../../../../../Helpers/utils";
import Avatar from "../../../../Shared/Avatar";
import AddNewNote from "./AddNewNote";
import { useAlerts } from "../../../../../context/AlertsContext";
import dayjs from "../../../../../lib/dayjs";
import { useState } from "react";
import Confirmation from "../../../../Shared/Confirmation";
import Pagination from "../../../../Pagination";
import { GetAllNotesByClient, RemoveNotById } from "../../../../../lib/api";
import { useTranslation } from "react-i18next";


export default function Notes({ clientData }) {

    const { t } = useTranslation()

    const { addAlert } = useAlerts();

    const { id } = clientData;

    const [pageNumber, setPageNumber] = useState(1)

    // Get notes list by client id
    const { data, loading, refresh } = GetAllNotesByClient({ client_id: id, page: pageNumber });
    const notesList = data?.data?.data ?? [];


    // handle remove subscription
    const [showConfirmationModalToRemove, setShowConfirmationModalToRemove] = useState(false)
    const [noteIdToRemove, setNoteIdToRemove] = useState("")
    const [noteDeleting, setNoteDeleting] = useState(false)
    const handleRemoveNote = async () => {
        if (isNull(noteIdToRemove)) return;

        const data = {
            note_id: noteIdToRemove,
            client_id: id,
        };

        setNoteDeleting(true)

        const { is_ok, message, type } = await RemoveNotById({}, data);

        if (type === "validation") {
            addAlert(t('Something was wrong!'), 'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        setNoteDeleting(false)

        if (is_ok) {
            refresh()
            setNoteIdToRemove("")
        }
    }

    return (
        <div className="min-h-screen">
            {/* Notes Header */}
            <div className="max-lg:hidden sticky top-0 lg:py-4 py-2 lg:px-6 md:px-4 p-2 flex items-center justify-between border-b border-primaryBorder/50 bg-primary2">
                <h2 className="textHeader-s">{t("Notes")}</h2>
            </div>

            {/* Add New Note */}
            <AddNewNote
                clientId={id}
                refreshNotes={refresh}
                addAlert={addAlert}
            />

            {/* Notes List */}
            {loading ? suspense() :
                <div className="py-4 lg:px-6 md:px-4 p-2 flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        {!isNull(notesList) && (
                            <>
                                {notesList?.map((note, index) => (
                                    <div
                                        key={note.id}
                                        className="p-4 bg-white border border-primaryBorder/50 rounded-lg flex flex-col gap-2">
                                        <div className="flex gap-2">
                                            <div className="">
                                                <Avatar
                                                    src={renderImage(note?.client?.avatar)}
                                                    size={'w-12 h-12'}
                                                    alt={getFullName(note.client)}
                                                />
                                            </div>
                                            <div className="w-full [&>*]:whitespace-nowrap">
                                                <h3 className="textBody-s3">{getFullName(note.client)}</h3>
                                                <span className="textBody-xs3 text-secondaryTextColor">{dayjs(note.created_at).format("D MMMM, YYYY")} <span>({dayjs(note.created_at).fromNow()})</span></span>
                                            </div>
                                            <button
                                                disabled={noteDeleting}
                                                onClick={() => {
                                                    setShowConfirmationModalToRemove(true)
                                                    setNoteIdToRemove(n(note.id))
                                                }}
                                                className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor/30 hover:text-red-300 cursor-pointer outline-none btn_effect">
                                                <Delete1 className="w-5 h-5" />
                                            </button>
                                        </div>
                                        <div className="">
                                            <p className="textBody-s3">{note.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>

                    <Pagination
                        currentPageNumber={data?.data?.current_page}
                        lastPageNumber={data?.data?.last_page}
                        onChange={(n) => setPageNumber(n)}
                        disabled={loading}
                    />
                </div>}

            {showConfirmationModalToRemove &&
                <Confirmation
                    onClose={() => {
                        setShowConfirmationModalToRemove(false)
                        setNoteIdToRemove("")
                    }}
                    onConfirm={() => {
                        handleRemoveNote()
                        setShowConfirmationModalToRemove(false)
                    }}
                    headingLine={t("Delete Note")}
                    message={t("Are you sure you want to delete this note?")}
                />}
        </div>
    )
}


const suspense = () => {
    return (
        <div className="p-6 min-h-96 flex items-center justify-center">
            <Spinner className="w-10 h-10 animate-spin" />
        </div>
    )
}