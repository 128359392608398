import useCookies from "./useCookies";



export const useCurrency = () => {

    const { getCookie } = useCookies();


    // Get current currency from cookies
    const currencyCode = getCookie('currency') || 'USD';

    
    return {
        Currency: currencyCode
    };
}