import { useTranslation } from "react-i18next";
import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import { useCurrency } from "../../../../hooks/useCurrency";

export default function SportsList({
    sports = [],
    selectItem = () => { },
    canViewPrice
}) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()


    const renderStatusBadge = (status) => {
        switch (status) {
            case "active":
                return <div className="w-2 h-2 rounded-full bg-green-400"></div>;
            case "inactive":
                return <div className="w-2 h-2 rounded-full bg-red-400"></div>;
            default:
                return <div className="w-2 h-2 rounded-full bg-gray-400"></div>;
        }
    }

    return isNull(sports)
        ? <NotResultsFound marginClassNames="my-8" title="No results" desc="No sports found on this page" desc2="try adding new sports" />
        : (
            <table className="mt-8 w-full">
                <tbody className="border-l border-r border-b border-primary/10">
                    {sports.length > 0 &&
                        sports.map((sport) => {
                            return (
                                <tr
                                    onClick={() => selectItem(sport.id)}
                                    key={sport.id}
                                    className="[&>*]:px-4 [&>*]:py-5 border-t border-primary/10 cursor-pointer select-none transition-all duration-200 ease-in bg-white hover:bg-primary2">
                                    <td>
                                        <div className="flex items-center gap-4">
                                            {renderStatusBadge(sport.status)}
                                            <h1 className="textBody-m1 line-clamp-1">{sport.name}</h1>
                                        </div>
                                    </td>
                                    <td className={canViewPrice ? '' : 'ltr:text-right rtl:text-left'}>
                                        <span className="textBody-s3 text-secondaryTextColor">{`${sport?.total_clients ?? 0} ${t("Client")}`}</span>
                                    </td>
                                    {canViewPrice &&
                                        <td className="ltr:text-right rtl:text-left">
                                            <span className="textBody-s1">{`${sport?.total_paid_price ?? 0} ${Currency}`}</span>
                                        </td>}
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        )
}