import { useState } from "react";
import { ArrowLeft1, ArrowRight1, Menu1 } from "../../../../icons";
import Avatar from "../../../Shared/Avatar";
import { renderImage } from "../../../../Helpers/utils";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../../../hooks/useCurrency";


export default function MobileDropDownMenu({ clientData, linksList = [], contentSection, setContentSection, closeModal }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    const { firstname, lastname, avatar, total_paid_price } = clientData;

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen)

    return (
        <div className="block lg:hidden">
            {/* Button */}
            <div className="md:p-4 p-2 flex justify-between">
                <div className="flex items-center gap-1">
                    <button onClick={closeModal} className="bg-transparent rtl:rotate-180 text-primaryTextColor rounded-lg transition-all duration-100 ease-in active:scale-[.97] flex items-center textBody-m1">
                        <ArrowLeft1 className="w-5 h-5" />
                    </button>
                    <div
                        onClick={() => setContentSection('details')}
                        className="flex items-center gap-1 select-none cursor-pointer">
                        <Avatar
                            src={renderImage(avatar)}
                            size={'w-10 h-10'}
                            alt={`${firstname} ${lastname}`}
                        />
                        <div className="flex flex-col">
                            <h3 className="textBody-s1 line-clamp-1">{`${firstname} ${lastname}`}</h3>
                            <span className="text-[#4CAF50] textBody-xs2">{`${total_paid_price ?? 0} ${Currency}`}</span>
                        </div>
                    </div>
                </div>

                <div className="flex items-center gap-2">
                    <button
                        onClick={() => setContentSection('pay')}
                        className="lg:hidden bg-transparent hover:bg-primaryBorder/30 border border-primaryBorder text-primaryTextColor rounded-lg py-2 px-4 transition-all duration-100 ease-in active:scale-[.99] flex items-center gap-1 lg:textBody-m1 textBody-s1 whitespace-nowrap">
                        {t("Pay Now")}
                    </button>

                    <button onClick={toggle} className="bg-transparent hover:bg-primaryBorder/30 border-[1.5px] border-primaryBorder text-primaryTextColor rounded-lg p-2 transition-all duration-100 ease-in active:scale-[.97] flex items-center textBody-m1">
                        <Menu1 className="w-4 h-4" />
                    </button>
                </div>
            </div>

            {/* Modal */}
            {isOpen &&
                <>
                    <div onClick={toggle} className={`bg-black/90 fixed top-0 left-0 w-full h-full z-[99] animation-01`}></div>
                    <div className={'w-full fixed -translate-x-2/4 left-2/4 bottom-0 z-[100] p-4 flex flex-col gap-4 animation-01'}>
                        <div className={`bg-white textBody-s3 py-2 w-full rounded-lg gap-1 justify-between flex flex-col overflow-hidden [&>*]:w-full [&>*]:flex [&>*]:items-center [&>*]:justify-between [&>*]:select-none [&>*]:py-3 [&>*]:px-4`}>
                            {linksList?.map((link, index) => {

                                const chackIsActive = contentSection === link.slug;

                                return (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            setContentSection(link.slug)
                                            toggle()
                                        }}
                                        className={`px-2.5 py-2.5 flex items-center textBody-s3 line-clamp-1 text-primaryTextColor ${chackIsActive ? 'bg-primary3' : 'hover:bg-primary2'}`}>
                                        <p className="line-clamp-1">{link.label}</p>
                                        <ArrowRight1 className="w-4 h-4" />
                                    </button>
                                )
                            })}
                        </div>

                        <button
                            onClick={toggle}
                            className="bg-white outline-none p-3 rounded-lg textBody-s3">
                            {t('Close')}
                        </button>
                    </div>
                </>}
        </div>
    )
}