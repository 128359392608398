import { useEffect, useState } from "react";
import FilterModal from "../../../Shared/FilterModal";
import { useTranslation } from "react-i18next";


export default function FilterPopup({ params, setParams, toggleModal }) {

    const { t } = useTranslation()

    const [gender, setGender] = useState("")
    const [sort_by, setSort_by] = useState("")


    useEffect(() => {
        setGender(params?.gender ?? "")
        setSort_by(params?.sort_by ?? "")
    }, [params])


    // clear all filter by one click
    const clearAllFilters = () => {
        setParams({})
        toggleModal()
    }


    // rearrange selected data and research
    const handleFilterData = () => {
        const data = {
            gender,
            sort_by
        };

        // return non-null keys only
        const body = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '')
        );

        setParams(body)
    }


    return (
        <FilterModal
            onClose={toggleModal}
            onSave={handleFilterData}
            clearAllFilters={clearAllFilters}
        >
            <div className="my-4 flex flex-col gap-4">
                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">{t("Gender")}</span>
                    <select
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                        <option value="">{t("All")}</option>
                        <option value="male">{t("Male")}</option>
                        <option value="female">{t("Female")}</option>
                    </select>
                </label>

                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">{t("Sort by")}</span>
                    <select
                        value={sort_by}
                        onChange={(e) => setSort_by(e.target.value)}
                        className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                        <option value="desc">{t("Newest First")}</option>
                        <option value="asc">{t("Oldest First")}</option>
                    </select>
                </label>
            </div>
        </FilterModal>
    )
}