import { useNavigate } from "react-router-dom";
import Avatar from "../../components/Shared/Avatar";
import { AddImage } from "../../icons";
import { useEffect, useState } from "react";
import { isNull } from "../../Helpers/utils";
import Input from "../../components/Shared/Input";
import { useAlerts } from "../../context/AlertsContext";
import Button from "../../components/Shared/Button";
import PhoneInput from "../../components/Shared/PhoneInput";
import { CreateNewTeamMember } from "../../lib/api";
import { useTranslation } from "react-i18next";



const NewMember = () => {

    const { t } = useTranslation()

    const navigate = useNavigate();
    const returnBack = () => navigate(-1);


    const { addAlert } = useAlerts();

    const [avatar, setAvatar] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [gender, setGender] = useState("")
    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)


    // Generate a Random Password.
    function randomPassword() {
        const length = 10;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
        const charactersLength = characters.length;
        let randomStr = '';
        for (let i = 0; i < length; i++) {
            randomStr += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        setPassword(randomStr);
    }


    useEffect(() => {
        randomPassword()
    }, [])


    // handle change user avatar
    const handleChangeAvatar = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result); // Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            setAvatar("");
        }
    }


    // reset  or empty data after create new team member account
    const resetData = () => {
        setAvatar("")
        setFirstname("")
        setLastname("")
        setPhone("")
        setEmail("")
        randomPassword()
        setGender("")
    }


    // handle click 'saveNewMember'
    const saveNewMember = async () => {

        const data = {
            avatar,
            firstname,
            lastname,
            phone: isNull(phone) ? "" : `${phoneCode} ${phone}`,
            email,
            password,
            gender,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await CreateNewTeamMember(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            resetData();
            navigate('/team')
        }
    }

    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="mt-4 w-full">
                <div className="w-full flex justify-between items-end">
                    {/* Text */}
                    <div className="flex flex-col gap-1">
                        <h2 className="flex items-center textHeader-l">
                            {t("Add new team member")}
                        </h2>
                    </div>

                    {/* Buttons */}
                    <div className="max-lg:hidden flex items-center gap-2">
                        <Button
                            name={t("Cancel")}
                            onClick={returnBack}
                            disabled={inProgress}
                            styleType="2"
                        />
                        <Button
                            name={t("Save")}
                            onClick={saveNewMember}
                            disabled={inProgress}
                            isLoading={inProgress}
                            styleType="1"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-6 w-full grid lg:grid-cols-[1fr_300px] grid-cols-1 gap-4">
                <div className="">
                    <div className="bg-white border border-primaryBorder rounded-lg p-2 md:p-4 lg:p-6">
                        <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                            <Input
                                type={"text"}
                                title={t("First name")}
                                placeholder={""}
                                value={firstname}
                                change={(e) => setFirstname(e.target.value)}
                                error={errors.firstname ?? ''}
                            />
                            <Input
                                type={"text"}
                                title={t("Last name")}
                                placeholder={""}
                                value={lastname}
                                change={(e) => setLastname(e.target.value)}
                                error={errors.lastname ?? ''}
                            />
                        </div>

                        <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                            <Input
                                type={"email"}
                                title={t("Email address")}
                                placeholder={""}
                                value={email}
                                change={(e) => setEmail(e.target.value)}
                                error={errors.email ?? ''}
                            />
                            <PhoneInput
                                title={t("Phone number")}
                                placeholder={""}
                                phoneNumber={phone}
                                changePhoneNumber={(p) => setPhone(p)}
                                phoneCode={phoneCode}
                                changePhoneCode={(c) => setPhoneCode(c)}
                                error={errors.phone ?? ''}
                            />
                        </div>

                        {/* <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6"> */}
                        <label className="flex flex-col gap-2">
                            <span className="textBody-s1">{t("Gender")}</span>
                            <select
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                                <option value="" disabled></option>
                                <option value="male">{t("Male")}</option>
                                <option value="female">{t("Female")}</option>
                            </select>
                            {!isNull(errors?.gender) && <p className="textBody-s2 text-red-600">{errors?.gender}</p>}
                        </label>
                        {/* </div> */}
                    </div>
                </div>

                <div className="">
                    <div className="bg-white border border-primaryBorder rounded-lg p-2 md:p-4 lg:p-6">
                        <div className="w-full border-b border-primaryBorder/50 mb-4 pb-4">
                            <div className="relative w-fit overflow-hidden mx-auto">
                                <Avatar
                                    src={avatar}
                                    size={'w-32 h-32'}
                                    alt={" "}
                                />
                                <label className={`absolute cursor-pointer flex items-center justify-center transition-colors duration-200 p-2 -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 bg-black/0 hover:bg-black/10 text-primary/60 rounded-full w-full h-full`}>
                                    <input type="file" onChange={handleChangeAvatar} accept="image/png, image/jpg, image/jpeg" hidden />
                                    {isNull(avatar) && <AddImage className="w-5 h-5" />}
                                </label>
                            </div>
                        </div>

                        <Input
                            type={"text"}
                            title={t("Password")}
                            placeholder={""}
                            value={password}
                            change={(e) => setPassword(e.target.value)}
                            error={errors.password ?? ''}
                        />
                    </div>
                </div>
            </div>


            {/* Buttons */}
            <div className="lg:hidden mb-4 grid grid-cols-1 gap-2 mt-6">
                <Button
                    name={t("Save")}
                    onClick={saveNewMember}
                    disabled={inProgress}
                    isLoading={inProgress}
                    styleType="1"
                />
                <Button
                    name={t("Cancel")}
                    onClick={returnBack}
                    disabled={inProgress}
                    styleType="2"
                />
            </div>
        </div>
    )
}
export default NewMember;