import { Check } from "../../icons";
import Button from "../../components/Shared/Button"
import { useNavigate } from "react-router-dom";
import { isNull, subscriptionPlansList } from "../../Helpers/utils";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";


export default function PlatformSubscription() {

    const { authUser } = useAuth();

    const { t } = useTranslation()

    const navigate = useNavigate()

    // render plan name
    const renderPlanName = (plan = {}) => {
        const currentLang = i18next?.language || "en";

        if (currentLang === "ar") {
            return plan?.ar_name;
        } else if (currentLang === "en") {
            return plan?.en_name;
        } else if (currentLang === "fr") {
            return plan?.fr_name;
        } else {
            return "#";
        }
    }

    useEffect(() => {
        if (!isNull(authUser?.data)) {
            if (authUser?.data?.role !== "owner") {
                navigate('/clients')
            }
        }
    }, [authUser, navigate])

    return (
        <div className="w-full">
            <SubscriptionMessage userData={authUser?.data ?? {}} />

            <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
                <div className="w-full">
                    <div className="w-full flex justify-between items-center">
                        {/* Text */}
                        <div className="flex flex-col gap-2">
                            <h2 className="flex items-center textHeader-l">
                                {t("Platform Subscription")}
                            </h2>
                            <p className="textBody-s3">{t("Choose a plan that fits your club's needs and handle payments seamlessly.")}</p>
                        </div>
                    </div>
                </div>
                {/* Content */}
                <div className="mt-6 flex flex-col">

                    <div className="flex">
                        <div className="border border-primary/80 rounded-full p-1 flex items-center shadow-sm sm:shadow-md bg-white textBody-s3">
                            <button
                                disabled
                                // onClick={() => setPlanType(1)}
                                className={`outline-none lg:px-6 px-4 lg:py-1.5 py-1 rounded-full hover:opacity-95 bg-primary text-white`}>
                                {t("Monthly")}
                            </button>
                            <button
                                disabled
                                // onClick={() => setPlanType(2)}
                                className={`outline-none lg:px-6 px-4 lg:py-1.5 py-1 rounded-full hover:opacity-95`}>
                                {t("Annual")}
                            </button>
                        </div>
                    </div>

                    <div className="mt-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                        {subscriptionPlansList?.map((plan) => {
                            const { id, slug, price } = plan ?? {};
                            return (
                                <PlanCard
                                    key={id}
                                    name={renderPlanName(plan)}
                                    price={price.monthly}
                                    buttonLink={`/settings/subscription/checkout?plan=${slug}`}
                                    className={id === 2 ? 'lg:block hidden' : id === 3 ? 'md:block hidden' : ''}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}



const PlanCard = ({ name, price, buttonLink = '#', className }) => {

    const { t } = useTranslation()

    const navigate = useNavigate();

    return (
        <div className={`border border-primaryBorder rounded-lg p-4 shadow-sm sm:shadow-md bg-white ${className}`}>
            <div className="flex items-center justify-between mb-4 pb-3 border-b border-primaryBorder/50">
                <h3 className="textBody-m2">{name}</h3>
                <span className="textBody-s1">{price}</span>
            </div>
            <div className="flex flex-col gap-3 textBody-s3">

                <div className="flex items-start gap-2">
                    <div className="mt-[3px]">
                        <Check className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="">
                        <p>{t("Unlimited clients")}</p>
                    </div>
                </div>
                <div className="flex items-start gap-2">
                    <div className="mt-[3px]">
                        <Check className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="">
                        <p>{t("Unlimited subscriptions")}</p>
                    </div>
                </div>
                <div className="flex items-start gap-2">
                    <div className="mt-[3px]">
                        <Check className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="">
                        <p>{t("Unlimited sports")}</p>
                    </div>
                </div>
                <div className="flex items-start gap-2">
                    <div className="mt-[3px]">
                        <Check className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="">
                        <p>{t("Unlimited team members")}</p>
                    </div>
                </div>
                <div className="flex items-start gap-2">
                    <div className="mt-[3px]">
                        <Check className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="">
                        <p>{t("Export data as PDF")}</p>
                    </div>
                </div>
                <div className="flex items-start gap-2">
                    <div className="mt-[3px]">
                        <Check className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="">
                        <p>{t("Payments history")}</p>
                    </div>
                </div>
                <div className="flex items-start gap-2">
                    <div className="mt-[3px]">
                        <Check className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="">
                        <p>{t("Earnings reports")}</p>
                    </div>
                </div>
                <div className="flex items-start gap-2">
                    <div className="mt-[3px]">
                        <Check className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="">
                        <p>{t("Technical support")}</p>
                    </div>
                </div>

            </div>
            <div className="mt-10">
                <Button
                    type="button"
                    name={t("Subscribe now")}
                    onClick={() => navigate(buttonLink)}
                    styleType="1"
                />
            </div>
        </div>
    )
}


const SubscriptionMessage = ({ userData }) => {

    const { t } = useTranslation()

    const { remaining_trial_days, remaining_subscription_days } = userData ?? {};

    return (
        <>
            {!isNull(remaining_trial_days) && isNull(remaining_subscription_days) ?
                <div className="p-4 bg-gradient-to-r from-primary/90 to-[#cb2dde]/90 w-full text-white shadow-sm sm:shadow-md max-lg:mb-4">
                    <p className="textBody-s3">
                        <strong>
                            {t("Your trial period has ended.")} {" "}
                        </strong>
                        {t("To continue using our platform, please subscribe to one of our plans. You can upgrade to a higher plan with more features now, starting at just $10 per month.")}
                    </p>
                </div> : null}

            {isNull(remaining_trial_days) && !isNull(remaining_subscription_days) ?
                <>
                    {remaining_subscription_days <= 0 ? (
                        <div className="p-4 bg-gradient-to-r from-primary/90 to-[#cb2dde]/90 w-full text-white shadow-sm sm:shadow-md max-lg:mb-4">
                            <p className="textBody-s3">
                                <strong>
                                    {t("Your subscription is ending soon!")} {" "}
                                </strong>
                                {t("You have 30 days left in your current subscription. Renew now to continue enjoying all the features without interruption.", { daysNum: remaining_subscription_days?.toString().replace('-', '') })}
                            </p>
                        </div>
                    ) : (
                        <div className="p-4 bg-gradient-to-r from-primary/90 to-[#cb2dde]/90 w-full text-white shadow-sm sm:shadow-md max-lg:mb-4">
                            <p className="textBody-s3">
                                <strong>
                                    {t("Your subscription has ended")} {" "}
                                </strong>
                                {t("Renew now to continue access.")}
                            </p>
                        </div>
                    )}
                </> : null
            }
        </>
    )
}