import { useTranslation } from "react-i18next";


export default function Heading({ totalRows }) {

    const { t } = useTranslation()

    return (
        <div className="w-full">
            <div className="w-full flex lg:justify-between lg:items-center max-lg:flex-col-reverse">
                {/* Text */}
                <div className="flex flex-col gap-1">
                    <h2 className="flex items-center textHeader-l">
                        {t("Blocked Clients")}
                        <span className="border border-primaryBorder bg-white text-secondaryTextColor py-1 px-3 flex w-fit rounded-3xl select-none mx-2 textBody-xs3">
                            {totalRows}
                        </span>
                    </h2>
                    <p className="textBody-s3">
                        {t("View all your blocked clients.")}
                    </p>
                </div>

                <div className="lg:hidden block h-6"></div>
            </div>
        </div>
    )
}