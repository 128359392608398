import { useState } from "react";
import { debounce } from "../../Helpers/utils";
import Filters from "../../components/Pages/Team/Filters";
import Heading from "../../components/Pages/Team/Heading";
import TeamMembers from "../../components/Pages/Team/TeamMembers";
import Pagination from "../../components/Pagination";
import TeamSuspense from "../../components/SuspensePages/TeamSuspense";
import { GetAllMembers } from "../../lib/api";
import { useAuth } from "../../hooks/useAuth";


const Team = () => {

    const { allowedFor } = useAuth()

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const [params, setParams] = useState({})

    const paramsData = { page: pageNumber, search_query: searchQuery, ...params };

    const { data, loading, refresh } = GetAllMembers(paramsData);


    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);


    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <Heading 
                totalRows={data?.data?.total ?? 0}
                canCreate={allowedFor('owner')}
            />
            <Filters
                params={params}
                setParams={setParams}
                handleSearchFilter={handleSearchFilter}
            />

            {loading
                ? <TeamSuspense />
                : <TeamMembers team={data?.data?.data ?? []} refresh={refresh} canUpdate={allowedFor('owner')} />
            }

            <Pagination
                className="mb-20 mt-4 py-4 border-t border-primary/10"
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />
        </div>
    )
}
export default Team;