import Djs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/ar";
import Cookies from 'js-cookie'

const tz = "Africa/Casablanca";

const currentLang = Cookies.get("aratlyLocale") || "en";


Djs.extend(utc);
Djs.extend(timezone);
Djs.extend(weekday);
Djs.extend(relativeTime);

Djs.locale(currentLang);
Djs.tz.setDefault(tz);

const dayjs = (...args) => {
    return Djs(...args).tz(tz);
};

const timezonedUnix = value => {
    return Djs.unix(value).tz(tz);
};

dayjs.unix = timezonedUnix;
dayjs.duration = Djs.duration;


export default dayjs;
