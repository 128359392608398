import { useState } from "react";
import Heading from "../../components/Pages/Sports/Heading";
import SportsList from "../../components/Pages/Sports/SportsList";
import { useAlerts } from "../../context/AlertsContext";
import CreateSport from "../../components/Pages/Sports/CreateSport";
import { isNull, n, paramsList } from "../../Helpers/utils";
import UpdateSport from "../../components/Pages/Sports/UpdateSport";
import SportsSuspense from "../../components/SuspensePages/SportsSuspense";
import Pagination from "../../components/Pagination";
import { DeleteSportById, GetAllSports } from "../../lib/api";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";


const Sports = () => {

    const { t } = useTranslation()

    const [pageNumber, setPageNumber] = useState(1)

    const paramsData = { page: pageNumber };

    // Get sports list
    const { data, loading, refresh } = GetAllSports(paramsData);


    // Call alerts provider
    const { addAlert } = useAlerts();



    //Start------------------------------ Create New Sport Modal ------------------------------//

    const [searchParams, setSearchParams] = useSearchParams();
    const queryName = paramsList()['action'];
    const queryName2 = paramsList()['id'];

    const addActionQuery = () => {
        searchParams.set(queryName, 'add');
        setSearchParams(searchParams);
    }

    const removeActionQuery = () => {
        searchParams.delete(queryName);
        setSearchParams(searchParams);
    }

    const getActionQuery = () => {
        return searchParams.get(queryName) ?? '';
    };

    const showAddNewModal = getActionQuery() === "add";

    //End------------------------------ Create New Sport Modal ------------------------------//




    //Start------------------------------ Edit Sport Modal ------------------------------//

    const addAction2Query = (v = '') => {
        searchParams.set(queryName, 'edit');
        searchParams.set(queryName2, n(v));
        setSearchParams(searchParams);
    }

    const removeAction2Query = () => {
        searchParams.delete(queryName);
        searchParams.delete(queryName2);
        setSearchParams(searchParams);
    }

    const getAction2Query = () => {
        const actionVal = searchParams.get(queryName) ?? '';
        const sportIdVal = searchParams.get(queryName2) ?? '';
        return !isNull(actionVal) && !isNull(sportIdVal) ? sportIdVal : '';
    };

    const selectedSportID = getAction2Query();


    // find selected item by "selectedSportID"
    const selectedItem = !isNull(selectedSportID) ? data?.data?.data?.find(i => i.id === n(selectedSportID)) : {};

    //End------------------------------ Edit Sport Modal ------------------------------//



    // handle remove subscription
    const handleRemoveSport = async (id) => {
        if (isNull(id)) return;

        const { is_ok, message, type } = await DeleteSportById({ id });

        if (type === "validation") {
            addAlert(t('Something was wrong!'),'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh()
        }
    }


    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <Heading
                totalRows={data?.data?.total ?? 0}
                openModal={addActionQuery}
                paramsData={paramsData}
                canExport={true}
            />

            {loading
                ? <SportsSuspense />
                : <SportsList
                    sports={data?.data?.data ?? []}
                    selectItem={addAction2Query}
                    canViewPrice={true}
                />}

            <Pagination
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />


            {/* Create */}
            {showAddNewModal &&
                <CreateSport
                    closeModal={removeActionQuery}
                    refresh={refresh}
                />
            }

            {/* Edit */}
            {!isNull(selectedItem) && (
                <UpdateSport
                    selectedItem={selectedItem}
                    closeModal={() => removeAction2Query("")}
                    refresh={refresh}
                    handleRemove={handleRemoveSport}
                    canRemove={true}
                />
            )}
        </div>
    )
}
export default Sports;