import axiosClient from "./axiosClient";

export const csrf = () => axiosClient.get("/sanctum/csrf-cookie");

export const download = async (path) => {
    if (!path) return;

    try {
        await csrf();
        const response = await axiosClient({
            url: "api/downloadFile",
            method: "GET",
            params: { path },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create file name
        const pathAsArr = path.split('/');
        const fileName = pathAsArr[pathAsArr.length - 1];

        const alink = document.createElement('a');
        alink.href = url;
        alink.setAttribute('download', fileName);
        document.body.appendChild(alink);
        alink.click();

        // Clean up
        alink.remove();
        window.URL.revokeObjectURL(url);

    } catch (error) {
        console.log(error)
    }
}