import { useState } from "react";
import FilterPopup from "./FilterPopup";
import SearchBox from "./SearchBox";
import { Filters as FiltersIcon } from "../../../../icons";
import { isNull, paramsList } from "../../../../Helpers/utils";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";


export default function Filters({ params, setParams, handleSearchFilter }) {

    const { t } = useTranslation()

    const [val, setVal] = useState("")

    const handleOnChange = (val) => {
        setVal(val)
        handleSearchFilter(val)
    }

    const isFilterActive = !isNull(params?.gender) || !isNull(params?.sport) || !isNull(params?.subscription) || !isNull(params?.payment_day) || !isNull(params?.sort_by);


    //Start------------------------------ Open Filters Modal ------------------------------//

    const [searchParams, setSearchParams] = useSearchParams();
    const queryName = paramsList()['filter'];

    const addActionQuery = () => {
        searchParams.set(queryName, 'filters');
        setSearchParams(searchParams);
    }

    const removeActionQuery = () => {
        searchParams.delete(queryName);
        setSearchParams(searchParams);
    }

    const getActionQuery = () => {
        return searchParams.get(queryName) ?? '';
    };

    const showFiltersModal = getActionQuery() === "filters";

    //End------------------------------ Open Filters Modal ------------------------------//

    return (
        <div className="my-4 w-full bg-primary2 rounded-lg lg:p-4 p-2">
            <div className="w-full flex justify-between items-center gap-2">
                <div className={`w-full flex items-center gap-2`}>
                    <SearchBox
                        value={val}
                        handleOnChange={handleOnChange}
                    />
                    {/*  */}
                </div>


                <button
                    onClick={addActionQuery}
                    className="relative bg-white border-[1.5px] border-primaryBorder hover:bg-white/50 text-secondaryTextColor rounded-full py-2 lg:px-4 px-2 transition-all duration-100 ease-in active:scale-[.99] flex items-center gap-2 textBody-m2">
                    <span className="lg:block hidden">{t("Filters")}</span>
                    <FiltersIcon className="w-4 h-4" />
                    {isFilterActive && <div className="absolute lg:top-0.5 lg:left-0.5 top-0 left-0 w-2 h-2 rounded-full bg-green-500 shadow-md"></div>}
                </button>


                {showFiltersModal &&
                    <FilterPopup
                        params={params}
                        setParams={setParams}
                        toggleModal={removeActionQuery}
                    />
                }
            </div>
        </div>
    )
}