import { useTranslation } from "react-i18next";

export default function Pagination({
    className = "mb-20 py-4 border-t border-primary/10",
    currentPageNumber = null,
    lastPageNumber = null,
    onChange = () => { },
    disabled = false,
}) {
    
    const { t } = useTranslation()

    // Get Preview Page Url
    const prevPageUrl = () => {
        const currentPage = currentPageNumber;
        const prevPage = currentPage - 1;
        if (prevPage > 0) {
            onChange(prevPage)
        }
    }

    // Get Next Page Url
    const nextPageUrl = () => {
        const currentPage = currentPageNumber;
        const lastPage = lastPageNumber;
        const nextPage = currentPage + 1;
        if (nextPage <= lastPage) {
            onChange(nextPage)
        }
    }


    const canNavigateToNextPage = currentPageNumber + 1 <= lastPageNumber ? false : true;
    const canNavigateToPrevPage = currentPageNumber - 1 > 0 ? false : true;


    // Show Pagination Only When Last Page Not Equal 1.
    const showPagination = lastPageNumber > 1;


    return !showPagination ? null : (
        <div className={`w-full flex flex-col gap-2 items-center justify-center ${className}`}>
            <div className="w-full flex items-center justify-between text-secondaryTextColor">
                <Btn
                    roundedClass="rounded-lg"
                    isDisabled={canNavigateToPrevPage || disabled}
                    onClick={prevPageUrl}
                >
                    <span className="rtl:rotate-180">←</span>
                    <span>{t("Previous")}</span>
                </Btn>
                <div className="lg:textBody-s3 textBody-xs3 flex items-center gap-1 cursor-context-menu">
                    <span className="">{currentPageNumber}</span>
                    <span className="">/</span>
                    <span className="">{lastPageNumber}</span>
                </div>
                <Btn
                    roundedClass="rounded-lg"
                    isDisabled={canNavigateToNextPage || disabled}
                    onClick={nextPageUrl}
                >
                    <span>{t("Next")}</span>
                    <span className="rtl:rotate-180">→</span>
                </Btn>
            </div>
        </div>
    )
}


const Btn = ({ roundedClass = "", borderClass = "", isDisabled, onClick, children }) => {

    const colorsClasses = isDisabled ? 'bg-transparent hover:bg-primary/5 cursor-not-allowed' : 'bg-transparent hover:text-white hover:bg-primary cursor-pointer';

    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            type='button'
            className={`lg:py-3 py-1.5 lg:px-6 px-4 transition-all duration-100 ease-in flex items-center gap-2 lg:textBody-s3 textBody-xs3 border-0 outline-none ${colorsClasses} ${roundedClass} ${borderClass}`}>
            {children}
        </button>
    )
}