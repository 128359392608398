import { useTranslation } from "react-i18next"
import { isNull } from "../../../../Helpers/utils"
import ClientCard1 from "./ClientCard1"
import { useCurrency } from "../../../../hooks/useCurrency"
import { NoResults } from "../../../../icons"
import { Link } from "react-router-dom"


export default function ClientsList1({ clients, handleSelectClient, isNewClub }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    return isNull(clients)
        ? <NoResultsFound title={t("No results")} desc={t("No clients found on this page")} desc2={t("try adding new clients")} isNewClub={isNewClub} buttonName={t("Add new client")} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none">
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">{t("Number")}</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1"><span className="max-lg:hidden">{t("Name")}</span><span className="lg:hidden">{t("Client")}</span></th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">{t("Contact")}</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">{t("Sport")}</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">{t("Price")}</th>
                        <th className="ltr:text-right rtl:text-left lg:textBody-s1 textBody-xs1">{t("Joined at")}</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.length > 0 &&
                        clients.map((client) => {
                            return (
                                <ClientCard1
                                    key={`client_card_1_${client.id}`}
                                    data={client}
                                    handleSelectClient={handleSelectClient}
                                    Currency={Currency}
                                />
                            )
                        })}
                </tbody>
            </table>
        )
}


// NotResultsFound
const NoResultsFound = ({
    marginClassNames = "my-4",
    title = "Title",
    desc = "Description",
    desc2 = "",
    isNewClub = false,
    buttonName = ""
}) => {
    return (
        <div className={`${marginClassNames} rounded-lg lg:h-80 h-56 border border-primary/15`}>
            <div className="w-full h-full flex flex-col items-center justify-center gap-1 text-center p-4 text-secondaryTextColor select-none">
                <NoResults className="w-12 h-w-12 mb-3" />
                <h2 className="textBody-m1">{title}</h2>
                <p className="textBody-s3">{desc}</p>
                {desc2 && <p className="textBody-s3">{desc2}</p>}
                {isNewClub &&
                <Link to={`/clients/new`}>
                    <button
                        className="rounded-full py-3 px-6 mt-4 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-m1 active:scale-[.99] bg-primary border-[1.5px] border-primary text-white hover:bg-primary/80 hover:border-primary/80"
                        type="button">
                        {buttonName ?? "click"}
                    </button>
                </Link>}
            </div>
        </div>
    )
}