import { ArrowLeft1, ArrowRight1 } from "../../../../icons";
import dayjs from "../../../../lib/dayjs";

export default function FilterPopup({ selectedDate, setSelectedDate}) {

    const prevMonth = () => {
        setSelectedDate(dayjs(selectedDate).add(-1, 'month').format("YYYY-MM-DD"))
    }

    const currentMonth = () => {
        setSelectedDate(dayjs(new Date()).format("YYYY-MM-DD"))
    }

    const nextMonth = () => {
        setSelectedDate(dayjs(selectedDate).add(1, 'month').format("YYYY-MM-DD"))
    }
    
    return (
        <div className="bg-white border-[1.5px] border-primaryBorder text-primaryTextColor/70 rounded-full font-semibold transition-all duration-100 ease-in flex overflow-hidden">
            <button
                onClick={prevMonth}
                className="py-2 px-4 active:scale-[.97] hover:bg-primary2">
                <ArrowRight1 className="w-4 h-4 ltr:hidden"/>
                <ArrowLeft1 className="w-4 h-4 rtl:hidden"/>
            </button>
            
            <button
                onClick={currentMonth}
                className="border-l-[1.5px] border-r-[1.5px] border-primaryBorder cursor-pointer py-2 px-4 active:scale-[.99] hover:bg-primary2">
                <span className="textBody-s3">{dayjs(selectedDate).format("MMMM, YYYY")}</span>
            </button>

            <button
                onClick={nextMonth}
                className="py-2 px-4 active:scale-[.97] hover:bg-primary2">
                <ArrowRight1 className="w-4 h-4 rtl:hidden"/>
                <ArrowLeft1 className="w-4 h-4 ltr:hidden"/>
            </button>
        </div>
    )
}