import { Link, useLocation } from "react-router-dom";
import { Clients, Dashboard, Help, MenuSquares, Payments, Settings, Sports, Subscriptions, Team } from "../../icons";
import { useTranslation } from "react-i18next";



export default function SideBar({ isOpen }) {

    const { t } = useTranslation()

    // Get path name
    const location = useLocation();
    const { pathname } = location;

    const asideLinks = [
        {
            label: t('Dashboard'),
            icon: <Dashboard className="w-6 h-6" />,
            path: '/',
            canVisit: true,
        }, {
            label: t('Clients'),
            icon: <Clients className="w-6 h-6" />,
            path: '/clients',
            canVisit: true,
        }, {
            label: t('Payments'),
            icon: <Payments className="w-6 h-6" />,
            path: '/payments',
            canVisit: true,
        }, {
            label: t('Sports'),
            icon: <Sports className="w-6 h-6" />,
            path: '/sports',
            canVisit: true,
        }, {
            label: t('Subscriptions'),
            icon: <Subscriptions className="w-6 h-6" />,
            path: '/subscriptions',
            canVisit: true,
        }, {
            //     label: 'Reports',
            //     icon: <Reports className="w-6 h-6" />,
            //     path: '/reports',
            //     canVisit: true,
            // }, {
            label: t('Team'),
            icon: <Team className="w-6 h-6" />,
            path: '/team',
            canVisit: true,
        }, {
            label: t('Club'),
            icon: <MenuSquares className="w-6 h-6" />,
            path: '/club',
            canVisit: true,
        }, {
            label: t('Settings'),
            icon: <Settings className="w-6 h-6" />,
            path: '/settings',
            canVisit: true,
        },
    ];


    const isActive = (path) => {
        if (path === "/" && pathname === "/") {
            return true;
        } else if (path !== "/" && pathname !== "/") {
            return pathname.startsWith(path);
        }
    }

    return (
        <aside className={` max-lg:hidden sticky top-[70px] h-[calc(100vh_-_70px)] overflow-y-auto overflow-x-hidden flex shrink-0 flex-col justify-between items-center bg-tertiaryBg p-3 scrollBar1 transition-all duration-300 z-[1001] ${isOpen ? 'w-[200px]' : 'w-[64px]'}`}>
            <div className="w-full h-full text-white flex flex-col justify-between textBody-s3">
                <div className="flex flex-col">
                    {asideLinks.map((link, index) => {
                        const canView = link.canVisit;

                        const ButtonContent = () => {
                            return (
                                <div
                                    className={`flex items-center gap-2 mb-3 rounded-lg text-white transition-colors duration-200
                                ${isActive(link.path) ? 'bg-primary cursor-pointer' : canView ? 'bg-transparent hover:bg-white/20 cursor-pointer' : 'bg-transparent opacity-60 cursor-not-allowed'}
                            `}>
                                    <div className="flex shrink-0 justify-center items-center w-10 h-10">
                                        {link.icon}
                                    </div>
                                    {isOpen && <span className="whitespace-nowrap">{link.label}</span>}
                                </div>
                            )
                        }

                        return !canView ? <ButtonContent key={`link_${index}`} /> : (
                            <Link
                                key={`link_${index}`}
                                to={`${link.path ?? '/'}`}>
                                <ButtonContent />
                            </Link>
                        )
                    }
                    )}
                </div>

                <a href="https://support.aratly.com/" target="_blank" rel="noreferrer" className="flex flex-col">
                    <div className={`flex items-center gap-2 rounded-lg text-white transition-colors duration-200 bg-transparent hover:bg-white/20 cursor-pointer`}>
                        <div className="flex shrink-0 justify-center items-center w-10 h-10">
                            <Help className="w-6 h-6" />
                        </div>
                        {isOpen && <span className="whitespace-nowrap">{t("Help Center")}</span>}
                    </div>
                </a>
            </div>
        </aside>
    )
}