import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import PrimaryLayout from './layouts/PrimaryLayout';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import Reports from './pages/Reports';
import ClientsList from './pages/Clients/List';
import BlockedList from './pages/Clients/BlockedList';
import NewClient from './pages/Clients/New';
import Payments from './pages/Payments';
import Team from './pages/Team';
import NewMember from './pages/Team/New';
import Settings from './pages/Settings';
import Club from './pages/Club';
import Profile from './pages/Profile';
import Sports from './pages/Sports';
import Subscriptions from './pages/Subscriptions';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import AuthLayout from './layouts/AuthLayout';
import EditClient from './pages/Clients/Edit';
import WarningReminder from './WarningReminder';
import EditMember from './pages/Team/Edit';
import PlatformSubscription from './pages/PlatformSubscription';
import Checkout from './pages/PlatformSubscription/Checkout';
import { AlertsProvider } from './context/AlertsContext';
import AlertsList from './components/Alerts/AlertsList';

function App() {
    return (
        <AlertsProvider>
            <AlertsList />
            <BrowserRouter>
                <WarningReminder />
                <ScrollToTop />

                <Routes>
                    <Route path="/auth" element={<AuthLayout />}>
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Register />} />
                    </Route>

                    <Route path="/" element={<PrimaryLayout />}>
                        <Route index element={<Dashboard />} />
                        <Route path="reports" element={<Reports />} />

                        <Route path="clients" element={<ClientsList />} />
                        <Route path="clients/new" element={<NewClient />} />
                        <Route path="clients/edit/:id" element={<EditClient />} />
                        <Route path="clients/blocked" element={<BlockedList />} />

                        <Route path="payments" element={<Payments />} />

                        <Route path="sports" element={<Sports />} />

                        <Route path="subscriptions" element={<Subscriptions />} />

                        <Route path="team" element={<Team />} />
                        <Route path="team/new" element={<NewMember />} />
                        <Route path="team/edit/:id" element={<EditMember />} />

                        <Route path="club" element={<Club />} />

                        <Route path="settings" element={<Settings />} />
                        <Route path="settings/subscription" element={<PlatformSubscription />} />
                        <Route path="settings/subscription/checkout" element={<Checkout />} />

                        <Route path="profile" element={<Profile />} />
                    </Route>

                    <Route path='*' element={<NotFound />}></Route>
                </Routes>
            </BrowserRouter>
        </AlertsProvider>
    );
}

export default App;
