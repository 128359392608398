import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AddNew2, AddNewClient, AddNewMember, Clients, Dashboard, Hide1, Menu, MenuSquares, Payments, Settings, Sports, Subscriptions, Team } from "../../icons";
import { useTranslation } from "react-i18next";
import ModalLayout from "../../layouts/ModalLayout";
import { paramsList } from "../../Helpers/utils";



export default function SideBarMobile() {

    const navigate = useNavigate()

    const { t } = useTranslation()

    // Get path name
    const location = useLocation();
    const { pathname } = location;


    //Start------------------------------ Toggle Add/Menu Modals ------------------------------//

    const [searchParams, setSearchParams] = useSearchParams();

    const queryName = paramsList()['modal'];

    const addParamQuery = (v = '') => {
        window.scrollTo(0, 0); //scroll to the top
        searchParams.set(queryName, v);
        setSearchParams(searchParams);
    }

    const removeParamQuery = () => {
        searchParams.delete(queryName);
        setSearchParams(searchParams);
    }

    const getParamQuery = () => {
        return searchParams.get(queryName) ?? '';
    };

    const showAddNewModal = getParamQuery() === "add";
    const showMenuModal = getParamQuery() === "menu";

    //End------------------------------ Toggle Add/Menu Modals ------------------------------//


    const isActive = (path) => {
        if (path === "/" && pathname === "/") {
            return !showAddNewModal && !showMenuModal ? true : false;
        } else if (path !== "/" && pathname !== "/") {
            return !showAddNewModal && !showMenuModal ? pathname.startsWith(path) : false;
        }
    }

    const GorTo = (path) => {
        navigate(path)
    }


    const asideLinks = [
        {
            label: t('Dashboard'),
            icon: <Dashboard className="w-6 h-6" />,
            path: '/',
        }, {
            label: t('Clients'),
            icon: <Clients className="w-6 h-6" />,
            path: '/clients',
        }, {
            label: t('Payments'),
            icon: <Payments className="w-6 h-6" />,
            path: '/payments',
        }, {
            label: t('Sports'),
            icon: <Sports className="w-6 h-6" />,
            path: '/sports',
        }, {
            label: t('Subscriptions'),
            icon: <Subscriptions className="w-6 h-6" />,
            path: '/subscriptions',
        }, {
            label: t('Team'),
            icon: <Team className="w-6 h-6" />,
            path: '/team',
        }, {
            label: t('Club'),
            icon: <MenuSquares className="w-6 h-6" />,
            path: '/club',
        }, {
            label: t('Settings'),
            icon: <Settings className="w-6 h-6" />,
            path: '/settings',
        },
    ];

    return (
        <>
            {showAddNewModal &&
                <div className="lg:hidden">
                    <ModalLayout
                        wallBackground={'bg-black/50'}
                        className={"absolute left-2/4 bottom-[60px] w-full -translate-x-2/4 z-[1312]"}
                        close={removeParamQuery}
                    >
                        <div className="shadow-lg bg-white animation-01 animation-02 min-w-[150px] h-full rounded-t-xl overflow-hidden flex flex-col textBody-s3">
                            <div className="flex items-center justify-between px-4 py-4 border-b border-primaryBorder/60">
                                <h3 className="textBody-m1">{t("Add")}</h3>
                                <button
                                    onClick={removeParamQuery}
                                    className="rounded-lg p-1 hover:bg-primary/5">
                                    <Hide1 className="w-5 h-5" />
                                </button>
                            </div>
                            <div className="bg-bodyBackground grid grid-cols-2 gap-3 py-4 px-4">
                                <button
                                    onClick={() => GorTo('/clients/new')}
                                    className="bg-white text-start whitespace-nowrap flex flex-col gap-2 items-center rounded-lg py-6 px-4 hover:bg-primary/5 transition-colors duration-300 border border-primaryBorder">
                                    <AddNewClient className="w-6 h-6" />
                                    <span>{t("New client")}</span>
                                </button>
                                <button
                                    onClick={() => GorTo('/team/new')}
                                    className="bg-white text-start whitespace-nowrap flex flex-col gap-2 items-center rounded-lg py-6 px-4 hover:bg-primary/5 transition-colors duration-300 border border-primaryBorder">
                                    <AddNewMember className="w-6 h-6" />
                                    <span>{t("New member")}</span>
                                </button>
                                <button
                                    onClick={() => GorTo(`/sports?${paramsList()['action']}=add`)}
                                    className="bg-white text-start whitespace-nowrap flex flex-col gap-2 items-center rounded-lg py-6 px-4 hover:bg-primary/5 transition-colors duration-300 border border-primaryBorder">
                                    <Sports className="w-6 h-6" />
                                    <span>{t("New sport")}</span>
                                </button>
                                <button
                                    onClick={() => GorTo(`/subscriptions?${paramsList()['action']}=add`)}
                                    className="bg-white text-start whitespace-nowrap flex flex-col gap-2 items-center rounded-lg py-6 px-4 hover:bg-primary/5 transition-colors duration-300 border border-primaryBorder">
                                    <Subscriptions className="w-6 h-6" />
                                    <span>{t("New subscription")}</span>
                                </button>
                            </div>
                        </div>
                    </ModalLayout>
                </div>
            }

            {showMenuModal &&
                <div className="lg:hidden">
                    <ModalLayout
                        wallBackground={'bg-black/50'}
                        className={"absolute left-2/4 bottom-[60px] w-full -translate-x-2/4 z-[1312]"}
                        close={removeParamQuery}
                    >
                        <div className="shadow-lg bg-white animation-01 animation-02 min-w-[150px] h-full rounded-t-xl overflow-hidden flex flex-col textBody-s3">
                            <div className="flex items-center justify-between px-4 py-4 border-b border-primaryBorder/60">
                                <h3 className="textBody-m1">{t("Menu")}</h3>
                                <button
                                    onClick={removeParamQuery}
                                    className="rounded-lg p-1 hover:bg-primary/5">
                                    <Hide1 className="w-5 h-5" />
                                </button>
                            </div>
                            <div className="bg-bodyBackground grid grid-cols-3 gap-3 py-4 px-4">
                                {asideLinks?.map((_link, index) => {
                                    return (
                                        <button
                                            key={index}
                                            onClick={() => GorTo(_link?.path)}
                                            className="bg-white text-start whitespace-nowrap flex flex-col gap-2 items-center rounded-lg py-6 px-4 hover:bg-primary/5 transition-colors duration-300 border border-primaryBorder">
                                            {_link?.icon}
                                            <span className="line-clamp-1">{_link?.label}</span>
                                        </button>
                                    )
                                })}

                                <button
                                    onClick={() => addParamQuery('add')}
                                    className="bg-white text-start whitespace-nowrap flex flex-col gap-2 items-center rounded-lg py-6 px-4 hover:bg-primary/5 transition-colors duration-300 border border-primaryBorder">
                                    <AddNew2 className="w-6 h-6" />
                                    <span className="line-clamp-1">{t('Add')}</span>
                                </button>
                            </div>
                        </div>
                    </ModalLayout>
                </div>
            }
            <div className="sticky bottom-0 lg:hidden">

                <aside className={`shrink-0 text-white flex items-center gap-2 justify-between textBody-s3 bg-tertiaryBg p-3 scrollBar1 transition-all duration-300 z-[1001] w-full`}>
                    <Link to={`/`}>
                        <div className={`flex items-center gap-2 rounded-lg text-white transition-colors duration-200 ${isActive('/') ? 'bg-primary cursor-pointer' : 'bg-transparent cursor-pointer'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Dashboard className="w-6 h-6" />
                            </div>
                        </div>
                    </Link>
                    <Link to={`/clients`}>
                        <div className={`flex items-center gap-2 rounded-lg text-white transition-colors duration-200 ${isActive('/clients') ? 'bg-primary cursor-pointer' : 'bg-transparent cursor-pointer'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Clients className="w-6 h-6" />
                            </div>
                        </div>
                    </Link>

                    <button onClick={() => addParamQuery('add')} className="outline-none">
                        <div className={`flex items-center gap-2 rounded-lg text-white transition-colors duration-200 ${showAddNewModal ? 'bg-primary cursor-pointer' : 'bg-transparent cursor-pointer'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <AddNew2 className="w-6 h-6" />
                            </div>
                        </div>
                    </button>

                    <Link to={`/payments`}>
                        <div className={`flex items-center gap-2 rounded-lg text-white transition-colors duration-200 ${isActive('/payments') ? 'bg-primary cursor-pointer' : 'bg-transparent cursor-pointer'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Payments className="w-6 h-6" />
                            </div>
                        </div>
                    </Link>

                    <button onClick={() => addParamQuery('menu')} className="outline-none">
                        <div className={`flex items-center gap-2 rounded-lg text-white transition-colors duration-200 ${showMenuModal ? 'bg-primary cursor-pointer' : 'bg-transparent cursor-pointer'}`}>
                            <div className="flex shrink-0 justify-center items-center w-9 h-9">
                                <Menu className="w-6 h-6" />
                            </div>
                        </div>
                    </button>
                </aside>
            </div>
        </>
    )
}