import { useState } from 'react';
import Filters from "../../components/Pages/Clients/Filters";
import Heading from "../../components/Pages/Clients/Heading";
import ClientsList1 from "../../components/Pages/Clients/ClientsList1";
import ClientDetails from "../../components/Pages/Clients/ClientDetails";
import { debounce, isNull, n, paramsList } from "../../Helpers/utils";
import ClientsSuspense from "../../components/SuspensePages/ClientsSuspense";
import Pagination from "../../components/Pagination";
import { GetAllClients } from "../../lib/api";
import PageSideBarLayout from "../../layouts/PageSideBarLayout";
import { useAlerts } from "../../context/AlertsContext";
import { useSearchParams } from 'react-router-dom';

const ClientsList = () => {

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const [params, setParams] = useState({})

    const paramsData = {
        ...(pageNumber !== 1 && { page: pageNumber }),
        ...(searchQuery && { search_query: searchQuery }),
        ...params
    };

    const { data, loading, refresh } = GetAllClients(paramsData);
    const clients = data?.data?.data ?? [];


    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);


    // Call alerts provider
    const { addAlert } = useAlerts();


    //------------------------------ Client Details Modal ------------------------------//

    const [searchParams, setSearchParams] = useSearchParams();
    const queryName = paramsList()['client'];
    const queryContentSectionName = paramsList()['contentSection'];

    const addVQuery = (val_) => {
        searchParams.set(queryName, val_);
        searchParams.set(queryContentSectionName, 'overview');
        setSearchParams(searchParams);
    }

    const removeVQuery = () => {
        searchParams.delete(queryName);
        searchParams.delete(queryContentSectionName);
        setSearchParams(searchParams);
    }

    const getVQuery = () => {
        return searchParams.get(queryName) ?? '';
    };

    const selectedClientId = getVQuery();
    const selectedClientData = !isNull(selectedClientId) ? clients?.find(i => i.membership_number === n(selectedClientId)) : {};


    return (
        <PageSideBarLayout>
            <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
                <Heading
                    totalRows={data?.data?.total ?? 0}
                    paramsData={paramsData}
                />

                <Filters
                    params={params}
                    setParams={setParams}
                    handleSearchFilter={handleSearchFilter}
                />


                {/* Show clients list */}
                {loading
                    ? <ClientsSuspense />
                    : <ClientsList1
                        clients={clients}
                        handleSelectClient={addVQuery} //setSelectedClientId
                        isNewClub={isNull(clients) && isNull(paramsData)}
                    />
                }

                <Pagination
                    currentPageNumber={data?.data?.current_page}
                    lastPageNumber={data?.data?.last_page}
                    onChange={(n) => setPageNumber(n)}
                    disabled={loading}
                />


                {/* Modal to show client details */}
                {!isNull(selectedClientData) && (
                    <ClientDetails
                        clientData={selectedClientData}
                        close={removeVQuery}
                        refresh={refresh}
                        showNotification={addAlert}
                    />
                )}
            </div>
        </PageSideBarLayout>
    )
}
export default ClientsList;