import SubscriptionsCard from "./SubscriptionsCard"
import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import { useTranslation } from "react-i18next"

export default function SubscriptionsList({
    subscriptions = [],
    selectItem = () => { }
}) {

    const { t } = useTranslation()

    return isNull(subscriptions)
        ? <NotResultsFound title={t("No results")} desc={t("No subscriptions found on this page")} desc2={t("try adding new subscriptions")} />
        : (
            <div className="mt-8 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4 lg:gap-4 xl:gap-6">
                {subscriptions.map((subscription) => {
                    return (
                        <SubscriptionsCard
                            key={`subscription_card_${subscription.id}`}
                            data={subscription}
                            selectItem={selectItem}
                        />
                    )
                })}
            </div>
        )
}