import { useNavigate, useParams } from "react-router-dom";
import Avatar from "../../components/Shared/Avatar";
import { AddImage } from "../../icons";
import { useEffect, useState } from "react";
import { isNull, renderImage } from "../../Helpers/utils";
import Input from "../../components/Shared/Input";
import PhoneInput from "../../components/Shared/PhoneInput";
import { GetMemberById } from "../../lib/api";
import { useTranslation } from "react-i18next";
import GlobalSuspense from "../../components/SuspensePages/GlobalSuspense";
import BlockMemberButton from "../../components/Pages/Team/TeamMembers/BlockMemberButton";
import { useAuth } from "../../hooks/useAuth";



const EditMember = () => {

    const { t } = useTranslation()

    const { id } = useParams();

    const navigate = useNavigate();
    const returnBack = () => navigate(-1);


    // Get member data
    const { data: memberData, loading: memberLoading } = GetMemberById({ id });



    const [avatarPreview, setAvatarPreview] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState("")
    // const [errors, setErrors] = useState({})
    const errors = {};


    useEffect(() => {
        setAvatarPreview(renderImage(memberData?.data?.avatar ?? ''))
        setFirstname(memberData?.data?.firstname ?? '')
        setLastname(memberData?.data?.lastname ?? '')
        setEmail(memberData?.data?.email ?? '')
        setGender(memberData?.data?.gender ?? '')

        // set phone number
        const phoneNumber_ = memberData?.data?.phone ?? '';
        const phone_ = phoneNumber_?.split(" ");
        setPhoneCode(phone_?.[0] ?? "")
        setPhone(phone_?.[1] ?? "")

    }, [memberData])


    // handle change user avatar
    const handleChangeAvatar = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatarPreview(reader.result); // Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            setAvatarPreview(renderImage(memberData?.data?.avatar ?? ''));
        }
    }

    const { allowedFor } = useAuth()

    return !allowedFor('owner') ? <></> : (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="w-full">
                <div className="w-full flex justify-between items-end">
                    {/* Text */}
                    <div className="flex flex-col gap-1">
                        <h2 className="flex items-center textHeader-l">
                            {`${t("Edit Member")} #${id}`}
                        </h2>
                    </div>

                    {/* Buttons */}
                </div>
            </div>

            {memberLoading
                ? <GlobalSuspense />
                : <div className="mt-6 w-full grid lg:grid-cols-[1fr_300px] grid-cols-1 gap-4">
                    <div className="opacity-70">
                        <div className="bg-white border border-primaryBorder rounded-lg p-2 md:p-4 lg:p-6">
                            <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                                <Input
                                    type={"text"}
                                    title={t("First name")}
                                    placeholder={""}
                                    value={firstname}
                                    change={(e) => setFirstname(e.target.value)}
                                    error={errors.firstname ?? ''}
                                    readOnly={true}
                                />
                                <Input
                                    type={"text"}
                                    title={t("Last name")}
                                    placeholder={""}
                                    value={lastname}
                                    change={(e) => setLastname(e.target.value)}
                                    error={errors.lastname ?? ''}
                                    readOnly={true}
                                />
                            </div>

                            <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                                <Input
                                    type={"email"}
                                    title={t("Email address")}
                                    placeholder={""}
                                    value={email}
                                    change={(e) => setEmail(e.target.value)}
                                    error={errors.email ?? ''}
                                    readOnly={true}
                                />
                                <PhoneInput
                                    title={t("Phone number")}
                                    placeholder={""}
                                    phoneNumber={phone}
                                    changePhoneNumber={(p) => setPhone(p)}
                                    phoneCode={phoneCode}
                                    changePhoneCode={(c) => setPhoneCode(c)}
                                    error={errors.phone ?? ''}
                                    isDisabled={true}
                                />
                            </div>

                            {/* <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6"> */}
                            <label className="flex flex-col gap-2">
                                <span className="textBody-s1">{t("Gender")}</span>
                                <select
                                    disabled={true}
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                                    <option value="" disabled></option>
                                    <option value="male">{t("Male")}</option>
                                    <option value="female">{t("Female")}</option>
                                </select>
                                {!isNull(errors?.gender) && <p className="textBody-s2 text-red-600">{errors?.gender}</p>}
                            </label>
                            {/* </div> */}
                        </div>
                    </div>

                    <div className="">
                        <div className="opacity-70 bg-white border border-primaryBorder rounded-lg p-4 md:p-6 lg:p-8">
                            <div className="w-full">
                                <div className="relative w-fit overflow-hidden mx-auto">
                                    <Avatar
                                        src={avatarPreview}
                                        size={'w-32 h-32'}
                                        alt={" "}
                                    />
                                    <label className={`absolute cursor-not-allowed flex items-center justify-center transition-colors duration-200 p-2 -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 bg-black/0 text-primary/60 rounded-full w-full h-full`}>
                                        <input type="file" onChange={handleChangeAvatar} disabled accept="image/png, image/jpg, image/jpeg" hidden />
                                        {isNull(avatarPreview) && <AddImage className="w-5 h-5" />}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 w-full flex flex-col">
                            <BlockMemberButton id={id} returnBack={returnBack} />
                        </div>
                    </div>
                </div>
            }

            {/* Buttons */}
        </div>
    )
}
export default EditMember;