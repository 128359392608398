import { useState } from "react";
import Filters from "../../components/Pages/BlockedClients/Filters";
import Heading from "../../components/Pages/BlockedClients/Heading";
import ClientsList1 from "../../components/Pages/BlockedClients/ClientsList1";
import { debounce } from "../../Helpers/utils";
import ClientsSuspense from "../../components/SuspensePages/ClientsSuspense";
import Pagination from "../../components/Pagination";
import { GetAllBlockedClients } from "../../lib/api";
import PageSideBarLayout from "../../layouts/PageSideBarLayout";
import { useAlerts } from "../../context/AlertsContext";


const BlockedList = () => {

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const [params, setParams] = useState({})

    const paramsData = { page: pageNumber, search_query: searchQuery, ...params };

    const { data, loading, refresh } = GetAllBlockedClients(paramsData);
    const clients = data?.data?.data ?? [];


    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);


    // Call alerts provider
    const { addAlert } = useAlerts();


    return (
        <PageSideBarLayout>
            <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
                <Heading
                    totalRows={data?.data?.total ?? 0}
                />

                <Filters
                    params={params}
                    setParams={setParams}
                    handleSearchFilter={handleSearchFilter}
                />


                {/* Show clients list */}
                {loading
                    ? <ClientsSuspense />
                    : <ClientsList1
                        clients={clients}
                        refreshClients={refresh}
                        showNotification={addAlert}
                    />
                }

                <Pagination
                    currentPageNumber={data?.data?.current_page}
                    lastPageNumber={data?.data?.last_page}
                    onChange={(n) => setPageNumber(n)}
                    disabled={loading}
                />
            </div>
        </PageSideBarLayout>
    )
}
export default BlockedList;