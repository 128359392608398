import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"
import Input from '../../components/Shared/Input';
import Button from '../../components/Shared/Button';
import { isNull } from '../../Helpers/utils';
import PhoneInput from '../../components/Shared/PhoneInput';
import { useTranslation } from 'react-i18next';
import useHtmlAttributes from '../../hooks/useHtmlAttributes';
import i18next from 'i18next';
import ModalLayout from '../../layouts/ModalLayout';
import { ArrowRight1 } from '../../icons';
import { useAlerts } from "../../context/AlertsContext";


export default function Register() {

    const { t } = useTranslation()

    const { selectedLang, changeLang } = useHtmlAttributes();


    const Form = () => {

        const { t } = useTranslation()

        const { addAlert } = useAlerts();

        const location = useLocation();
        const searchParams = new URLSearchParams(location.search);
        const referral = searchParams.get('ref') ?? '';


        const { register, isLoading } = useAuth()

        const navigate = useNavigate()

        const [firstname, setFirstname] = useState('')
        const [lastname, setLastname] = useState('')
        const [email, setEmail] = useState('')
        const [password, setPassword] = useState('')
        const [phoneCode, setPhoneCode] = useState("")
        const [phone, setPhone] = useState('')
        const [errors, setErrors] = useState({})


        const [isAgree, setIsAgree] = useState(true)


        // handle form submit
        const sendRequest = async (e) => {
            e.preventDefault();

            if (!isAgree) {
                setErrors({ agree: t('Policies must be accepted') })
                addAlert(t('Policies must be accepted'), 'error');
                return;
            }

            register({
                setErrors,
                firstname,
                lastname,
                email,
                password,
                phone: isNull(phone) ? "" : `${phoneCode} ${phone}`,
                referral_slug: referral
            })
        }

        return (
            <div className="flex flex-col gap-6">

                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                    <Input
                        type={"text"}
                        title={t("First name")}
                        placeholder={t("Enter your first name")}
                        value={firstname}
                        change={(e) => setFirstname(e.target.value)}
                        error={errors.firstname ?? ''}
                    />
                    <Input
                        type={"text"}
                        title={t("Last name")}
                        placeholder={t("Enter your last name")}
                        value={lastname}
                        change={(e) => setLastname(e.target.value)}
                        error={errors.lastname ?? ''}
                    />
                </div>


                <Input
                    type={"email"}
                    title={t("Email address")}
                    placeholder={t("Enter your email address")}
                    value={email}
                    change={(e) => setEmail(e.target.value)}
                    error={errors.email ?? ''}
                />

                <Input
                    type={"password"}
                    title={t("Password")}
                    placeholder={t("Enter your password")}
                    value={password}
                    change={(e) => setPassword(e.target.value)}
                    error={errors.password ?? ''}
                />

                <PhoneInput
                    title={t("Phone number")}
                    placeholder={""}
                    phoneNumber={phone}
                    changePhoneNumber={(p) => setPhone(p)}
                    phoneCode={phoneCode}
                    changePhoneCode={(c) => setPhoneCode(c)}
                    error={errors.phone ?? ''}
                />


                <label className="flex flex-col gap-2 select-none cursor-pointer">
                    <div className="flex items-start gap-2">
                        <input
                            type={'checkbox'}
                            className={`w-4 h-4 mt-[4px] p-3 rounded-lg transition-colors duration-300 ${!isNull(errors.agree) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90 outline-primary/80'}`}
                            onClick={() => setIsAgree(!isAgree)}
                            onChange={(e) => setIsAgree(e.target.checked)}
                            checked={isAgree}
                        />
                        <p className="text-black/60 lg:textBody-s2 textBody-s2">
                            {t("I have read and agree to the")} <a href="https://aratly.com/privacy-policy" target="_blank" rel="noreferrer" className=" hover:underline text-primary">{t("Privacy Policy")}</a> {t("and")} <a href="https://aratly.com/terms-and-conditions" target="_blank" rel="noreferrer" className=" hover:underline text-primary">{t("Terms And Conditions")}</a>
                        </p>
                    </div>
                    {!isNull(errors.agree) && <p className="textBody-s2 text-red-600">{errors.agree}</p>}
                </label>


                <div className="flex flex-col gap-2">
                    <Button
                        name={t("Create an account")}
                        onClick={sendRequest}
                        disabled={isLoading}
                        isLoading={isLoading}
                        styleType="1"
                    />
                    <Button
                        name={t("Login")}
                        onClick={() => navigate(`/auth/login${!isNull(referral) ? '?ref=' + referral : ''}`)}
                        disabled={isLoading}
                        styleType="2"
                    />
                </div>
            </div>
        )
    }


    const Info = () => {

        const { t } = useTranslation()

        return (
            <div className="flex flex-col gap-4">
                <a href="https://aratly.com" className='w-fit'>
                    <div className="w-12 h-12 select-none">
                        <img src="/images/logo_square.jpg" alt="aratly logo" className="w-full h-full rounded-lg" />
                    </div>
                </a>
                <div className="flex flex-col gap-2">
                    <h1 className="textHeader-l">{t("Create an account")}</h1>
                    <p className="textBody-s3 text-secondaryTextColor">{t("Create an account and log in to manage your club.")}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full min-h-screen lg:bg-primary/5 bg-white lg:px-2 lg:py-2 py-4 px-0 lg:flex lg:flex-col lg:justify-center lg:items-center">
            <div className="w-full max-w-4xl mx-auto">
                <div className="w-full bg-white rounded-xl lg:p-6 p-2 lg:shadow-sm">
                    <div className="w-full grid lg:grid-cols-2 grid-cols-1 lg:gap-1 gap-8">
                        <Info />
                        <Form />
                    </div>
                </div>

                <div className="px-2 py-4 w-full flex items-start justify-between mb-10">
                    <LanguagesSwitcher
                        selectedLang={selectedLang}
                        changeLang={changeLang}
                    />
                    <div className="flex items-center gap-3">
                        <a
                            href="https://aratly.com/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                            className="lg:textBody-s3 textBody-xs3 text-black/60 hover:text-primary">
                            {t("Privacy Policy")}
                        </a>
                        <a
                            href="https://aratly.com/terms-and-conditions"
                            target="_blank"
                            rel="noreferrer"
                            className="lg:textBody-s3 textBody-xs3 text-black/60 hover:text-primary">
                            {t("Terms And Conditions")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}


const LanguagesSwitcher = ({ selectedLang, changeLang }) => {

    const currentLanguage = i18next?.language ?? "en";

    const renderCurrentLanguageName = (cLang = 'en') => {
        if (cLang === "ar") {
            return "العربية";
        } else if (cLang === "en") {
            return "English";
        } else if (cLang === "fr") {
            return "French";
        } else {
            return "#";
        }
    }


    const [isOpen, setIsOpen] = useState(false)
    const toogleModal = () => setIsOpen(!isOpen)

    return (
        <div className="relative">
            <button
                onClick={toogleModal}
                className="flex shrink-0 justify-center items-center gap-1.5 bg-transparent rounded text-primaryTextColor cursor-pointer outline-none hover:underline">
                <div className="rounded-full w-4 h-4 bg-primary/10">
                    <img src={`/flags/${currentLanguage}.png`} alt="flag" className="w-full h-full rounded-full" />
                </div>
                <span className="textBody-xs2">{renderCurrentLanguageName(currentLanguage)}</span>
            </button>

            {isOpen &&
                <ModalLayout
                    className="absolute ltr:left-0 rtl:right-0 bottom-8 w-[200px] z-[1011] animation-01 animation-02"
                    close={toogleModal}
                >
                    <div className={`bg-white textBody-s3 w-full rounded-lg gap-1 justify-between flex flex-col overflow-hidden`}>

                        <div className="py-4 px-4 flex flex-col overflow-y-auto">
                            <div className="flex flex-col gap-2">
                                {[
                                    { title: 'English', slug: 'en', },
                                    { title: 'French', slug: 'fr', },
                                    { title: 'Arabic', slug: 'ar', },
                                ].map((lng, i) => (
                                    <button
                                        key={i}
                                        onClick={() => {
                                            changeLang(lng.slug)
                                            toogleModal()
                                        }}
                                        className={`rounded-lg py-2 lg:px-3 px-2 transition-all duration-100 ease-in flex items-center gap-2 textBody-s2 justify-between 
                                            ${selectedLang === lng.slug
                                                ? 'bg-primary/5 border border-primary/10 text-secondaryTextColor'
                                                : 'bg-white border border-primaryBorder/60 hover:border-primaryBorder hover:bg-white/50 text-secondaryTextColor'
                                            }
                                        `}>
                                        <div className="flex items-center gap-2">
                                            <div className="rounded w-[35px] min-h-[24px] bg-primary2 overflow-hidden">
                                                <img
                                                    className="w-full h-auto"
                                                    src={`/flags/${lng.slug}.svg`}
                                                    alt={`${lng.slug}-flag`}
                                                />
                                            </div>
                                            <span className="textBody-s3">{lng.title}</span>
                                        </div>
                                        <span className="rtl:rotate-180"><ArrowRight1 className="w-4 h-4" /></span>
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* footer */}
                    </div>
                </ModalLayout>
            }
        </div>
    )
}