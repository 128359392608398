import { isNull } from "../../../../Helpers/utils";
import FormModal from "../../../Shared/FormModal";
import { useEffect, useState } from "react";
import { Delete1 } from "../../../../icons";
import Confirmation from "../../../Shared/Confirmation";
import { UpdateSportById } from "../../../../lib/api";
import { useTranslation } from "react-i18next";
import { useAlerts } from "../../../../context/AlertsContext";


export default function UpdateSport({ selectedItem, closeModal, refresh, handleRemove, canRemove }) {

    const { t } = useTranslation()
    
    const { addAlert } = useAlerts();

    const [sportId, setSportId] = useState('')
    const [name, setName] = useState("")
    const [status, setStatus] = useState("active")

    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)

    useEffect(() => {
        setSportId(selectedItem.id)
        setName(selectedItem.name)
        setStatus(selectedItem.status)
    }, [selectedItem])


    // handle click 'updateSport'
    const updateSport = async () => {

        const data = {
            sport_id: sportId,
            name,
            status,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await UpdateSportById(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh()
            closeModal();
        }
    }


    const [showConfirmationModalToRemove, setShowConfirmationModalToRemove] = useState(false)


    return (
        <>
            {showConfirmationModalToRemove &&
                <Confirmation
                    onClose={() => setShowConfirmationModalToRemove(false)}
                    onConfirm={() => {
                        handleRemove(sportId)
                        closeModal()
                    }}
                    headingLine={t("Delete Sport")}
                    message={t("Are you sure you want to delete this sport?")}
                />}

            <FormModal
                close={closeModal}
                title={t('Update Sport')}
                save={updateSport}
                isDisabled={inProgress}
                isLoading={inProgress}
                buttonTitle={t("Update")}
                removeBtn={canRemove &&
                    <button
                        onClick={() => setShowConfirmationModalToRemove(true)}
                        className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor/30 hover:text-red-300 cursor-pointer outline-none btn_effect">
                        <Delete1 className="w-5 h-5" />
                    </button>
                }
            >
                <div className="w-full grid grid-cols-1 gap-6">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Name")}</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.name) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </div>
                        {!isNull(errors.name) && <p className="textBody-s2 text-red-600">{errors.name}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Status")}</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.status) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className={`w-full p-3 outline-none appearance-none`}
                            >
                                <option value="active">{t("active")}</option>
                                <option value="inactive">{t("inactive")}</option>
                            </select>
                        </div>
                        {!isNull(errors.status) && <p className="textBody-s2 text-red-600">{errors.status}</p>}
                    </label>
                </div>
            </FormModal>
        </>
    )
}