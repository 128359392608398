import { useTranslation } from "react-i18next"
import { isNull } from "../../../Helpers/utils"

export default function Confirmation({
    onClose = () => { },
    onConfirm = () => { },
    onCancel = () => { },
    headingLine = "heading line",
    message = "message here",
    confirmationBtnName = "",
}) {

    const { t } = useTranslation()

    return (
        <>
            <div onClick={onClose} className="bg-black/80 fixed top-0 left-0 w-full h-full z-[1012] animation-01"></div>
            <div className="fixed lg:max-w-[500px] w-full -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 z-[1013]">
                <div className="w-full bg-white lg:rounded-xl justify-between flex flex-col overflow-hidden max-lg:h-dvh">

                    <div className="p-4">
                        <h3 className="textBody-m1">{headingLine}</h3>
                    </div>

                    <div className="p-4 mb-8 max-lg:h-full overflow-y-auto textBody-s3">
                        <p>{message}</p>
                    </div>

                    {/* Buttons */}
                    <div className="flex items-center gap-2 justify-end p-4">
                        <button
                            onClick={() => {
                                onCancel()
                                onClose()
                            }}
                            className="bg-transparent hover:bg-primaryBorder/30 border-[1.5px] border-primaryBorder text-primaryTextColor rounded-lg py-3 px-6 transition-all duration-100 ease-in active:scale-[.99] flex items-center gap-2 textBody-m1">
                            {t("Cancel")}
                        </button>
                        <button
                            onClick={() => {
                                onConfirm()
                                onClose()
                            }}
                            className="bg-dangerColor hover:bg-dangerColor/80 border-[1.5px] border-dangerColor hover:border-dangerColor/80 text-white rounded-lg py-3 px-6 transition-all duration-100 ease-in active:scale-[.99] flex items-center gap-2 textBody-m1">
                            {!isNull(confirmationBtnName) ? confirmationBtnName : t("Delete")}
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}