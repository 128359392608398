import { getFullName } from "../../../Helpers/utils";
import dayjs from "../../../lib/dayjs";
import { Link } from "react-router-dom"
import GlobalSuspense from "../../SuspensePages/GlobalSuspense";
import { useTranslation } from "react-i18next";


export default function LatestClients({ clients, loading, Currency }) {

    const { t } = useTranslation()

    return loading ? <GlobalSuspense /> : (
        <div className="mt-4 w-full overflow-x-auto">
            <table className="w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none lg:textBody-s2 textBody-xs2">
                        <th className="rtl:text-right ltr:text-left">{t("Name")}</th>
                        <th className="rtl:text-right ltr:text-left">{t("Sport")}</th>
                        <th className="rtl:text-right ltr:text-left">{t("Subscription")}</th>
                        <th className="ltr:text-right rtl:text-left">{t("Joined at")}</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.map((client) => {

                        const { id, sport, subscription, created_at } = client;
                        const { price, months } = subscription;

                        return (
                            <tr key={id} className="[&>*]:px-2 [&>*]:py-3 border-t border-primary/10 cursor-context-menu select-none hover:bg-primary2 lg:textBody-s3 textBody-xs3">
                                <td>
                                    <h3 className="whitespace-nowrap">{getFullName(client)}</h3>
                                </td>
                                <td>
                                    <span className="whitespace-nowrap">{sport?.name ?? '#'}</span>
                                </td>
                                <td>
                                    <span className="whitespace-nowrap">{`${months} ${months > 1 ? t('Months') : t('Month')}`} <span className="textBody-xs3 text-secondaryTextColor">({`${price} ${Currency}`})</span></span>
                                </td>
                                <td className="ltr:text-right rtl:text-left">
                                    <span className="textBody-xs3 text-secondaryTextColor whitespace-nowrap">{dayjs(created_at).fromNow()}</span>
                                </td>
                            </tr>
                        )
                    })}

                    {clients?.length === 10 &&
                        <tr className="[&>*]:px-2 lg:[&>*]:pt-6 [&>*]:pt-3 border-t border-primary/10 cursor-context-menu select-none lg:textBody-s3 textBody-xs3">
                            <td colSpan={4}>
                                <Link to={`/clients`}>
                                    <button className="outline-none hover:underline textBody-s2 text-primary/70">{t("View all clients")}</button>
                                </Link>
                            </td>
                        </tr>}
                </tbody>
            </table>
        </div>
    )
}