import LatestClients from "../../components/Pages/Dashboard/LatestClients";
import LatestTransactions from "../../components/Pages/Dashboard/LatestTransactions";
import StatisticsBar from "../../components/Pages/Dashboard/StatisticsBar";
import StatisticsByNumbers from "../../components/Pages/Dashboard/StatisticsByNumbers";
import { GetDashboardStatistics } from "../../lib/api";
import { useCurrency } from "../../hooks/useCurrency";
import { useTranslation } from "react-i18next";


const Dashboard = () => {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    const { data, loading } = GetDashboardStatistics();
    const stats = data?.data ?? {};
    const totalPrice = stats?.total_price ?? 0;
    const period = stats?.period ?? [];
    const salesByMonth = stats?.salesByMonth ?? [];
    const this_month_statistics = stats?.this_month_statistics ?? {};
    const latest_10_transactions = stats?.latest_10_transactions ?? [];
    const latest_10_clients = stats?.latest_10_clients ?? [];


    

    return (
        <div className="xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="grid lg:grid-cols-2 grid-cols-1 xl:gap-8 lg:gap-6 gap-4">
                <div className="bg-white border border-primaryBorder rounded-lg lg:p-6 sm:p-4 p-2">
                    <div className="flex flex-col">
                        <h3 className="textBody-ml1">{t("Recent sales")}</h3>
                        <span className="text-secondaryTextColor textBody-s3">{t("Last 12 Months")}</span>
                        {!loading &&
                            <span className="mt-4 mb-2 text-[40px] leading-[44px]">{`${Currency} ${totalPrice.toLocaleString()}`}</span>
                        }
                    </div>
                    {/* react-chartjs-2 */}
                    <StatisticsBar period={period} statsData={salesByMonth} loading={loading} />
                </div>

                <div className="bg-white border border-primaryBorder rounded-lg lg:p-6 sm:p-4 p-2">
                    <div className="flex flex-col h-full">
                        <h3 className="textBody-ml1">{t("This month")}</h3>
                        <span className="text-secondaryTextColor textBody-s3">{t("This Month Statistics")}</span>

                        {/* This Month Statistics */}
                        <StatisticsByNumbers data={this_month_statistics} loading={loading} Currency={Currency} />
                    </div>
                </div>

                <div className="bg-white border border-primaryBorder rounded-lg lg:p-6 sm:p-4 p-2">
                    <div className="flex flex-col h-full">
                        <h3 className="textBody-ml1">{t("Payments")}</h3>
                        <span className="text-secondaryTextColor textBody-s3">{t("Latest 10 transactions")}</span>

                        <LatestTransactions payments={latest_10_transactions} loading={loading} Currency={Currency} />
                    </div>
                </div>

                <div className="bg-white border border-primaryBorder rounded-lg lg:p-6 sm:p-4 p-2">
                    <div className="flex flex-col h-full">
                        <h3 className="textBody-ml1">{t("Clients")}</h3>
                        <span className="text-secondaryTextColor textBody-s3">{t("Latest 10 clients")}</span>

                        <LatestClients clients={latest_10_clients} loading={loading} Currency={Currency} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard;