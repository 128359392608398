import { useState } from "react";
import { reqExportClientsAsPdf, reqExportPaymentsAsPdf, reqExportSportsAsPdf, reqExportSubscriptionsAsPdf } from "../lib/api";
import { isNull } from "../Helpers/utils";
import { download } from "../lib/downloadFiles";
import { useTranslation } from "react-i18next";
import { useCurrency } from "./useCurrency";
import { useAlerts } from "../context/AlertsContext";


export const useExport = () => {

    const { t } = useTranslation()

    const { addAlert } = useAlerts();

    const { Currency } = useCurrency()


    // State to store 'isLoading' value, for all requests in this page
    const [isLoadingPdf, setIsLoadingPdf] = useState(false)
    // const [isLoading, setIsLoading] = useState(false)
    // const [isLoading, setIsLoading] = useState(false)



    /**
     * Export Clients
    */
    const exportClientsAsPdf = async ({ paramsData, totalRows }) => {

        if (totalRows === 0) {
            addAlert(t("You have no data to export"), 'error');
            return;
        }

        setIsLoadingPdf(true)

        const { is_ok, message, type, data } = await reqExportClientsAsPdf({}, { ...paramsData, currency_code: Currency });

        if (type === "validation") {
            addAlert(t('Something was wrong!'), 'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            if (!isNull(data?.file_path)) {
                await download(data?.file_path)
            }
        }

        setIsLoadingPdf(false)
    }


    /**
     * Export Sports
    */
    const exportSportsAsPdf = async ({ paramsData, totalRows }) => {

        if (totalRows === 0) {
            addAlert(t("You have no data to export"), 'error');
            return;
        }

        setIsLoadingPdf(true)

        const { is_ok, message, type, data } = await reqExportSportsAsPdf({}, { ...paramsData, currency_code: Currency });

        if (type === "validation") {
            addAlert(t('Something was wrong!'), 'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            if (!isNull(data?.file_path)) {
                await download(data?.file_path)
            }
        }

        setIsLoadingPdf(false)
    }


    /**
     * Export Subscriptions
    */
    const exportSubscriptionsAsPdf = async ({ paramsData, totalRows }) => {

        if (totalRows === 0) {
            addAlert(t("You have no data to export"), 'error');
            return;
        }

        setIsLoadingPdf(true)

        const { is_ok, message, type, data } = await reqExportSubscriptionsAsPdf({}, { ...paramsData, currency_code: Currency });

        if (type === "validation") {
            addAlert(t('Something was wrong!'), 'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            if (!isNull(data?.file_path)) {
                await download(data?.file_path)
            }
        }

        setIsLoadingPdf(false)
    }


    /**
     * Export Payments
    */
    const exportPaymentsAsPdf = async ({ paramsData, totalRows }) => {

        if (totalRows === 0) {
            addAlert(t("You have no data to export"), 'error');
            return;
        }

        setIsLoadingPdf(true)

        const { is_ok, message, type, data } = await reqExportPaymentsAsPdf({}, { ...paramsData, currency_code: Currency });

        if (type === "validation") {
            addAlert(t('Something was wrong!'), 'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            if (!isNull(data?.file_path)) {
                await download(data?.file_path)
            }
        }

        setIsLoadingPdf(false)
    }


    return {
        isLoadingPdf,
        exportClientsAsPdf,
        exportSportsAsPdf,
        exportSubscriptionsAsPdf,
        exportPaymentsAsPdf,
    };
}