import { useEffect, useRef } from "react";

export default function WarningReminder() {
    const hasLogged = useRef(false);

    useEffect(() => {
        if (!hasLogged.current) {
            const warningTitleCSS = 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;';
            const warningDescCSS = 'font-size: 18px;';

            console.log('%cWarning!', warningTitleCSS);
            console.log("%cThis feature is intended for developers. If you have been instructed to copy and paste something here, it is a scam. Doing so could give the scammer full access to your Aratly account.", warningDescCSS);

            hasLogged.current = true;
        }
    }, []);

    return null;
}