import { useEffect, useState } from "react";
import { isNull } from "../../../../../Helpers/utils";
import dayjs from "../../../../../lib/dayjs";
import SelectMonths from "./SelectMonths";
import { useAlerts } from "../../../../../context/AlertsContext";
import Button from "../../../../Shared/Button";
import { GetLastPaidMonthByClient, SaveNewPayment } from "../../../../../lib/api";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../../../../hooks/useCurrency";


export default function PaymentForm({ clientData, setContentSection }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()


    const { addAlert } = useAlerts();


    const { id, membership_number, firstname, lastname, sport, subscription, payment_day } = clientData;


    const [showMonthsPicker, setShowMonthsPicker] = useState(false)
    const toogleMonthsPicker = () => setShowMonthsPicker(!showMonthsPicker)


    // state to store last payment date
    const [selectedMonthToPay, setSelectedMonthToPay] = useState('')

    // Get last paid date
    const { data, loading, refresh } = GetLastPaidMonthByClient({ client_id: id });

    useEffect(() => {
        setSelectedMonthToPay(dayjs(data?.data?.date ?? '').format('YYYY-MM-DD'))
    }, [data])


    // state to store payment remarks
    const [note, setNote] = useState('')


    const [inProgress, setInProgress] = useState(false)


    // handle 'payNow' btn
    const payNow = async () => {

        setInProgress(true)

        const data = {
            client_id: id,
            price: subscription?.price,
            subscription_id: subscription?.id,
            sport_id: sport?.id,
            paid_month: dayjs(selectedMonthToPay).format('YYYY-MM') + '-' + payment_day,
            total_months: subscription?.months,
            note,
        };

        const { is_ok, message, type } = await SaveNewPayment(data);

        setInProgress(false)

        if (type === "validation") {
            addAlert(t('Something was wrong!'), 'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh()
            setContentSection('overview');
        }
    }

    return (
        showMonthsPicker
            ? <SelectMonths
                toogleMonthsPicker={toogleMonthsPicker}
                selectedDate={selectedMonthToPay}
                monthsNumber={subscription?.months ?? 1}
                setSelectedMonthToPay={setSelectedMonthToPay}
            />
            : <div className="lg:border lg:border-l-primaryBorder/60 bg-white min-h-screen">

                {/* Notes Header */}
                <div className="max-lg:hidden sticky top-0 lg:py-4 py-2 lg:px-6 md:px-4 p-2 flex items-center justify-between border-b border-primaryBorder/50 bg-primary2">
                    <h2 className="textHeader-s">{t("Payment")}</h2>
                </div>

                {/* Add New Note */}
                <div className="lg:px-6 md:px-4 p-2 flex flex-col gap-2">
                    <div className="bg-white rounded-lg flex flex-col gap-2">

                        <table className="w-full border-b border-primaryBorder/50">
                            <tbody>
                                <tr className="[&>*]:py-3">
                                    <td>
                                        <span className="lg:textBody-s1 textBody-xs1">{`${t("Client")} :`}</span>
                                    </td>
                                    <td>
                                        <span className="lg:textBody-s3 textBody-xs3 text-secondaryTextColor">{`${firstname} ${lastname}`}</span>
                                    </td>
                                </tr>
                                <tr className="[&>*]:py-3 border-t border-primaryBorder/50">
                                    <td>
                                        <span className="lg:textBody-s1 textBody-xs1">{`${t("Membership Number")} :`}</span>
                                    </td>
                                    <td>
                                        <span className="lg:textBody-s3 textBody-xs3 text-secondaryTextColor">{`#${membership_number}`}</span>
                                    </td>
                                </tr>
                                <tr className="[&>*]:py-3 border-t border-primaryBorder/50">
                                    <td>
                                        <span className="lg:textBody-s1 textBody-xs1">{`${t("Sport")} :`}</span>
                                    </td>
                                    <td>
                                        <span className="lg:textBody-s3 textBody-xs3 text-secondaryTextColor">{sport?.name ?? '#'}</span>
                                    </td>
                                </tr>
                                <tr className="[&>*]:py-3 border-t border-primaryBorder/50">
                                    <td>
                                        <span className="lg:textBody-s1 textBody-xs1">{`${t("Subscription")} :`}</span>
                                    </td>
                                    <td>
                                        <span className="lg:textBody-s3 textBody-xs3 text-secondaryTextColor">
                                            {`${subscription.months} ${subscription.months > 1 ? t('Months') : t('Month')} (${subscription?.price} ${Currency})`}
                                        </span>
                                    </td>
                                </tr>
                                <tr className="[&>*]:py-3 border-t border-primaryBorder/50">
                                    <td>
                                        <span className="lg:textBody-s1 textBody-xs1">{`${t("Payment Day")} :`}</span>
                                    </td>
                                    <td>
                                        <span className="lg:textBody-s3 textBody-xs3 text-secondaryTextColor">
                                            {payment_day}
                                        </span>
                                    </td>
                                </tr>
                                <tr className="[&>*]:py-3 border-t border-primaryBorder/50">
                                    <td className="flex flex-col">
                                        <span className="lg:textBody-s1 textBody-xs1">{`${t("Months that will be paid")} :`}</span>
                                        <span
                                            onClick={toogleMonthsPicker}
                                            className="textBody-s3 text-primary hover:text-primary/90 hover:underline cursor-pointer flex items-center gap-0.5">
                                            {t("Edit")}
                                        </span>
                                    </td>
                                    <td>
                                        {loading ? <span className="lg:textBody-s3 textBody-xs3 text-secondaryTextColor">{t("Loading...")}</span> :
                                            <div className="flex flex-col gap-1 cursor-context-menu">
                                                {!isNull(selectedMonthToPay) &&
                                                    [...Array(subscription?.months ?? 0)].map((n, i) => {
                                                        const _date1 = dayjs(selectedMonthToPay).add(i, 'month').format('MMMM');
                                                        const _date2 = dayjs(selectedMonthToPay).add(i, 'month').format('MM, YYYY');
                                                        return (
                                                            <span key={i} className="lg:textBody-s3 textBody-xs3 text-secondaryTextColor">{_date1} <i className="textBody-xs3 text-secondaryTextColor/80">{`(${_date2})`}</i></span>
                                                        )
                                                    })}
                                            </div>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="mt-4 flex flex-col gap-2">
                            <span className="lg:textBody-s1 textBody-xs1">{`${t("Your note")} :`}</span>
                            <textarea
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                placeholder={t("Type your note here...")}
                                className="w-full min-h-32 max-h-64 p-3 rounded-lg transition-colors duration-300 border border-primaryBorder/60 hover:border-primaryBorder/90 outline-primary/80">
                            </textarea>
                        </div>

                        <div className="mt-4 flex flex-row-reverse items-center gap-2">
                            <Button
                                name={t("Pay Now")}
                                onClick={payNow}
                                disabled={inProgress}
                                isLoading={inProgress}
                                styleType="1"
                            />
                            <span className="textBody-s1 text-[#23d142] cursor-context-menu">{`${subscription?.price ?? '0'} ${Currency}`}</span>
                        </div>
                    </div>
                </div>

                {/* ... */}
            </div>
    )
}