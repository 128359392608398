import { fetchData, useSWRFetch } from "../hooks/useFetch";


/**
 * All api requests in one place
 */

export const GetDashboardStatistics = (params) => useSWRFetch(`api/GetDashboardStatistics`, 'GET', params);
export const GetAllMembers = (params) => useSWRFetch(`api/GetAllMembers`, 'GET', params);
export const GetAllPublicCountries = (params) => useSWRFetch(`api/GetAllPublicCountries`, 'GET', params);
export const GetAllPublicCurrencies = (params) => useSWRFetch(`api/GetAllPublicCurrencies`, 'GET', params);
export const GetClubByAuthUser = (params) => useSWRFetch(`api/GetClubByAuthUser`, 'GET', params);
export const GetAllSubscriptions = (params) => useSWRFetch(`api/GetAllSubscriptions`, 'GET', params);
export const GetAllSports = (params) => useSWRFetch(`api/GetAllSports`, 'GET', params);
export const GetAllPublicSports = (params) => useSWRFetch(`api/GetAllPublicSports`, 'GET', params);
export const GetAllPublicSubscriptions = (params) => useSWRFetch(`api/GetAllPublicSubscriptions`, 'GET', params);
export const GetAllClients = (params) => useSWRFetch(`api/GetAllClients`, 'GET', params);
export const GetAllBlockedClients = (params) => useSWRFetch(`api/GetAllBlockedClients`, 'GET', params);
export const GetClientById = (params) => useSWRFetch(`api/GetClientById`, 'GET', params, {}, true);
export const GetLastPaidMonthByClient = (params) => useSWRFetch(`api/GetLastPaidMonthByClient`, 'GET', params, {}, true);
export const GetPaidAndUnPaidMonthsByClientId = (params) => useSWRFetch(`api/GetPaidAndUnPaidMonthsByClientId`, 'GET', params, {}, true);
export const GetPaymentsHistoryByClientId = (params) => useSWRFetch(`api/GetPaymentsHistoryByClientId`, 'GET', params, {}, true);
export const GetAllNotesByClient = (params) => useSWRFetch(`api/GetAllNotesByClient`, 'GET', params);
export const GetPaymentsByMonth = (params) => useSWRFetch(`api/GetPaymentsByMonth`, 'GET', params, {}, true);
export const GetMemberById = (params) => useSWRFetch(`api/GetMemberById`, 'GET', params, {}, true);

export const saveNewClubPayment = (data, params) => fetchData(`api/saveNewPayment`, 'POST', params, data);

export const CreateNewTeamMember = (data, params) => fetchData(`api/CreateNewTeamMember`, 'POST', params, data);
export const UpdateUserBaseData = (data, params) => fetchData(`api/UpdateUserBaseData`, 'PUT', params, data);
export const UpdateUserPassword = (data, params) => fetchData(`api/UpdateUserPassword`, 'PUT', params, data);
export const UpdateUserContacts = (data, params) => fetchData(`api/UpdateUserContacts`, 'PUT', params, data);

export const CreateNewSport = (data, params) => fetchData(`api/CreateNewSport`, 'POST', params, data);
export const UpdateSportById = (data, params) => fetchData(`api/UpdateSportById`, 'PUT', params, data);
export const DeleteSportById = (data, params) => fetchData(`api/DeleteSportById`, 'DELETE', params, data);

export const CreateNewSubscription = (data, params) => fetchData(`api/CreateNewSubscription`, 'POST', params, data);
export const UpdateSubscriptionById = (data, params) => fetchData(`api/UpdateSubscriptionById`, 'PUT', params, data);
export const DeleteSubscriptionById = (data, params) => fetchData(`api/DeleteSubscriptionById`, 'DELETE', params, data);

export const CreateNewClient = (data, params) => fetchData(`api/CreateNewClient`, 'POST', params, data);
export const EditClientById = (data, params) => fetchData(`api/EditClientById`, 'PUT', params, data);

export const UpdateClubInformations = (data, params) => fetchData(`api/UpdateClubInformations`, 'PUT', params, data);

export const SaveNewPayment = (data, params) => fetchData(`api/SaveNewPayment`, 'POST', params, data);

export const SaveNewNote = (data, params) => fetchData(`api/SaveNewNote`, 'POST', params, data);
export const RemoveNotById = (data, params) => fetchData(`api/RemoveNotById`, 'DELETE', params, data);

export const RemoveMemberById = (data, params) => fetchData(`api/RemoveMemberById`, 'DELETE', params, data);



export const reqExportClientsAsPdf = (data, params) => fetchData(`api/reqExportClientsAsPdf`, 'POST', params, data);
export const reqExportSportsAsPdf = (data, params) => fetchData(`api/reqExportSportsAsPdf`, 'POST', params, data);
export const reqExportSubscriptionsAsPdf = (data, params) => fetchData(`api/reqExportSubscriptionsAsPdf`, 'POST', params, data);
export const reqExportPaymentsAsPdf = (data, params) => fetchData(`api/reqExportPaymentsAsPdf`, 'POST', params, data);


export const BlockCLientById = (data, params) => fetchData(`api/BlockCLientById`, 'PUT', params, data);
export const UnBlockCLientById = (data, params) => fetchData(`api/UnBlockCLientById`, 'PUT', params, data);