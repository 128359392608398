
import { useState } from "react";
import Filters from "../../components/Pages/Payments/Filters";
import Heading from "../../components/Pages/Payments/Heading";
import PaymentsList from "../../components/Pages/Payments/PaymentsList";
import { isNull } from "../../Helpers/utils";
import PaymentsSuspense from "../../components/SuspensePages/PaymentsSuspense";
import Pagination from "../../components/Pagination";
import dayjs from "../../lib/dayjs"
import { GetPaymentsByMonth } from "../../lib/api";


const Payments = () => {

    const [pageNumber, setPageNumber] = useState(1)

    const [paidDate, setPaidDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"))

    const paramsData = !isNull(paidDate) ? { page: pageNumber, paid_at: paidDate } : {};

    // Get subscriptions list
    const { data, loading } = GetPaymentsByMonth(paramsData);
    const payments = data?.data?.data ?? [];


    // Calculate the total price
    const totalPrice = !isNull(payments) ? payments.reduce((total, payment) => {
        return total + parseFloat(payment.price);
    }, 0) : 0;

    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <Heading
                totalRows={data?.data?.total ?? 0}
                paramsData={paramsData}
            />
            
            <Filters
                totalPrice={totalPrice}
                selectedDate={paidDate}
                setSelectedDate={setPaidDate}
            />

            {loading
                ? <PaymentsSuspense />
                : <PaymentsList
                    payments={payments}
                    selectedMonth={dayjs(paidDate).format("MMMM, YYYY")}
                />
            }

            <Pagination
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />
        </div>
    )
}
export default Payments;