
import React, { useEffect, useState } from "react";
import { ArrowLeft1 } from "../icons"
import { Link, useLocation } from "react-router-dom"
import { useIsMobile } from "../hooks/useIsMobile";
import { useTranslation } from "react-i18next";


export default function PageSideBarLayout({ children }) {

    const { t } = useTranslation()

    // Get path name
    const location = useLocation();

    const isMobile = useIsMobile();

    // State to store asideBar status
    const [showLinks, setShowLinks] = useState(false)//useState(true)

    useEffect(() => {
        // setShowLinks(!isMobile)
    }, [isMobile])

    // check if the link is active or not.
    const isLinkActive = (link) => {
        const { search, pathname } = location ?? {};

        const fullUrl = (pathname ?? "") + (search ?? "");

        return link === fullUrl;
    }

    const aside = {
        title: t("Clients"),
        links: [
            {
                path: "/clients",
                name: t("Clients list"),
            }, {
                path: "/clients/blocked",
                name: t("Blocked clients"),
            },
        ],
    };

    return (
        <div className="w-full h-full">

            {/* Aside & Content */}
            <div className="relative w-full h-full flex overflow-x-hidden">
                {/* AsideBar */}
                <div className={`${showLinks ? '' : 'hidden'} max-lg:shadow-xl`}>
                    <div className={`w-[200px] min-h-[calc(100dvh_-_70px)] max-lg:min-h-[100dvh] max-lg:overflow-y-auto bg-white`}>
                        {/* Title */}
                        <div className="border-b border-primaryBorder/60">
                            <div className="py-4 px-4">
                                <p className="textBody-s1">{aside.title}</p>
                            </div>
                        </div>

                        {/* Links */}
                        <div className="px-2 pt-1 pb-4 gap-2">
                            {aside?.links?.map((link, i) => (
                                <div
                                    key={i}
                                    className={`rounded-lg lg:textBody-s3 textBody-xs3
                                        ${isLinkActive(link.path)
                                            ? 'bg-primary/10 '
                                            : 'hover:bg-primary/5'
                                        }
                                    `}>
                                    <Link to={link.path}>
                                        <div className={`p-3 ${isLinkActive(link.path) ? 'cursor-context-menu' : 'cursor-pointer'}`}>
                                            <p className="line-clamp-1">{link.name}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Toggle Button */}
                <button
                    onClick={() => setShowLinks(!showLinks)}
                    className={`bg-white hover:bg-gray-100 border border-gray-200 hover:border-gray-300 text-gray-700 lg:p-1 p-2 rounded-full z-10 transition-colors duration-300
                        ${showLinks
                            ? 'ltr:left-[184px] ltr:max-lg:left-[186px] rtl:right-[184px] rtl:max-lg:right-[186px] rotate-0 ltr:rotate-0 rtl:rotate-180'
                            : 'ltr:left-[8px] rtl:right-[8px] rtl:rotate-0 ltr:rotate-180'
                        }
                        absolute sm:top-3 top-2
                        `}>
                    <ArrowLeft1 className="lg:w-5 lg:h-5 w-4 h-4" />
                </button>

                {/* Contnet */}
                <div className={`relative w-full ${showLinks ? 'rtl:lg:border-r ltr:lg:border-l lg:border-primaryBorder/60' : ''}`}>
                    {showLinks && <div onClick={() => setShowLinks(!showLinks)} className="lg:hidden absolute top-0 left-0 w-full h-full bg-black/30 z-[9] animation-01"></div>}
                    <div className="">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
