import { Link, useNavigate } from "react-router-dom";
import Avatar from "../../components/Shared/Avatar";
import { useState } from "react";
import { isNull, n } from "../../Helpers/utils";
import { AddImage } from "../../icons";
import Button from "../../components/Shared/Button";
import Input from "../../components/Shared/Input";
import { useAlerts } from "../../context/AlertsContext";
import PhoneInput from "../../components/Shared/PhoneInput";
import { CreateNewClient, GetAllPublicSports, GetAllPublicSubscriptions } from "../../lib/api";
import { useTranslation } from "react-i18next";
import Textarea from "../../components/Shared/Textarea";
import { useCurrency } from "../../hooks/useCurrency";


const NewClient = () => {
    const navigate = useNavigate();
    const returnBack = () => navigate(-1);

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    const { addAlert } = useAlerts();


    // Get all public sports list
    const { data: sportsData, loading: sportsLoading } = GetAllPublicSports();


    // Get all public subscriptions list
    const { data: subscriptionsData, loading: subscriptionsLoading } = GetAllPublicSubscriptions();



    const [avatar, setAvatar] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phoneCode, setPhoneCode] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState("")
    const [sport, setSport] = useState("")
    const [subscription, setSubscription] = useState("")
    const [note, setNote] = useState('')
    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)


    const handleChangeAvatar = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result); // Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            setAvatar("");
        }
    }


    // reset  or empty data after create new client account
    const resetData = () => {
        setAvatar("")
        setFirstname("")
        setLastname("")
        setPhone("")
        setEmail("")
        setGender("")
        setSport("")
        setSubscription("")
        setNote("")
    }


    // send request
    const saveNewClient = async () => {

        const data = {
            avatar,
            firstname,
            lastname,
            email,
            phone: isNull(phone) ? "" : `${phoneCode} ${phone}`,
            sport,
            gender,
            subscription,
            note,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await CreateNewClient(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            resetData();
            navigate('/clients') // redirect to the clientsList page
        }
    }

    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="mt-4 w-full">
                <div className="w-full flex justify-between items-end">
                    {/* Text */}
                    <div className="flex flex-col gap-1">
                        <h2 className="flex items-center textHeader-l">
                            {t("Add new client")}
                        </h2>
                    </div>

                    {/* Buttons */}
                    <div className="max-lg:hidden flex items-center gap-2">
                        <Button
                            name={t("Cancel")}
                            onClick={returnBack}
                            disabled={inProgress}
                            styleType="2"
                        />
                        <Button
                            name={t("Save")}
                            onClick={saveNewClient}
                            disabled={inProgress}
                            isLoading={inProgress}
                            styleType="1"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-6 w-full grid lg:grid-cols-[1fr_300px] grid-cols-1 gap-4">
                <div className="bg-white border border-primaryBorder rounded-lg p-2 md:p-4 lg:p-6">
                    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <Input
                            type={"text"}
                            title={t("First name")}
                            placeholder={""}
                            value={firstname}
                            change={(e) => setFirstname(e.target.value)}
                            error={errors.firstname ?? ''}
                        />
                        <Input
                            type={"text"}
                            title={t("Last name")}
                            placeholder={""}
                            value={lastname}
                            change={(e) => setLastname(e.target.value)}
                            error={errors.lastname ?? ''}
                        />
                    </div>

                    <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <Input
                            type={"email"}
                            title={t("Email address")}
                            placeholder={""}
                            value={email}
                            change={(e) => setEmail(e.target.value)}
                            error={errors.email ?? ''}
                            isOptional={true}
                        />
                        <PhoneInput
                            title={t("Phone number")}
                            placeholder={""}
                            phoneNumber={phone}
                            changePhoneNumber={(p) => setPhone(p)}
                            phoneCode={phoneCode}
                            changePhoneCode={(c) => setPhoneCode(c)}
                            error={errors.phone ?? ''}
                            isOptional={true}
                        />
                    </div>

                    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <label className="flex flex-col gap-2">
                            <div className="flex items-center justify-between">
                                <span className="textBody-s1">{t("Sport")}</span>
                                <Link to={`/sports`} className="text-primary hover:underline textBody-2xs2">{t("View all")}</Link>
                            </div>
                            <select
                                value={sport}
                                onChange={(e) => setSport(n(e.target.value))}
                                className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                                {sportsLoading
                                    ? <option value="" disabled>{t("Loading...")}</option>
                                    : <>
                                        <option value="" disabled></option>
                                        {sportsData?.data?.data?.map((sport) => (
                                            <option key={sport.id} value={sport.id}>{sport.name}</option>
                                        ))}
                                    </>
                                }
                            </select>
                            {!isNull(errors.sport) && <p className="textBody-s2 text-red-600">{errors.sport}</p>}
                        </label>

                        <label className="flex flex-col gap-2">
                            <div className="flex items-center justify-between">
                                <span className="textBody-s1">{t("Subscription")}</span>
                                <Link to={`/subscriptions`} className="text-primary hover:underline textBody-2xs2">{t("View all")}</Link>
                            </div>
                            <select
                                value={subscription}
                                onChange={(e) => setSubscription(n(e.target.value))}
                                className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                                {subscriptionsLoading
                                    ? <option value="" disabled>{t("Loading...")}</option>
                                    : <>
                                        <option value="" disabled></option>
                                        {subscriptionsData?.data?.data?.map((subscription) => {
                                            const { id, months, price } = subscription;
                                            return (
                                                <option key={id} value={id}>
                                                    {`${months} ${months > 1 ? t('Months') : t('Month')} (${price}${Currency})`}
                                                </option>
                                            )
                                        }
                                        )}
                                    </>
                                }
                            </select>
                            {!isNull(errors.subscription) && <p className="textBody-s2 text-red-600">{errors.subscription}</p>}
                        </label>
                    </div>
                </div>

                <div className="bg-white border border-primaryBorder rounded-lg p-2 md:p-4 lg:p-6">
                    <div className="w-full border-b border-primaryBorder/50 mb-4 pb-4">
                        <div className="relative w-fit overflow-hidden mx-auto">
                            <Avatar
                                src={avatar}
                                size={'lg:w-32 lg:h-32 w-24 h-24'}
                                alt={" "}
                            />
                            <label className={`absolute cursor-pointer flex items-center justify-center transition-colors duration-200 p-2 -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 bg-black/0 hover:bg-black/10 text-primary/60 rounded-full w-full h-full`}>
                                <input type="file" onChange={handleChangeAvatar} accept="image/png, image/jpg, image/jpeg" hidden />
                                {isNull(avatar) && <AddImage className="w-5 h-5" />}
                            </label>
                        </div>
                    </div>

                    <Textarea
                        title={t("Note")}
                        placeholder={t("Type a note for this client...")}
                        value={note}
                        change={(e) => setNote(e.target.value)}
                        error={errors.note ?? ''}
                    />
                </div>

                {/* Buttons */}
                <div className="lg:hidden grid grid-cols-1 gap-2 mt-8">
                    <Button
                        name={t("Save")}
                        onClick={saveNewClient}
                        disabled={inProgress}
                        isLoading={inProgress}
                        styleType="1"
                    />
                    <Button
                        name={t("Cancel")}
                        onClick={returnBack}
                        disabled={inProgress}
                        styleType="2"
                    />
                </div>
            </div>
        </div>
    )
}
export default NewClient;