import { Spinner } from "../../../icons"


export default function Button({
    type = "button",
    name = "Button",
    onClick = () => { },
    disabled = false,
    isLoading = false,
    styleType = "1",
}) {

    // render colors style style
    const renderStyleColors = () => {
        switch (styleType) {
            case "1":
                return `bg-primary border-[1.5px] border-primary text-white ${disabled ? 'opacity-90' : 'hover:bg-primary/80 hover:border-primary/80'}`
            case "2":
                return `bg-white border-[1.5px] border-primaryBorder text-secondaryTextColor ${disabled ? 'opacity-90' : 'hover:underline'}`
            default:
                return ""
        }
    }

    return (
        <div className="flex flex-col">
            <button
                onClick={onClick}
                type={type}
                disabled={disabled}
                className={`rounded-lg py-3 px-6 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-m1 ${disabled ? '' : 'active:scale-[.99]'} ${renderStyleColors()}`}>
                {isLoading
                    ? <Spinner className="w-6 h-6 animate-spin" />
                    : name}
            </button>
        </div>
    )
}