import { useCallback, useEffect, useState } from 'react';
import useCookies from './useCookies';
import i18n from 'i18next';
import { isNull } from '../Helpers/utils';


const useHtmlAttributes = () => {
    const { getCookie } = useCookies();

    const [lng, setLng] = useState('');

    const setAtt = useCallback(() => {
        const selectedLang = getCookie('aratlyLocale') || 'en';
        const direction = !isNull(selectedLang) && selectedLang === 'ar' ? 'rtl' : 'ltr';

        document.documentElement.lang = selectedLang;
        document.documentElement.dir = direction;
        setLng(selectedLang);
    }, [getCookie]);

    useEffect(() => {
        setAtt();
    }, [setAtt]);

    const changeLang = (lng) => {
        i18n.changeLanguage(lng);
        setAtt();
    };

    return { selectedLang: lng, changeLang };
};

export default useHtmlAttributes;
