import { useTranslation } from "react-i18next";
import { ArrowRight1 } from "../../icons";
import { Link } from "react-router-dom"


const Settings = () => {

    const { t } = useTranslation();

    const links1 = [
        {
            title: t("Clients"),
            headerLink: "/clients/new",
            childrens: [
                {
                    title: t("View clients list"),
                    href: "/clients",
                }, {
                    title: t("View blocked clients"),
                    href: "/clients/blocked",
                }, {
                    title: t("Add new client"),
                    href: "/clients/new",
                },
            ],
        },
        {
            title: t("Members"),
            headerLink: "/team/new",
            childrens: [
                {
                    title: t("View members list"),
                    href: "/team",
                }, {
                    title: t("Add new member"),
                    href: "/team/new",
                },
            ],
        },
        {
            title: t("Subscriptions"),
            headerLink: "",
            childrens: [
                {
                    title: t("View subscriptions list"),
                    href: "/subscriptions",
                },
            ],
        },
    ];

    const links2 = [
        {
            title: t("Club"),
            headerLink: "",
            childrens: [
                {
                    title: t("Edit club informations"),
                    href: "/club",
                },
            ],
        },
        {
            title: t("Payments"),
            headerLink: "",
            childrens: [
                {
                    title: t("View payments list"),
                    href: "/payments",
                },
            ],
        },
        {
            title: t("Sports"),
            headerLink: "",
            childrens: [
                {
                    title: t("View sports list"),
                    href: "/sports",
                },
            ],
        },
    ];

    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="w-full">
                <div className="w-full flex justify-between items-center">
                    {/* Text */}
                    <div className="flex flex-col gap-2">
                        <h2 className="flex items-center textHeader-l">
                            {t('Settings')}
                        </h2>
                        <p className="textBody-s3">{t("Manage all your business settings in one place.")}</p>
                    </div>
                </div>
            </div>
            {/* Content */}
            <div className="mt-6 grid lg:grid-cols-2 grid-cols-1 gap-4 lg:gap-6">

                <div className="flex flex-col gap-4 lg:gap-6">
                    {links1?.map((linksCard, i) => (
                        <div
                            key={`links_card_${i}`}
                            className="bg-white border border-primaryBorder rounded-lg overflow-hidden">
                            <div className="py-3 lg:py-4 px-2 lg:px-4 flex items-start justify-between border-b border-primaryBorder/40">
                                <h3 className="textBody-ml1">{linksCard?.title}</h3>
                                {/* {linksCard?.headerLink &&
                                    <Link to={linksCard?.headerLink}>
                                        <button
                                            className="p-1 flex shrink-0 bg-transparent rounded-full text-secondaryTextColor cursor-pointer outline-none btn_effect">
                                            <AddNew1 className="lg:w-6 lg:h-6 w-5 h-5" />
                                        </button>
                                    </Link>
                                } */}
                            </div>
                            <div className="flex flex-col text-secondaryTextColor">
                                {linksCard?.childrens?.map((link, i) => (
                                    <Link key={`link_${i}`} to={link?.href}>
                                        <div className="py-3 lg:py-4 px-2 lg:px-4 hover:bg-primary2 flex items-start justify-between cursor-pointer transition-colors duration-200 ease-in">
                                            <h3 className="textBody-s3 line-clamp-1">{link?.title}</h3>
                                            <span className="py-1 px-2 rtl:rotate-180"><ArrowRight1 className="w-4 h-4" /></span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col gap-4 lg:gap-6">
                    {links2?.map((linksCard, i) => (
                        <div
                            key={`links_card_${i}`}
                            className="bg-white border border-primaryBorder rounded-lg overflow-hidden">
                            <div className="py-3 lg:py-4 px-2 lg:px-4 flex items-start justify-between border-b border-primaryBorder/40">
                                <h3 className="textBody-ml1">{linksCard?.title}</h3>
                                {/* {linksCard?.headerLink &&
                                    <Link to={linksCard?.headerLink}>
                                        <button
                                            className="p-1 flex shrink-0 bg-transparent rounded-full text-secondaryTextColor cursor-pointer outline-none btn_effect">
                                            <AddNew1 className="lg:w-6 lg:h-6 w-5 h-5" />
                                        </button>
                                    </Link>
                                } */}
                            </div>
                            <div className="flex flex-col text-secondaryTextColor">
                                {linksCard?.childrens?.map((link, i) => (
                                    <Link key={`link_${i}`} to={link?.href}>
                                        <div className="py-3 lg:py-4 px-2 lg:px-4 hover:bg-primary2 flex items-start justify-between cursor-pointer transition-colors duration-200 ease-in">
                                            <h3 className="textBody-s3 line-clamp-1">{link?.title}</h3>
                                            <span className="py-1 px-2 rtl:rotate-180"><ArrowRight1 className="w-4 h-4" /></span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}
export default Settings;