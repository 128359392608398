import { getFullName, isNull, renderImage } from "../../../../../Helpers/utils";
import { AddNew4, Spinner } from "../../../../../icons";
import Avatar from "../../../../Shared/Avatar";
import dayjs from "../../../../../lib/dayjs";
import Pagination from "../../../../Pagination";
import { useState } from "react";
import { GetPaymentsHistoryByClientId } from "../../../../../lib/api";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../../../../hooks/useCurrency";

export default function PaymentsHistory({ clientData, setContentSection }) {

    const { t } = useTranslation()

    const { id } = clientData;

    const [pageNumber, setPageNumber] = useState(1)

    // Get paid/unPaid months
    const { data, loading } = GetPaymentsHistoryByClientId({ client_id: id, page: pageNumber });


    return (
        <div className="min-h-screen">
            <div className="max-lg:hidden sticky top-0 lg:py-4 py-2 lg:px-6 md:px-4 p-2 flex items-center justify-between border-b border-primaryBorder/50 bg-primary2 z-50">
                <h2 className="textHeader-s">{t("Payments History")}</h2>
                <button
                    onClick={() => setContentSection('pay')}
                    className="bg-transparent hover:bg-primaryBorder/30 lg:border-[1.5px] border border-primaryBorder text-primaryTextColor rounded-lg py-1 px-3 transition-all duration-100 ease-in active:scale-[.99] flex items-center gap-1 textBody-m1">
                    {t("Pay Now")}
                    <AddNew4 className="w-4 h-4" />
                </button>
            </div>

            <div className="py-4 lg:px-6 md:px-4 p-2">
                {loading
                    ? <Suspense />
                    : <RenderPayments data={data?.data?.data ?? []} />
                }

                <Pagination
                    currentPageNumber={data?.data?.current_page}
                    lastPageNumber={data?.data?.last_page}
                    onChange={(n) => setPageNumber(n)}
                    disabled={loading}
                />
            </div>

        </div>
    )
}


const RenderPayments = ({ data }) => {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    return (
        <div className="flex flex-col gap-4">
            {isNull(data) ? <span className="textBody-xs3 text-secondaryTextColor cursor-context-menu">{t("No results")}</span> :
                data?.map((item) => {

                    const { id, price, created_at, client, months, paid_months, remarks } = item;

                    const paidMonthsArr = !isNull(paid_months) ? paid_months.split(',') : [];

                    return (
                        <div
                            key={id}
                            className={`p-4 bg-white border border-primaryBorder/50 rounded-lg flex flex-col select-none`}>
                            <div className="flex items-start justify-between gap-1">
                                <div className="w-full flex flex-col gap-1">
                                    <div className="flex gap-2">
                                        <div className="">
                                            <Avatar
                                                src={renderImage(client?.avatar)}
                                                size={'w-12 h-12'}
                                                alt={getFullName(client)}
                                            />
                                        </div>
                                        <div className="w-full [&>*]:whitespace-nowrap">
                                            <h3 className="textBody-s3">{getFullName(client)}</h3>
                                            <span className="textBody-xs3 text-secondaryTextColor">{dayjs(created_at).format("D MMMM, YYYY")} <span>({dayjs(created_at).fromNow()})</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <span className="textBody-s1 text-[#4CAF50] whitespace-nowrap">{`${price} ${Currency}`}</span>
                                </div>
                            </div>

                            <div className="my-3 border-b border-primaryBorder/30"></div>

                            <div className="flex gap-2">
                                <div className="relative w-12 flex flex-col items-center justify-center">
                                    <div className="w-0.5 h-full bg-primary/5 rounded-xl"></div>

                                    <div className="absolute -translate-x-2/4 left-2/4 top-0 h-full w-fit flex flex-col justify-between py-[0.3rem]">
                                        {[...Array(months ?? 0)].map((n, i) => (
                                            <div key={`_p_b_${i}`} className="w-1.5 h-1.5 bg-primary/15 rounded-xl"></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full [&>*]:whitespace-nowrap">
                                    {paidMonthsArr?.map((m, i) => {
                                        const _date1 = dayjs(m).format('MMMM');
                                        const _date2 = dayjs(m).format('MM, YYYY');
                                        return (
                                            <span key={`_p_m_${i}`} className="textBody-xs2 text-secondaryTextColor">{_date1} <i className="textBody-xs3 text-secondaryTextColor/80">{`(${_date2})`}</i></span>
                                        )
                                    })}
                                </div>
                            </div>

                            {!isNull(remarks) && <>
                                <div className="my-3 border-b border-primaryBorder/30"></div>

                                <div className="flex flex-col gap-2">
                                    <p className="textBody-s2">Remark</p>
                                    <p className="textBody-s3 text-secondaryTextColor">{remarks}</p>
                                </div>
                            </>}

                        </div>
                    )
                }
                )}
        </div>
    )
}


const Suspense = () => {
    return (
        <div className="p-6 min-h-96 flex items-center justify-center">
            <Spinner className="w-10 h-10 animate-spin" />
        </div>
    )
}