import { renderImage } from "../../../../Helpers/utils";
import Avatar from "../../../Shared/Avatar";
import { Link } from "react-router-dom";


export default function MemberCard({ data = {}, canUpdate }) {

    const { id, firstname, lastname, email, phone, avatar, role } = data;


    const card = () => {
        return (
            <div className={`bg-white border border-primary/10 hover:border-primary/15 rounded-2xl lg:p-6 p-4 transition-all duration-200 ease-in select-none overflow-hidden ${canUpdate ? 'cursor-pointer' : ''}`}>
                <div className="w-full flex flex-col items-center justify-center text-center gap-2">
                    <Avatar
                        src={renderImage(avatar)}
                        size={'w-20 h-20'}
                        alt={`${firstname} ${lastname}`}
                    />
                    <div className="flex flex-col">
                        <h3 className="textBody-m3 line-clamp-1 whitespace-nowrap">{`${firstname} ${lastname}`}</h3>
                        <span className="text-secondaryTextColor textBody-s3 line-clamp-1 mt-2 mb-1 whitespace-nowrap">{email}</span>
                        <span className="text-secondaryTextColor textBody-s3 line-clamp-1 whitespace-nowrap">{phone}</span>
                    </div>
                </div>
            </div>
        )
    }

    return canUpdate ? <Link to={role === 'owner' ? `/profile` : `/team/edit/${id}`} className="group">{card()}</Link> : card();
}