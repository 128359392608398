import { useTranslation } from "react-i18next";
import { getFullName, isNull } from "../../../../Helpers/utils";
import dayjs from "../../../../lib/dayjs";
import NotResultsFound from "../../../NotResultsFound";
import { useCurrency } from "../../../../hooks/useCurrency"


export default function PaymentsList({ payments, selectedMonth }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    return isNull(payments)
        ? <NotResultsFound title={t("No results")} desc={`${t('No transactions found on this month')} (${selectedMonth})`} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none lg:textBody-s1 textBody-xs1">
                        <th className="ltr:text-left rtl:text-right font-semibold">{t("Client")}</th>
                        <th className="ltr:text-left rtl:text-right font-semibold max-lg:hidden">{t("Sport")}</th>
                        <th className="lg:text-left ltr:text-right rtl:text-left font-semibold">{t("Price & Months")}</th>
                        <th className="ltr:text-right rtl:text-left font-semibold max-lg:hidden">{t("Paid at")}</th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map((payment) => {

                        const { id, sport, client, price, months, created_at } = payment;

                        return (
                            <tr key={id} className="[&>*]:px-2 [&>*]:py-3 border-t border-primary/10 cursor-pointer select-none transition-all duration-200 ease-in hover:bg-primary2">
                                <td>
                                    <div className="flex flex-col gap-1">
                                        <h3 className="lg:textBody-m2 textBody-s2">{getFullName(client)}</h3>
                                        <span className="textBody-xs3 lg:hidden">{sport?.name}</span>
                                        <span className="textBody-xs3 lg:hidden">{dayjs(created_at).format("D MMMM, YYYY")} <span className="text-xs text-secondaryTextColor">({dayjs(created_at).fromNow()})</span></span>
                                    </div>
                                </td>
                                <td className="max-lg:hidden">
                                    <span className="textBody-s3">{sport?.name}</span>
                                </td>
                                <td className="lg:text-left ltr:text-right rtl:text-left">
                                    <span className="lg:block hidden textBody-s1">{`${price} ${Currency}`} <span className="textBody-xs3 text-secondaryTextColor">{`/ ${months} ${months > 1 ? t("Months") : t("Month")}`}</span></span>
                                    <div className="lg:hidden block">
                                        <p className="flex flex-col gap-1">
                                            <span className="textBody-s1">{`${price} ${Currency}`}</span>
                                            <span className="textBody-xs3 text-secondaryTextColor">{`${months} ${months > 1 ? t("Months") : t("Month")}`}</span>
                                        </p>
                                    </div>
                                </td>
                                <td className="max-lg:hidden ltr:text-right rtl:text-left">
                                    <span className="textBody-s3">{dayjs(created_at).format("D MMMM, YYYY")} <span className="text-xs text-secondaryTextColor">({dayjs(created_at).fromNow()})</span></span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
}