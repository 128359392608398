import { useState } from "react";
import { AddNew2, Menu1 } from "../../../../icons";
import ModalLayout from "../../../../layouts/ModalLayout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function MobileDropDownMenu() {

    const { t } = useTranslation()

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen)

    return (
        <div className="block lg:hidden">
            {/* Button */}
            <div className="flex justify-end">
                <button onClick={toggle} className="bg-transparent hover:bg-primaryBorder/30 border-[1.5px] border-primaryBorder text-primaryTextColor rounded-lg p-2 transition-all duration-100 ease-in active:scale-[.97] flex items-center textBody-m1">
                    <Menu1 className="w-4 h-4" />
                </button>
            </div>

            {/* Modal */}
            {isOpen &&
                <ModalLayout
                    wallBackground={"bg-black/90"}
                    className={"w-full fixed -translate-x-2/4 left-2/4 bottom-0 z-[1010] p-4 flex flex-col gap-4 animation-01"}
                    close={toggle}
                >
                    <div className={`bg-white textBody-s3 py-2 w-full rounded-lg gap-1 justify-between flex flex-col overflow-hidden [&>*]:w-full [&>*]:flex [&>*]:items-center [&>*]:justify-between [&>*]:select-none [&>*]:py-3 [&>*]:px-4`}>
                        <button
                            onClick={() => {
                                toggle()
                                navigate('/team/new')
                            }}
                            className="outline-none hover:bg-primary/5">
                            <p className="line-clamp-1">{t("Add new member")}</p>
                            <AddNew2 className="w-4 h-4" />
                        </button>
                        {/* <button className="outline-none hover:bg-primary/5">
                            <p className="line-clamp-1">Export as Excel</p>
                            <ArrowBottom1 className="w-4 h-4" />
                        </button>
                        <button className="outline-none hover:bg-primary/5">
                            <p className="line-clamp-1">Export as PDF</p>
                            <ArrowBottom1 className="w-4 h-4" />
                        </button>
                        <button className="outline-none hover:bg-primary/5">
                            <p className="line-clamp-1">Export as CSV</p>
                            <ArrowBottom1 className="w-4 h-4" />
                        </button> */}
                    </div>

                    <button
                        onClick={toggle}
                        className="bg-white outline-none p-3 rounded-lg textBody-s3">
                        {t("Close")}
                    </button>
                </ModalLayout>}
        </div>
    )
}