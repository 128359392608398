import { useEffect, useState, useMemo, useCallback } from "react";
import { ArrowLeft1, ArrowRight1 } from "../../../../../icons";
import dayjs from "../../../../../lib/dayjs";
import { isNull } from "../../../../../Helpers/utils";
import { useTranslation } from "react-i18next";


export default function SelectMonths({
    toogleMonthsPicker = () => { },
    selectedDate = dayjs(new Date()).format('YYYY-MM-DD'),
    monthsNumber = 3,
    setSelectedMonthToPay = () => { },
}) {

    const { t } = useTranslation()

    const [dates, setDates] = useState([]);
    const [currentPage, setCurrentPage] = useState(2);
    const [hoveredDate, setHoveredDate] = useState(null);

    useEffect(() => {
        const startYear = dayjs().subtract(10, 'year');
        const endYear = dayjs().add(10, 'year');
        const datesArray = [];

        for (let date = startYear; date.isBefore(endYear); date = date.add(1, 'month')) {
            datesArray.push({
                formattedDate: date.format('YYYY-MM-DD'),
                year: date.format('YYYY'),
                month: date.format('MM'),
                monthName: date.format('MMMM'),
                day: date.format('DD'),
            });
        }

        const partSize = Math.ceil(datesArray.length / 6);
        setDates([
            datesArray.slice(0, partSize),
            datesArray.slice(partSize, partSize * 2),
            datesArray.slice(partSize * 2, partSize * 3),
            datesArray.slice(partSize * 3, partSize * 4),
            datesArray.slice(partSize * 4, partSize * 5),
            datesArray.slice(partSize * 5),
        ]);
    }, []);

    useEffect(() => {
        for (let i = 0; i < 6; i++) {
            if (dates[i]?.find(d => dayjs(d.formattedDate).isSame(dayjs(selectedDate), 'month'))) {
                setCurrentPage(i + 1);
                break;
            }
        }
    }, [dates, selectedDate]);

    const selectedDates = useMemo(() => {
        if (isNull(selectedDate)) return [];
        return Array.from({ length: monthsNumber }, (_, index) =>
            dayjs(selectedDate).add(index, 'month').format('YYYY-MM')
        );
    }, [selectedDate, monthsNumber]);

    const hoveredDates = useMemo(() => {
        if (isNull(hoveredDate)) return [];
        return Array.from({ length: monthsNumber }, (_, index) =>
            dayjs(hoveredDate).add(index, 'month').format('YYYY-MM')
        );
    }, [hoveredDate, monthsNumber]);

    const isSelected = useCallback((_d) => {
        if (isNull(_d)) return false;
        return selectedDates.includes(dayjs(_d).format('YYYY-MM'));
    }, [selectedDates]);

    const isHovered = useCallback((_d) => {
        if (isNull(_d)) return false;
        return hoveredDates.includes(dayjs(_d).format('YYYY-MM')) ? true : false;
    }, [hoveredDates]);


    const selectMonthToPay = (_m) => {
        setSelectedMonthToPay(_m)
        toogleMonthsPicker()
    }

    return (
        <div className="lg:border lg:border-l-primaryBorder/60 bg-white min-h-screen">
            <div className="sticky top-0 lg:py-4 py-2 lg:px-6 md:px-4 p-2 flex items-center justify-between border-b border-primaryBorder/50 bg-white z-[100]">
                <h2 className="textHeader-s select-none">{t("Select Months To Pay")}</h2>
                <button onClick={toogleMonthsPicker} className="bg-transparent hover:bg-primaryBorder/30 border-[1.5px] border-primaryBorder text-primaryTextColor rounded-lg p-2 transition-all duration-100 ease-in active:scale-[.97] flex items-center textBody-m1">
                    <ArrowLeft1 className="w-4 h-4" />
                </button>
            </div>

            <div className="w-full lg:px-6 md:px-4 p-2 flex items-center justify-between">
                <div className="w-12 h-12 flex items-center justify-center">
                    <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`w-full h-full rounded-full bg-transparent flex flex-col items-center justify-center textBody-xs2 text-secondaryTextColor hover:bg-primary2 ${currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                        <ArrowLeft1 className="w-4 h-4" />
                    </button>
                </div>

                <div className="w-12 h-12 flex items-center justify-center">
                    <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === 6}
                        className={`w-full h-full rounded-full bg-transparent flex flex-col items-center justify-center textBody-xs2 text-secondaryTextColor hover:bg-primary2 ${currentPage === 6 ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                        <ArrowRight1 className="w-4 h-4" />
                    </button>
                </div>
            </div>

            {dates[currentPage - 1] && (
                <RenderMonthsList
                    list={dates[currentPage - 1]}
                    isSelected={isSelected}
                    isHovered={isHovered}
                    setHoveredDate={setHoveredDate}
                    selectMonthToPay={selectMonthToPay}
                />
            )}
        </div>
    );
}

const RenderMonthsList = ({ list, isSelected, isHovered, setHoveredDate, selectMonthToPay }) => (
    <div className="w-full py-4 lg:px-6 md:px-4 p-2 flex gap-1">
        <div className="w-full bg-white rounded-lg grid lg:grid-cols-6 grid-cols-6 gap-1 select-none">
            {list.map((date, index) => {
                const ifIsSelected = isSelected(date.formattedDate);
                const ifIsHovered = isHovered(date.formattedDate);

                const itemClassNames = ifIsSelected
                    ? ifIsHovered ? 'bg-primary/20' : 'bg-primary'
                    : ifIsHovered ? 'bg-primary/20' : 'bg-primary2';

                return (
                    <div
                        key={index}
                        className="w-full h-full flex items-center justify-center">
                        <div
                            onClick={() => selectMonthToPay(date.formattedDate)}
                            onMouseEnter={() => setHoveredDate(date.formattedDate)}
                            onMouseLeave={() => setHoveredDate(null)}
                            className={`w-16 h-16 rounded-full cursor-pointer flex flex-col items-center justify-center ${itemClassNames}`}>
                            <span className={`textBody-xs1 ${ifIsSelected ? 'text-white' : 'text-secondaryTextColor'}`}>
                                {date.month}
                            </span>
                            <span className={`textBody-xs3 ${ifIsSelected ? 'text-white/80' : 'text-secondaryTextColor/80'}`}>
                                {date.year}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    </div>
);
