import { useState } from "react";
import ModalLayout from "../../../../layouts/ModalLayout";

export default function OptionsDropDown() {

    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen);


    const DropDown = () => {
        return (
            <ModalLayout
                className={"absolute right-0 z-[99]"}
                close={toggle}
            >
                <div className="shadow-lg bg-white animation-01 animation-02 w-full h-full rounded-lg overflow-hidden py-2 flex flex-col">
                    <button className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">Export as Excel</button>
                    <button className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">Export as CSV</button>
                    <button className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">Export as PDF</button>
                </div>
            </ModalLayout>
        )
    }


    return (
        <div className="relative">
            {/* <button onClick={toggle} className="bg-transparent hover:bg-primaryBorder/30 border-[1.5px] border-primaryBorder text-primaryTextColor rounded-lg py-3 px-6 transition-all duration-100 ease-in active:scale-[.97] flex items-center gap-2 textBody-m1">
                Options
                <ArrowBottom1 className="w-4 h-4" />
            </button> */}

            {isOpen && <DropDown />}
        </div>
    )
}