import { useTranslation } from "react-i18next";
import FilterPopup from "./FilterPopup";
import { useCurrency } from "../../../../hooks/useCurrency";


export default function Filters({ totalPrice, selectedDate, setSelectedDate }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    return (
        <div className="my-4 w-full bg-primary2 rounded-lg lg:p-4 p-2">
            <div className="w-full flex justify-between items-center gap-2">
                <div className={`w-full flex items-center gap-2`}>
                    <FilterPopup
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                    />
                </div>

                <p className="text-sm whitespace-nowrap textBody-xs3">{t("Total")} (<span className="font-semibold">{`${totalPrice} ${Currency}`}</span>)</p>
            </div>
        </div>
    )
}