import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_LOCAL === "production" && process.env.REACT_APP_API_URL !== "http://localhost:8000/") {
    Sentry.init({
        dsn: "https://a620e464f217547ed4f864da917ca53b@o4507532423397376.ingest.de.sentry.io/4507532427984976",

        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration(),
        ],

        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        tracePropagationTargets: ["localhost", /^https:\/\/api\.aratly\.com/],

        profilesSampleRate: 1.0, // Set profilesSampleRate to profile every transaction
        replaysSessionSampleRate: 0.1, // Sample rate at 10%
        replaysOnErrorSampleRate: 1.0, // Sample rate at 100% for sessions with errors
    });
}