import { useTranslation } from "react-i18next";
import { renderImage } from "../../../../Helpers/utils";
import Avatar from "../../../Shared/Avatar";
import { Spinner } from "../../../../icons";
import { useState } from "react";
import { UnBlockCLientById } from "../../../../lib/api";
import { useCurrency } from "../../../../hooks/useCurrency";


export default function ClientCard1({ data = {}, refreshClients, showNotification }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    const { id, membership_number, firstname, lastname, email, phone, avatar, total_paid_price, sport } = data;

    const [inProgress, setInProgress] = useState(false)


    const handleBlockCLient = async () => {

        setInProgress(true)

        const { is_ok, message, type } = await UnBlockCLientById({}, { id });

        setInProgress(false)

        if (type === "validation") {
            // 
        } else {
            showNotification(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refreshClients()
        }
    }

    return (
        <>
            <tr className="[&>*]:px-2 [&>*]:py-5 border-t border-primary/10 select-none">
                <td className="max-lg:hidden">
                    <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${membership_number}`}</span>
                </td>
                <td>
                    <div className="flex lg:items-center gap-2">
                        <Avatar
                            src={renderImage(avatar)}
                            alt={`${firstname} ${lastname}`}
                        />
                        <div className="flex flex-col">
                            <h3 className="lg:textBody-m3 textBody-s2">{`${firstname} ${lastname}`}</h3>
                            <span className="text-primary hover:text-primary/90 textBody-xs3 lg:hidden">{email ?? 'null'}</span>
                            <span className="text-primary hover:text-primary/90 textBody-xs3 lg:hidden">{phone ?? 'null'}</span>
                        </div>
                    </div>
                </td>
                <td className="max-lg:hidden">
                    <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                        <span className="text-primary hover:text-primary/90 line-clamp-1">{email ?? 'null'}</span>
                        <span className="text-primary hover:text-primary/90 line-clamp-1">{phone ?? 'null'}</span>
                    </div>
                </td>
                <td className="max-lg:hidden">
                    <span className="lg:textBody-s3 textBody-xs3">{sport?.name ?? ''}</span>
                </td>
                <td className="max-lg:hidden">
                    <span className="text-[#4CAF50] lg:textBody-s2 textBody-xs2 whitespace-nowrap">{`${total_paid_price ?? 0} ${Currency}`}</span>
                </td>
                <td className="ltr:text-right rtl:text-left">
                    <button disabled={inProgress} onClick={handleBlockCLient} className="bg-primary hover:bg-primary border-0 text-white rounded-lg lg:py-3 py-2 lg:px-3 px-2 textBody-s2">
                        {inProgress ? <Spinner className="w-5 h-5 animate-spin" /> : t("Unblock")}
                    </button>
                </td>
            </tr>
        </>
    )
}