import { useTranslation } from "react-i18next";
import { renderImage } from "../../../../Helpers/utils";
import Avatar from "../../../Shared/Avatar";
import MobileDropDownMenu from "./MobileDropDownMenu";
import { useCurrency } from "../../../../hooks/useCurrency";

export default function SideBar({ clientData, contentSection, setContentSection, closeModal }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    const { firstname, lastname, email, phone, avatar, total_paid_price } = clientData;

    const linksList = [
        {
            label: t('Overview'),
            slug: 'overview',
        }, {
            label: t('Payments History'),
            slug: 'payments',
        }, {
            label: t('Client Details'),
            slug: 'details',
        }, {
            label: t('Notes'),
            slug: 'notes',
        },
    ];

    return (
        <>
            <div className="lg:block hidden lg:h-full lg:max-h-full lg:overflow-y-auto h-fit">
                <div className="w-full flex flex-col items-center justify-center text-center gap-2 p-2 lg:p-4">
                    <Avatar
                        src={renderImage(avatar)}
                        size={'w-24 h-24'}
                        alt={`${firstname} ${lastname}`}
                    />
                    <div className="flex flex-col">
                        <h3 className="textBody-m3 line-clamp-1">{`${firstname} ${lastname}`}</h3>
                        <span className="text-secondaryTextColor textBody-s3 line-clamp-1 mt-1 mb-1">{email}</span>
                        <span className="text-secondaryTextColor textBody-s3 line-clamp-1">{phone}</span>
                        <span className="text-[#4CAF50] textBody-m1 line-clamp-1 mt-1">{`${total_paid_price ?? 0} ${Currency}`}</span>
                    </div>
                </div>

                <div className="w-full h-0.5 bg-primary2"></div>

                <div className="w-full flex flex-col p-2 lg:p-4">
                    {linksList?.map((link, index) => {

                        const chackIsActive = contentSection === link.slug;

                        return (
                            <button
                                key={index}
                                onClick={() => setContentSection(link.slug)}
                                className={`rounded-lg px-2.5 py-2.5 flex items-center textBody-s3 line-clamp-1 text-primaryTextColor ${chackIsActive ? 'bg-primary3' : 'hover:bg-primary2'}`}>
                                {link.label}
                            </button>
                        )
                    })}
                </div>
            </div>

            {/* SideBar For Mobile */}
            <MobileDropDownMenu
                clientData={clientData}
                linksList={linksList}
                contentSection={contentSection}
                setContentSection={setContentSection}
                closeModal={closeModal}
            />
        </>
    )
}