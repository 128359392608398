import { Edit1 } from "../../../icons";
import { useEffect, useState } from "react";
import Input from "../../Shared/Input";
import FormModal from "../../Shared/FormModal";
import PhoneInput from "../../Shared/PhoneInput";
import { UpdateUserContacts } from "../../../lib/api";
import { useTranslation } from "react-i18next";
import { isNull } from "../../../Helpers/utils";


export default function ContactDetails({ authUser, setNotif }) {

    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    const toogleModal = () => setIsOpen(!isOpen)

    const { data, isLoading, mutate } = authUser ?? {};

    return (
        <>
            <div className="rounded-lg bg-white border border-primaryBorder/60">
                <div className="lg:p-6 p-4 flex items-center justify-between border-b border-primaryBorder/50">
                    <h3 className="textBody-m1">{t("Contact Details")}</h3>
                    <button
                        disabled={isLoading}
                        onClick={toogleModal}
                        className="bg-primary/5 border border-primary/10 rounded-full p-2 hover:bg-primary/10 hover:border-primary/15"
                    >
                        <Edit1 className="w-4 h-4" />
                    </button>
                </div>
                <div className="lg:p-6 p-4 flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                        <h4 className="textBody-s1">{t("Phone number")}</h4>
                        {!isLoading
                            ? <span className="text-secondaryTextColor textBody-s2">{data?.phone}</span>
                            : <div className="w-44 h-6 rounded-xl bg-secondaryTextColor/10 animate-pulse"></div>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <h4 className="textBody-s1">{t("Email address")}</h4>
                        {!isLoading
                            ? <span className="text-secondaryTextColor textBody-s2">{data?.email}</span>
                            : <div className="w-44 h-6 rounded-xl bg-secondaryTextColor/10 animate-pulse"></div>}
                    </div>
                </div>
            </div>

            {isOpen && (
                <UpdateData
                    close={toogleModal}
                    setNotif={setNotif}
                    userInfo={{
                        phone: data.phone,
                        email: data.email,
                    }}
                    refresh={mutate}
                />
            )}
        </>
    )
}


// Edit modal
const UpdateData = ({ close, setNotif, userInfo, refresh }) => {

    const { t } = useTranslation()

    const [phoneCode, setPhoneCode] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [errors, setErrors] = useState([])
    const [inProgress, setInProgress] = useState(false)


    useEffect(() => {
        setPhone(userInfo.phone)
        setEmail(userInfo.email)

        // set phone number
        const phoneNumber_ = userInfo.phone ?? '';
        const phone_ = phoneNumber_?.split(" ");
        setPhoneCode(phone_?.[0] ?? "")
        setPhone(phone_?.[1] ?? "")

    }, [userInfo])


    // send request
    const saveChanges = async () => {

        const data = {
            phone: isNull(phone) ? "" : `${phoneCode} ${phone}`,
            email,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await UpdateUserContacts(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            setNotif(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 1 : 0);
        }

        if (is_ok) {
            refresh();
            close();
        }
    }

    return (
        <FormModal
            close={close}
            title={t('Edit Contact Details')}
            save={saveChanges}
            isDisabled={inProgress}
            isLoading={inProgress}
        >
            <PhoneInput
                title={t("Phone number")}
                placeholder={""}
                phoneNumber={phone}
                changePhoneNumber={(p) => setPhone(p)}
                phoneCode={phoneCode}
                changePhoneCode={(c) => setPhoneCode(c)}
                error={errors.phone ?? ''}
            />
            <Input
                type={"text"}
                title={t("Email address")}
                placeholder={""}
                value={email}
                change={(e) => setEmail(e.target.value)}
                error={errors.email ?? ''}
            />
        </FormModal>
    )
}