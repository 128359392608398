import { useTranslation } from "react-i18next"
import { Spinner } from "../../../../icons";
import { useState } from "react";
import { isNull } from "../../../../Helpers/utils";
import { RemoveMemberById } from "../../../../lib/api";
import { useAlerts } from "../../../../context/AlertsContext";
import Confirmation from "../../../Shared/Confirmation"


export default function BlockMemberButton({ id, returnBack }) {

    const { t } = useTranslation()

    const { addAlert } = useAlerts();

    const [inProgress, setInProgress] = useState(false)

    // handle remove member
    const removeTeamMember = async () => {
        if (isNull(id)) return;

        setInProgress(true)

        const { is_ok, message, type } = await RemoveMemberById({ id });

        if (type === "validation") {
            addAlert(t('Something was wrong!'), 'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            returnBack()
        }

        setInProgress(false)
    }

    const [showConfirmationModalToBlock, setShowConfirmationModalToBlock] = useState(false)

    return (
        <>
            <button
                disabled={inProgress}
                onClick={() => setShowConfirmationModalToBlock(true)}
                className="text-center justify-center bg-dangerColor hover:bg-dangerColor/80 border border-dangerColor text-white outline-none rounded-lg lg:py-4 py-4 lg:px-6 px-2 textBody-s2 transition-colors duration-200 ease-in flex items-center gap-2">
                <span>{t("Block this member")}</span>
                {inProgress && <Spinner className="w-4 h-4 animate-spin" />}
            </button>

            {/* Confirmation */}
            {showConfirmationModalToBlock &&
                <Confirmation
                    onClose={() => setShowConfirmationModalToBlock(false)}
                    onConfirm={() => {
                        removeTeamMember()
                    }}
                    headingLine={t("Block")}
                    message={t("Are you sure you want to block this member?")}
                    confirmationBtnName={t("Block")}
                />}
        </>
    )
}