
import ModalLayout from '../../layouts/ModalLayout';
import { ArrowRight1, Hide1 } from '../../icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { paramsList } from '../../Helpers/utils';


export default function LanguagesSwitcher({ selectedLang, changeLang, close = () => { }, buttonStyle = "2" }) {

    const { t } = useTranslation();

    //Start------------------------------ Change Locale Modal ------------------------------//

    const [searchParams, setSearchParams] = useSearchParams();
    const queryName = paramsList()['locale'];

    const addActionQuery = () => {
        searchParams.set(queryName, 'languages');
        setSearchParams(searchParams);
    }

    const removeActionQuery = () => {
        searchParams.delete(queryName);
        setSearchParams(searchParams);
    }

    const getActionQuery = () => {
        return searchParams.get(queryName) ?? '';
    };

    const isOpen = getActionQuery() === "languages";

    //End------------------------------ Change Locale Modal ------------------------------//

    const currentLanguage = i18next?.language ?? "en";

    return (
        <>
            {buttonStyle === "1" ? (
                <button
                    onClick={addActionQuery}
                    className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                    <div className="rounded-full lg:w-6 lg:h-6 w-5 h-5 bg-primary/10">
                        <img src={`/flags/${currentLanguage}.png`} alt="flag" className="w-full h-full rounded-full" />
                    </div>
                </button>
            ) : (
                <button
                    onClick={addActionQuery}
                    className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                    {t("Language")}
                </button>
            )}

            {isOpen &&
                <ModalLayout
                    className="fixed lg:max-w-[400px] w-full -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 z-[1011]"
                    close={removeActionQuery}
                >
                    <div className={`bg-white textBody-s3 w-full lg:rounded-lg gap-1 justify-between flex flex-col overflow-hidden max-lg:h-dvh`}>
                        <div className="flex items-center justify-between p-4">
                            <h3 className="textBody-m1">{t("Change language")}</h3>
                            <button
                                onClick={removeActionQuery}
                                className="rounded-lg p-1 hover:bg-primary/5">
                                <Hide1 className="w-5 h-5" />
                            </button>
                        </div>

                        <div className="py-6 px-4 flex flex-col border-y border-primaryBorder lg:max-h-[60vh] max-lg:h-full overflow-y-auto">
                            <div className="">
                                <p>{t("Set your language for the best experience")}</p>
                            </div>
                            <div className="pt-6 flex flex-col gap-2">
                                {[
                                    { title: 'English', slug: 'en', },
                                    { title: 'French', slug: 'fr', },
                                    { title: 'Arabic', slug: 'ar', },
                                ].map((lng, i) => (
                                    <button
                                        key={i}
                                        onClick={() => {
                                            changeLang(lng.slug)
                                            removeActionQuery()
                                            close()
                                        }}
                                        className={`rounded-lg py-3 lg:px-4 px-2 transition-all duration-100 ease-in flex items-center gap-2 textBody-s2 justify-between 
                                            ${selectedLang === lng.slug
                                                ? 'bg-primary/5 border border-primary/10 text-secondaryTextColor'
                                                : 'bg-white border border-primaryBorder/60 hover:border-primaryBorder hover:bg-white/50 text-secondaryTextColor'
                                            }
                                        `}>
                                        <div className="flex items-center gap-2">
                                            <div className="rounded w-[35px] min-h-[24px] bg-primary2 overflow-hidden">
                                                <img
                                                    className="w-full h-auto"
                                                    src={`/flags/${lng.slug}.svg`}
                                                    alt={`${lng.slug}-flag`}
                                                />
                                            </div>
                                            <span>{lng.title}</span>
                                        </div>
                                        <span><ArrowRight1 className="w-4 h-4" /></span>
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* footer */}
                    </div>
                </ModalLayout>
            }
        </>
    )
}