import { useState } from "react";
import Avatar from "../Shared/Avatar";
import ModalLayout from "../../layouts/ModalLayout";
import { useNavigate } from "react-router-dom";
import AvatarLoader from "../Shared/Avatar/AvatarLoader";
import { Spinner } from "../../icons";
import { renderImage } from "../../Helpers/utils";
import LanguagesSwitcher from "./LanguagesSwitcher";
import { useTranslation } from "react-i18next";
import useHtmlAttributes from "../../hooks/useHtmlAttributes";


export default function AvatarDropDown({ authUser, logout, inProgress }) {

    const { t } = useTranslation();

    const { selectedLang, changeLang } = useHtmlAttributes();

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => setIsOpen(!isOpen)

    const GorTo = (path) => {
        navigate(path)
        toggleDropDown()
    }

    return authUser.isLoading ? <AvatarLoader size={'lg:w-12 lg:h-12 w-8 h-8'} /> : (
        <div className="relative">
            <Avatar
                src={renderImage(authUser?.data?.avatar)}
                size={'lg:w-12 lg:h-12 w-8 h-8'}
                alt={`${authUser?.data?.firstname ?? '#'} ${authUser?.data?.lastname ?? '#'}`}
                onClick={toggleDropDown}
                style={{ cursor: 'pointer' }}
            />

            {isOpen && (
                <ModalLayout
                    wallBackground={`bg-black/50`}
                    className={`absolute ltr:right-0 rtl:left-0 z-[1012]`}
                    close={toggleDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[300px] h-full rounded-lg overflow-hidden pb-2 flex flex-col textBody-s3">
                        <h3 className="px-4 line-clamp-1 textBody-s1 border-b border-secondaryTextColor/20 pt-4 pb-4 mb-2">
                            {`${authUser?.data?.firstname ?? '#'} ${authUser?.data?.lastname ?? '#'}`}
                        </h3>
                        <button
                            onClick={() => GorTo('/profile')}
                            className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("Account Details")}
                        </button>
                        <button
                            onClick={() => GorTo('/club')}
                            className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("Club Details")}
                        </button>

                        <div className="w-full h-[1px] bg-primary/5 my-2"></div>

                        {authUser?.data?.role === "owner" && 
                        <button
                            onClick={() => GorTo('/settings/subscription')}
                            className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("Platform Subscription")}
                        </button>}

                        <a href="https://support.aratly.com/" target="_blank" rel="noreferrer" className="flex flex-col">
                            <button
                                className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                                {t("Help Center")}
                            </button>
                        </a>

                        <LanguagesSwitcher
                            selectedLang={selectedLang}
                            changeLang={changeLang}
                            close={toggleDropDown}
                            buttonStyle="2"
                        />

                        <button
                            disabled={inProgress}
                            onClick={() => {
                                logout()
                            }}
                            className={`text-start whitespace-nowrap py-2 px-4 transition-colors duration-300 flex items-center justify-between ${inProgress ? 'bg-primary/5' : 'hover:bg-primary/5'}`}>
                            <span>{t("Logout")}</span>
                            {inProgress ? <Spinner className="w-4 h-4 animate-spin" /> : null}
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}