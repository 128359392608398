import { Link } from "react-router-dom";
import MobileDropDownMenu from "./MobileDropDownMenu";
import OptionsDropDown from "./OptionsDropDown";
import { useTranslation } from "react-i18next";


export default function Heading({ totalRows, canCreate }) {

    const { t } = useTranslation()

    return (
        <div className="w-full">
            <div className="w-full flex lg:justify-between lg:items-center max-lg:flex-col-reverse">
                {/* Text */}
                <div className="flex flex-col gap-1">
                    <h2 className="flex items-center textHeader-l">
                        {t("Team members")}
                        <span className="border border-primaryBorder bg-white text-secondaryTextColor py-1 px-3 flex w-fit rounded-3xl select-none mx-2 textBody-xs3">
                            {totalRows}
                        </span>
                    </h2>
                    <p className="textBody-s3">{t("View, add, remove your team's details.")}</p>
                </div>

                {/* Buttons */}
                {canCreate &&
                    <div className="lg:block hidden">
                        <div className="flex items-center gap-2">
                            <OptionsDropDown />
                            <Link to="/team/new">
                                <button className="bg-primary hover:bg-primary/80 border-[1.5px] border-primary hover:border-primary/80 text-white rounded-lg py-3 px-6 transition-all duration-100 ease-in active:scale-[.97] flex items-center gap-2 textBody-m1">
                                    {t("Add new")}
                                </button>
                            </Link>
                        </div>
                    </div>}

                {canCreate &&
                    <MobileDropDownMenu />}
            </div>
        </div>
    )
}