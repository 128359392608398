import { useTranslation } from "react-i18next";
import { isNull } from "../../../../Helpers/utils";
import { CreateNewSport } from "../../../../lib/api";
import FormModal from "../../../Shared/FormModal";
import { useState } from "react";
import { useAlerts } from "../../../../context/AlertsContext";


export default function CreateSport({ closeModal, refresh = () => { } }) {

    const { t } = useTranslation()
    
    const { addAlert } = useAlerts();

    const [name, setName] = useState("")
    const [status, setStatus] = useState("active")

    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)


    // handle click 'saveNewSport'
    const saveNewSport = async () => {

        const data = {
            name,
            status,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await CreateNewSport(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh()
            closeModal();
        }
    }


    return (
        <FormModal
            close={closeModal}
            title={t("Add new sport")}
            save={saveNewSport}
            isDisabled={inProgress}
            isLoading={inProgress}
        >
            <div className="w-full grid grid-cols-1 gap-6">
                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">{t("Name")}</span>
                    <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.name) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                        <input
                            type="text"
                            className={`w-full p-3 outline-none`}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </div>
                    {!isNull(errors.name) && <p className="textBody-s2 text-red-600">{errors.name}</p>}
                </label>

                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">{t("Status")}</span>
                    <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.status) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                        <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            className={`w-full p-3 outline-none appearance-none`}
                        >
                            <option value="active">{t("active")}</option>
                            <option value="inactive">{t("inactive")}</option>
                        </select>
                    </div>
                    {!isNull(errors.status) && <p className="textBody-s2 text-red-600">{errors.status}</p>}
                </label>
            </div>
        </FormModal>
    )
}