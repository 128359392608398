import { useTranslation } from "react-i18next"
import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import ClientCard1 from "./ClientCard1"


export default function ClientsList1({ clients, refreshClients, showNotification }) {

    const { t } = useTranslation()

    return isNull(clients)
        ? <NotResultsFound title={t("No results")} desc={t("No clients found on this page")} desc2={t("try adding new clients")} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none">
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">{t("Number")}</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1"><span className="max-lg:hidden">{t("Name")}</span><span className="lg:hidden">{t("Client")}</span></th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">{t("Contact")}</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">{t("Sport")}</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">{t("Price")}</th>
                        <th className="ltr:text-right rtl:text-left lg:textBody-s1 textBody-xs1">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.length > 0 &&
                        clients.map((client) => {
                            return (
                                <ClientCard1
                                    key={`client_card_1_${client.id}`}
                                    data={client}
                                    refreshClients={refreshClients}
                                    showNotification={showNotification}
                                />
                            )
                        })}
                </tbody>
            </table>
        )
}