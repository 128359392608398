import ClientDetailsSection from "./ContentSections/ClientDetails"
import Notes from "./ContentSections/Notes"
import Overview from "./ContentSections/Overview"
import PaymentForm from "./ContentSections/PaymentForm"
import PaymentsHistory from "./ContentSections/PaymentsHistory"


export default function Content({ clientData, contentSection, setContentSection, closeModal, refreshClients, showNotification }) {

    const renderSelectedSection = () => {
        switch (contentSection) {
            case "overview":
                return <Overview clientData={clientData} setContentSection={setContentSection} />
            case "payments":
                return <PaymentsHistory clientData={clientData} setContentSection={setContentSection} />
            case "details":
                return <ClientDetailsSection clientData={clientData} closeModal={closeModal} refreshClients={refreshClients} showNotification={showNotification} />
            case "notes":
                return <Notes clientData={clientData} />
            case "pay":
                return <PaymentForm clientData={clientData} setContentSection={setContentSection} />
            default:
                return <div className="min-h-dvh"></div>
        }
    }

    return (
        <div id="client-details-modal" className="h-full min-h-full bg-primary2 max-h-full overflow-y-auto">
            {renderSelectedSection()}
        </div>
    )
}