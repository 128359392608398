import { useRef, useState } from "react";
import { isNull, renderImage } from "../../../Helpers/utils"
import { ArrowBottom1, Check, UnCheck } from "../../../icons";
import ModalLayout from "../../../layouts/ModalLayout";
import GlobalSuspense from "../../../components/SuspensePages/GlobalSuspense";
import { GetAllPublicCountries } from "../../../lib/api";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { isValidPhoneNumber } from 'libphonenumber-js'



export default function PhoneInput({
    title = "Title",
    placeholder = "",
    phoneNumber = "",
    changePhoneNumber = () => { },
    phoneCode = "",
    changePhoneCode = () => { },
    error = null,
    isOptional = false,
    isDisabled = false,
    readOnly = false,
}) {

    const { t } = useTranslation()


    const inputRef = useRef(null);

    const focusInput = () => {
        const textarea = inputRef.current;
        textarea.focus();
    };


    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        if (isDisabled) return;
        setIsOpen(!isOpen)
    }


    // Get all public countries
    const { data, loading } = GetAllPublicCountries();
    const countriesList = data?.data ?? [];


    const [phoneIsCurrect, setPhoneIsCurrect] = useState(" ")


    const handleChangePhoneNumber = (txt) => {
        const isValid = `${phoneCode} ${txt}`;
        setPhoneIsCurrect(isValidPhoneNumber(isValid))
        changePhoneNumber(txt)
    }


    const handleChangePhoneCode = (phoneCode) => {
        changePhoneCode(phoneCode)
        changePhoneNumber("")
        focusInput()
    }


    const borderStyle = !isNull(error) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90 outline-primary/80';


    return (
        <div className="flex flex-col gap-2">
            <span className="textBody-s1 select-none cursor-context-menu">{title} {isOptional && <i className="textBody-xs3">({t("Optional")})</i>}</span>
            <div className={`relative h-[49px] flex gap-1 items-center rounded-lg transition-colors duration-300 bg-white ${borderStyle}`}>
                <SelectPhoneCountry
                    phoneCode={phoneCode}
                    changePhoneCode={handleChangePhoneCode}
                    loading={loading}
                    countriesList={countriesList}
                    setPhoneIsCurrect={setPhoneIsCurrect}
                    isDisabled={isDisabled}
                    isOpen={isOpen}
                    toggle={toggle}
                />
                {!isNull(phoneCode) && <span onClick={toggle} className="pr-1 text-secondaryTextColor cursor-context-menu">{phoneCode}</span>}

                <input
                            ref={inputRef}
                    disabled={isDisabled}
                    type="number"
                    className={`w-full h-full outline-none rounded-lg`}
                    placeholder={placeholder}
                    onChange={(e) => handleChangePhoneNumber(e.target.value)}
                    value={phoneNumber}
                />

                {renderNumberStatus({ phoneNumber, phoneIsCurrect })}
            </div>
            {!isNull(error) && <p className="textBody-s2 text-red-600">{error}</p>}
        </div>
    )
}



const SelectPhoneCountry = ({ phoneCode, changePhoneCode, loading, countriesList, setPhoneIsCurrect, isDisabled, isOpen, toggle }) => {


    // Get data of selected country by "phoneCode"
    const getSelectedCountryData = !isNull(phoneCode) ? countriesList?.find(c => c.phone_code === phoneCode) : {};


    // render city name
    const renderCountryName = (country = {}) => {
        const currentLang = i18next?.language || "en";

        if (currentLang === "ar") {
            return country?.ar_name;
        } else if (currentLang === "en") {
            return country?.en_name;
        } else if (currentLang === "fr") {
            return country?.fr_name;
        } else {
            return "#";
        }
    }

    return (
        <div className="">
            <div className="ltr:pl-2 rtl:pr-2">
                {!isNull(phoneCode) &&
                    <button
                        disabled={isDisabled}
                        onClick={toggle}
                        className="outline-none flex items-center gap-1">
                        <div className="rounded w-[40px] min-h-[25px] bg-primary2 overflow-hidden">
                            {!isNull(getSelectedCountryData) &&
                                <img
                                    className="w-full h-auto"
                                    src={renderImage(getSelectedCountryData?.country_flag)}
                                    alt="flag"
                                />}
                        </div>
                        <ArrowBottom1 className="w-5 h-5 text-secondaryTextColor" />
                    </button>}
            </div>

            {isNull(phoneCode) && <div onClick={toggle} className="absolute top-0 left-0 w-full h-full cursor-text"></div>}

            {isOpen && (
                <ModalLayout
                    wallBackground="bg-black/10"
                    className={"absolute ltr:left-0 rtl:right-0 z-[1020]"}
                    close={toggle}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[260px] h-full rounded-lg overflow-hidden flex flex-col">
                        <div className="py-2 flex flex-col max-h-[250px] overflow-y-auto">
                            {loading ? <GlobalSuspense heightClass="min-h-52" /> :
                                countriesList?.map((c) => {
                                    const isSelected = c?.id === getSelectedCountryData?.id;
                                    return (
                                        <div key={c?.id} className="w-full">
                                            <button
                                                disabled={isSelected}
                                                onClick={() => {
                                                    changePhoneCode(c?.phone_code)
                                                    setPhoneIsCurrect(" ")
                                                    toggle()
                                                }}
                                                className={`px-3 py-2 w-full outline-none flex items-center gap-2 transition-colors duration-100 ease-in line-clamp-1 ${isSelected ? 'bg-primary/10' : 'hover:bg-primary/10'}`}>
                                                <div className="rounded w-[40px] min-h-[25px] bg-primary2 overflow-hidden">
                                                    <img
                                                        className="w-full h-auto"
                                                        src={renderImage(c?.country_flag)}
                                                        alt="flag"
                                                    />
                                                </div>
                                                <span className="first-letter:capitalize">{`${renderCountryName(c)} (${c?.phone_code})`}</span>
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}


const renderNumberStatus = ({ phoneNumber, phoneIsCurrect }) => {
    return isNull(phoneNumber) || phoneIsCurrect === " " ? null : (
        <>
            {phoneIsCurrect ? (
                <div className="absolute ltr:right-2 rtl:left-2 top-2/4 -translate-y-2/4 border border-green-600 p-0.5 flex shrink-0 justify-between items-center bg-green-600 text-white rounded-3xl cursor-context-menu">
                    <Check className="w-4 h-4" />
                </div>
            ) : (
                <div className="absolute ltr:right-2 rtl:left-2 top-2/4 -translate-y-2/4 border border-red-600 p-0.5 flex shrink-0 justify-between items-center bg-red-600 text-white rounded-3xl cursor-context-menu">
                    <UnCheck className="w-4 h-4" />
                </div>
            )}
        </>
    )
}