import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import MemberCard from "./MemberCard"
import { useTranslation } from "react-i18next";

export default function TeamMembers({ team, canUpdate }) {

    const { t } = useTranslation()

    return isNull(team)
        ? <NotResultsFound title={t("No results")} desc={t("No members found on this page")} desc2={t("try adding new members")} />
        : (
            <div className="mt-8 grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-4 xl:gap-6">
                {team.map((client) => {
                    return (
                        <MemberCard
                            key={`client_card_2_${client.id}`}
                            data={client}
                            canUpdate={canUpdate}
                        />
                    )
                })}
            </div>
        )
}