import { useState } from "react";
import ModalLayout from "../../layouts/ModalLayout";
import { useNavigate } from "react-router-dom";
import { AddNew1 } from "../../icons";
import { useTranslation } from "react-i18next";
import { paramsList } from "../../Helpers/utils";


export default function AddNewDropDown() {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => setIsOpen(!isOpen)

    const GorTo = (path) => {
        navigate(path)
        toggleDropDown()
    }

    return (
        <div className="relative max-lg:hidden">
            <button
                onClick={toggleDropDown}
                className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                <AddNew1 className="lg:w-6 lg:h-6 w-5 h-5" />
            </button>

            {isOpen && (
                <ModalLayout
                    className={"absolute ltr:right-0 rtl:left-0 z-[1012]"}
                    close={toggleDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 min-w-[150px] h-full rounded-lg overflow-hidden py-2 flex flex-col textBody-s3">
                        <button
                            onClick={() => GorTo('/clients/new')}
                            className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("Add new client")}
                        </button>
                        <button
                            onClick={() => GorTo('/team/new')}
                            className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("Add new member")}
                        </button>
                        <button
                            onClick={() => GorTo(`/sports?${paramsList()['action']}=add`)}
                            className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("Add new sport")}
                        </button>
                        <button
                            onClick={() => GorTo(`/subscriptions?${paramsList()['action']}=add`)}
                            className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("Add new subscription")}
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}