import { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import SideBarMobile from "../components/SideBar/SideBarMobile";
import PrimarySuspense from "../components/SuspensePages/PrimarySuspense";



export default function PrimaryLayout() {

    // state to store sideBar status (open or close)
    const [isOpen, setIsOpen] = useState(true)
    const toogleSideBar = () => setIsOpen(!isOpen);


    const { isAuth, authUser } = useAuth()

    const navigate = useNavigate()

    /**
     * redirect unauth users to the login page
     */
    useEffect(() => {
        const checkAuthStatus = () => {
            if (!isAuth) {
                navigate('/auth/login')
            }
        };

        checkAuthStatus();
    }, [isAuth, navigate])


    return authUser?.isLoading ? <PrimarySuspense /> : (
        <main className="flex flex-col text-primaryTextColor font-body">
            <NavBar
                toogleSideBar={toogleSideBar}
            />

            <section className="w-full max-w-full flex max-lg:flex-col-reverse">
                <SideBar
                    isOpen={isOpen}
                />
                <div
                    className="bg-bodyBackground h-full min-h-screen flex flex-col relative grow justify-between">
                    <Outlet />
                </div>
            </section>

            <SideBarMobile />
        </main>
    )
}