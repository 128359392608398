import { useNavigate, useParams } from "react-router-dom";
import Avatar from "../../components/Shared/Avatar";
import { useEffect, useState } from "react";
import { isNull, n, renderImage } from "../../Helpers/utils";
import { AddImage } from "../../icons";
import Button from "../../components/Shared/Button";
import Input from "../../components/Shared/Input";
import { useAlerts } from "../../context/AlertsContext";
import EditClientSuspense from "../../components/SuspensePages/EditClientSuspense";
import PhoneInput from "../../components/Shared/PhoneInput";
import { EditClientById, GetAllPublicSports, GetAllPublicSubscriptions, GetClientById } from "../../lib/api";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../hooks/useCurrency";



export default function EditClient() {
    const navigate = useNavigate();
    const returnBack = () => navigate(-1);

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    const { id } = useParams();

    const { addAlert } = useAlerts();


    // Get client data
    const { data: clientData, loading: clientLoading } = GetClientById({ id });


    // Get all public sports list
    const { data: sportsData, loading: sportsLoading } = GetAllPublicSports();


    // Get all public subscriptions list
    const { data: subscriptionsData, loading: subscriptionsLoading } = GetAllPublicSubscriptions();


    const [membership_number, setMembership_number] = useState('')
    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phoneCode, setPhoneCode] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState("")
    const [sport, setSport] = useState("")
    const [subscription, setSubscription] = useState("")
    const [payment_day, setPayment_day] = useState("")
    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)


    useEffect(() => {
        setAvatar('')
        setAvatarPreview(renderImage(clientData?.data?.avatar ?? ''))
        setFirstname(clientData?.data?.firstname ?? '')
        setLastname(clientData?.data?.lastname ?? '')
        setEmail(clientData?.data?.email ?? '')
        setGender(clientData?.data?.gender ?? '')
        setSport(clientData?.data?.sport ?? '')
        setSubscription(clientData?.data?.subscription ?? '')
        setPayment_day(clientData?.data?.payment_day ?? '')
        setMembership_number(clientData?.data?.membership_number ?? '')

        // set phone number
        const phoneNumber_ = clientData?.data?.phone ?? '';
        const phone_ = phoneNumber_?.split(" ");
        setPhoneCode(phone_?.[0] ?? "")
        setPhone(phone_?.[1] ?? "")

    }, [clientData])


    const handleChangeAvatar = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result); // Base64 string
                setAvatarPreview(reader.result); // Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            setAvatar('')
            setAvatarPreview(renderImage(clientData?.data?.avatar ?? ''));
        }
    }


    // send request
    const EditClient = async () => {

        const data = {
            client_id: id,
            avatar,
            firstname,
            lastname,
            email,
            phone: isNull(phone) ? "" : `${phoneCode} ${phone}`,
            sport,
            subscription,
            payment_day,
            gender,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await EditClientById(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            returnBack()
        }
    }

    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="w-full">
                <div className="w-full flex justify-between items-end">
                    {/* Text */}
                    <div className="flex flex-col gap-1">
                        <h2 className="flex items-center textHeader-l">
                            {`${t("Edit Client")} ${membership_number ? `#${membership_number}` : ``}`}
                        </h2>
                    </div>

                    {/* Buttons */}
                    <div className="max-lg:hidden flex items-center gap-2">
                        <Button
                            name={t("Cancel")}
                            onClick={returnBack}
                            disabled={inProgress || clientLoading}
                            styleType="2"
                        />
                        <Button
                            name={t("Save")}
                            onClick={EditClient}
                            disabled={inProgress || clientLoading}
                            isLoading={inProgress}
                            styleType="1"
                        />
                    </div>
                </div>
            </div>

            {clientLoading
                ? <EditClientSuspense />
                : <div className="mt-6 mb-10 w-full grid lg:grid-cols-[1fr_300px] grid-cols-1 gap-4">
                    <div className="flex flex-col gap-4">
                        <div className="bg-white border border-primaryBorder rounded-lg p-2 md:p-4 lg:p-6">
                            <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                                <Input
                                    type={"text"}
                                    title={t("First name")}
                                    placeholder={""}
                                    value={firstname}
                                    change={(e) => setFirstname(e.target.value)}
                                    error={errors.firstname ?? ''}
                                />
                                <Input
                                    type={"text"}
                                    title={t("Last name")}
                                    placeholder={""}
                                    value={lastname}
                                    change={(e) => setLastname(e.target.value)}
                                    error={errors.lastname ?? ''}
                                />
                            </div>

                            <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                                <Input
                                    type={"email"}
                                    title={t("Email address")}
                                    placeholder={""}
                                    value={email}
                                    change={(e) => setEmail(e.target.value)}
                                    error={errors.email ?? ''}
                                    isOptional={true}
                                />
                                <PhoneInput
                                    title={t("Phone number")}
                                    placeholder={""}
                                    phoneNumber={phone}
                                    changePhoneNumber={(p) => setPhone(p)}
                                    phoneCode={phoneCode}
                                    changePhoneCode={(c) => setPhoneCode(c)}
                                    error={errors.phone ?? ''}
                                    isOptional={true}
                                />
                            </div>

                            {/* <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6"> */}
                            <label className="flex flex-col gap-2">
                                <span className="textBody-s1">{t("Gender")} <i className="textBody-xs3">({t("Optional")})</i></span>
                                <select
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                                    <option value="" disabled></option>
                                    <option value="male">{t("Male")}</option>
                                    <option value="female">{t("Female")}</option>
                                </select>
                                {!isNull(errors?.gender) && <p className="textBody-s2 text-red-600">{errors?.gender}</p>}
                            </label>
                            {/* </div> */}
                        </div>

                        <div className="bg-white border border-primaryBorder rounded-lg p-2 md:p-4 lg:p-6">
                            <div className="my-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                                <label className="flex flex-col gap-2">
                                    <span className="textBody-s1">{t("Sport")}</span>
                                    <select
                                        value={sport}
                                        onChange={(e) => setSport(n(e.target.value))}
                                        className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                                        {sportsLoading
                                            ? <option value="" disabled>{t("Loading...")}</option>
                                            : <>
                                                <option value="" disabled></option>
                                                {sportsData?.data?.data?.map((sport) => (
                                                    <option key={sport.id} value={sport.id}>{sport.name}</option>
                                                ))}
                                            </>
                                        }
                                    </select>
                                    {!isNull(errors.sport) && <p className="textBody-s2 text-red-600">{errors.sport}</p>}
                                </label>

                                <label className="flex flex-col gap-2">
                                    <span className="textBody-s1">{t("Subscription")}</span>
                                    <select
                                        value={subscription}
                                        onChange={(e) => setSubscription(n(e.target.value))}
                                        className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                                        {subscriptionsLoading
                                            ? <option value="" disabled>{t("Loading...")}</option>
                                            : <>
                                                <option value="" disabled></option>
                                                {subscriptionsData?.data?.data?.map((subscription) => {
                                                    const { id, months, price } = subscription;
                                                    return (
                                                        <option key={id} value={id}>
                                                            {`${months} ${months > 1 ? t('Months') : t('Month')} (${price}${Currency})`}
                                                        </option>
                                                    )
                                                }
                                                )}
                                            </>
                                        }
                                    </select>
                                    {!isNull(errors.subscription) && <p className="textBody-s2 text-red-600">{errors.subscription}</p>}
                                </label>
                            </div>

                            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                                <label className="flex flex-col gap-2">
                                    <span className="textBody-s1">{t("Payment Day")}</span>
                                    <select
                                        value={payment_day}
                                        onChange={(e) => setPayment_day(e.target.value)}
                                        className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                                        <option value={'01'}>1</option>
                                        <option value={'02'}>2</option>
                                        <option value={'03'}>3</option>
                                        <option value={'04'}>4</option>
                                        <option value={'05'}>5</option>
                                        <option value={'06'}>6</option>
                                        <option value={'07'}>7</option>
                                        <option value={'08'}>8</option>
                                        <option value={'09'}>9</option>
                                        <option value={'10'}>10</option>
                                        <option value={'11'}>11</option>
                                        <option value={'12'}>12</option>
                                        <option value={'13'}>13</option>
                                        <option value={'14'}>14</option>
                                        <option value={'15'}>15</option>
                                        <option value={'16'}>16</option>
                                        <option value={'17'}>17</option>
                                        <option value={'18'}>18</option>
                                        <option value={'19'}>19</option>
                                        <option value={'20'}>20</option>
                                        <option value={'20'}>20</option>
                                        <option value={'21'}>21</option>
                                        <option value={'22'}>22</option>
                                        <option value={'23'}>23</option>
                                        <option value={'24'}>24</option>
                                        <option value={'25'}>25</option>
                                        <option value={'26'}>26</option>
                                        <option value={'27'}>27</option>
                                        <option value={'28'} disabled>28</option>
                                        <option value={'29'} disabled>29</option>
                                        <option value={'30'} disabled>30</option>
                                        <option value={'31'} disabled>31</option>
                                    </select>
                                    {!isNull(errors.payment_day) && <p className="textBody-s2 text-red-600">{errors.payment_day}</p>}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="bg-white border border-primaryBorder rounded-lg p-2 md:p-4 lg:p-6">
                            <div className="w-full border-b border-primaryBorder/50 mb-4 pb-4">
                                <div className="relative w-fit overflow-hidden mx-auto">
                                    <Avatar
                                        src={avatarPreview}
                                        size={'w-32 h-32'}
                                        alt={" "}
                                    />
                                    <label className={`absolute cursor-pointer flex items-center justify-center transition-colors duration-200 p-2 -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 bg-black/0 hover:bg-black/10 text-primary/60 rounded-full w-full h-full`}>
                                        <input type="file" onChange={handleChangeAvatar} accept="image/png, image/jpg, image/jpeg" hidden />
                                        {isNull(avatarPreview) && <AddImage className="w-5 h-5" />}
                                    </label>
                                </div>
                            </div>

                            {/* Buttons */}
                            <div className="lg:hidden grid grid-cols-1 gap-2 mt-8">
                                <Button
                                    name={t("Save")}
                                    onClick={EditClient}
                                    disabled={inProgress || clientLoading}
                                    isLoading={inProgress}
                                    styleType="1"
                                />
                                <Button
                                    name={t("Cancel")}
                                    onClick={returnBack}
                                    disabled={inProgress || clientLoading}
                                    styleType="2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}