import { useEffect, useState } from "react";
import FilterModal from "../../../Shared/FilterModal";
import { GetAllPublicSports, GetAllPublicSubscriptions } from "../../../../lib/api";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../../../hooks/useCurrency";



export default function FilterPopup({ params, setParams, toggleModal }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()


    // Get all public sports list
    const { data: sportsData, loading: sportsLoading } = GetAllPublicSports();


    // Get all public subscriptions list
    const { data: subscriptionsData, loading: subscriptionsLoading } = GetAllPublicSubscriptions();


    const [gender, setGender] = useState("")
    const [sport, setSport] = useState("")
    const [subscription, setSubscription] = useState("")
    const [payment_day, setPayment_day] = useState("")
    const [sort_by, setSort_by] = useState("")


    useEffect(() => {
        setGender(params?.gender ?? "")
        setSport(params?.sport ?? "")
        setSubscription(params?.subscription ?? "")
        setPayment_day(params?.payment_day ?? "")
        setSort_by(params?.sort_by ?? "")
    }, [params])


    // clear all filter by one click
    const clearAllFilters = () => {
        setParams({})
        toggleModal()
    }


    // rearrange selected data and research
    const handleFilterData = () => {
        const data = {
            gender,
            sport,
            subscription,
            payment_day,
            sort_by
        };

        // return non-null keys only
        const body = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '')
        );

        setParams(body)
    }


    return (
        <FilterModal
            onClose={toggleModal}
            onSave={handleFilterData}
            clearAllFilters={clearAllFilters}
        >
            <div className="my-4 flex flex-col gap-4">
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Sport")}</span>
                        <select
                            value={sport}
                            onChange={(e) => setSport(e.target.value)}
                            className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                            <option value="">{t("All")}</option>
                            {sportsLoading
                                ? <option value="" disabled>{t("Loading...")}</option>
                                : <>
                                    {sportsData?.data?.data?.map((sport) => (
                                        <option key={sport.id} value={sport.id}>{sport.name}</option>
                                    ))}
                                </>
                            }
                        </select>
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Subscription")}</span>
                        <select
                            value={subscription}
                            onChange={(e) => setSubscription(e.target.value)}
                            className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                            <option value="">{t("All")}</option>
                            {subscriptionsLoading
                                ? <option value="" disabled>{t("Loading...")}</option>
                                : <>
                                    {subscriptionsData?.data?.data?.map((subscription) => {
                                        const { id, months, price } = subscription;
                                        return (
                                            <option key={id} value={id}>
                                                {`${months} ${months > 1 ? t('Months') : t('Month')} (${price}${Currency})`}
                                            </option>
                                        )
                                    })}
                                </>
                            }
                        </select>
                    </label>
                </div>

                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Gender")}</span>
                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                            <option value="">{t("All")}</option>
                            <option value="male">{t("Male")}</option>
                            <option value="female">{t("Female")}</option>
                        </select>
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Payment Day")}</span>
                        <select
                            value={payment_day}
                            onChange={(e) => setPayment_day(e.target.value)}
                            className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                            <option value="">{t("All")}</option>
                            <option value={'01'}>1</option>
                            <option value={'02'}>2</option>
                            <option value={'03'}>3</option>
                            <option value={'04'}>4</option>
                            <option value={'05'}>5</option>
                            <option value={'06'}>6</option>
                            <option value={'07'}>7</option>
                            <option value={'08'}>8</option>
                            <option value={'09'}>9</option>
                            <option value={'10'}>10</option>
                            <option value={'11'}>11</option>
                            <option value={'12'}>12</option>
                            <option value={'13'}>13</option>
                            <option value={'14'}>14</option>
                            <option value={'15'}>15</option>
                            <option value={'16'}>16</option>
                            <option value={'17'}>17</option>
                            <option value={'18'}>18</option>
                            <option value={'19'}>19</option>
                            <option value={'20'}>20</option>
                            <option value={'20'}>20</option>
                            <option value={'21'}>21</option>
                            <option value={'22'}>22</option>
                            <option value={'23'}>23</option>
                            <option value={'24'}>24</option>
                            <option value={'25'}>25</option>
                            <option value={'26'}>26</option>
                            <option value={'27'}>27</option>
                            <option value={'28'} disabled>28</option>
                            <option value={'29'} disabled>29</option>
                            <option value={'30'} disabled>30</option>
                            <option value={'31'} disabled>31</option>
                        </select>
                    </label>
                </div>

                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">{t("Sort by")}</span>
                    <select
                        value={sort_by}
                        onChange={(e) => setSort_by(e.target.value)}
                        className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                        <option value="desc">{t("Newest First")}</option>
                        <option value="asc">{t("Oldest First")}</option>
                    </select>
                </label>
            </div>
        </FilterModal>
    )
}