import { useEffect, useState } from "react";
import { isNull, n } from "../../Helpers/utils";
import Button from "../../components/Shared/Button";
import { useAlerts } from "../../context/AlertsContext";
import ClubSuspense from "../../components/SuspensePages/ClubSuspense";
import PhoneInput from "../../components/Shared/PhoneInput";
import { GetAllPublicCountries, GetAllPublicCurrencies, GetClubByAuthUser, UpdateClubInformations } from "../../lib/api";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useCookies from "../../hooks/useCookies";


export default function Club() {

    const { t } = useTranslation();

    const { setCookie } = useCookies();

    const { addAlert } = useAlerts();


    // State to store all public countries
    const [countries, setCountries] = useState([])

    // State to store all public currencies
    const [currencies, setCurrencies] = useState([])


    // Store business informations here
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phone, setPhone] = useState("")
    const [country, setCountry] = useState("")
    const [currency, setCurrency] = useState("")


    // Get all public countries
    const { data: clubData, loading: clubLoading } = GetClubByAuthUser();
    useEffect(() => {
        setName(clubData?.data?.name ?? "")
        setEmail(clubData?.data?.email ?? "")
        setCountry(clubData?.data?.country ?? "")
        setCurrency(clubData?.data?.currency ?? "")


        // set phone number
        const phoneNumber_ = clubData?.data?.phone ?? '';
        const phone_ = phoneNumber_?.split(" ");
        setPhoneCode(phone_?.[0] ?? "")
        setPhone(phone_?.[1] ?? "")

    }, [clubData])


    // Get all public countries
    const { data: countriesData, loading: countriesLoading } = GetAllPublicCountries();
    useEffect(() => {
        setCountries(countriesData?.data ?? [])
    }, [countriesData])


    // Get all public currencies
    const { data: currenciesData, loading: currenciesLoading } = GetAllPublicCurrencies();
    useEffect(() => {
        setCurrencies(currenciesData?.data ?? [])
    }, [currenciesData])


    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])


    // submit
    const saveBusinessInfo = async () => {

        const data = {
            name,
            email,
            phone: isNull(phone) ? "" : `${phoneCode} ${phone}`,
            country,
            currency,
        };

        setErrors([])
        setIsLoading(true)

        const { is_ok, message, type, errors, data: resData } = await UpdateClubInformations(data);


        // save currency cookie
        if(is_ok) {
            setCookie('currency', resData["currencyCode"] || 'USD')
        }

        
        setIsLoading(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }
    }

    // render currency name
    const renderCountryName = (currency = {}) => {
        const currentLang = i18next?.language || "en";

        if (currentLang === "ar") {
            return currency?.ar_name;
        } else if (currentLang === "en") {
            return currency?.en_name;
        } else if (currentLang === "fr") {
            return currency?.fr_name;
        } else {
            return "#";
        }
    }

    // render country name
    const renderCurrencyName = (country = {}) => {
        const currentLang = i18next?.language || "en";

        if (currentLang === "ar") {
            return country?.ar_name;
        } else if (currentLang === "en") {
            return country?.en_name;
        } else if (currentLang === "fr") {
            return country?.fr_name;
        } else {
            return "#";
        }
    }

    return (
        <div className="w-full max-w-xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="w-full flex flex-col gap-2 text-center">
                <h2 className="textHeader-l">
                    {t("Club Info")}
                </h2>
                <p className="textBody-s3">{t("Add basic details about your club")}</p>
            </div>

            {clubLoading ? <ClubSuspense /> :
                <div className="mt-6 mb-10 w-full bg-white border border-primaryBorder rounded-lg px-2 md:px-4 py-4 flex flex-col gap-4">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Name")}</span>
                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            className="p-3 rounded-lg border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80"
                            placeholder="Name"
                        />
                        {!isNull(errors.name) && <p className="textBody-s2 text-red-600">{errors.name}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Email")}</span>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            className="p-3 rounded-lg border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80"
                            placeholder="Email Adress"
                        />
                        {!isNull(errors.email) && <p className="textBody-s2 text-red-600">{errors.email}</p>}
                    </label>

                    <PhoneInput
                        title={t("Phone number")}
                        placeholder={""}
                        phoneNumber={phone}
                        changePhoneNumber={(p) => setPhone(p)}
                        phoneCode={phoneCode}
                        changePhoneCode={(c) => setPhoneCode(c)}
                        error={errors.phone ?? ''}
                    />

                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Country")}</span>
                        <select
                            value={country}
                            onChange={(e) => setCountry(n(e.target.value))}
                            className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                            {countriesLoading
                                ? <option value="" disabled>{t("Loading...")}</option>
                                : (
                                    <>
                                        <option value="" disabled></option>
                                        {countries?.map((country) => (
                                            <option key={country.id} value={country.id}>{renderCountryName(country)}</option>
                                        ))}
                                    </>
                                )}
                        </select>
                        {!isNull(errors.country) && <p className="textBody-s2 text-red-600">{errors.country}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-s1">{t("Currency")}</span>
                        <select
                            value={currency}
                            onChange={(e) => setCurrency(n(e.target.value))}
                            className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                            {currenciesLoading
                                ? <option value="" disabled>{t("Loading...")}</option>
                                : (
                                    <>
                                        <option value="" disabled></option>
                                        {currencies?.map((currency) => (
                                            <option key={currency.id} value={currency.id}>{`${renderCurrencyName(currency)} (${currency.code})`}</option>
                                        ))}
                                    </>
                                )}
                        </select>
                        {!isNull(errors.currency) && <p className="textBody-s2 text-red-600">{errors.currency}</p>}
                    </label>

                    <Button
                        name={t("Save Changes")}
                        onClick={saveBusinessInfo}
                        disabled={isLoading}
                        isLoading={isLoading}
                        styleType="1"
                    />
                </div>}
        </div>
    )
}