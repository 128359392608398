import { useEffect } from "react";
import ModalLayout from "../../../../layouts/ModalLayout";
import Content from "./Content";
import SideBar from "./SideBar";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { paramsList } from "../../../../Helpers/utils";


export default function ClientDetails({ clientData, close, refresh, showNotification }) {

    const { t } = useTranslation()


    //Start------------------------------ ContentSection ------------------------------//
    const [searchParams, setSearchParams] = useSearchParams();
    const queryName = paramsList()['contentSection'];

    const addSectionQuery = (val_) => {
        searchParams.set(queryName, val_);
        setSearchParams(searchParams);
    }

    const getSectionQuery = () => {
        return searchParams.get(queryName) ?? '';
    };

    const contentSection = getSectionQuery();
    //End------------------------------ ContentSection ------------------------------//



    // scroll to the top when 'contentSection' has changed
    useEffect(() => {
        const el = document.getElementById('client-details-modal');
        el.scrollTo(0, 0);
    }, [contentSection])


    const linksList = [
        {
            label: t('Overview'),
            slug: 'overview',
        }, {
            label: t('Notes'),
            slug: 'notes',
        }, {
            label: t('Payments History'),
            slug: 'payments',
        }, {
            label: t('Client Details'),
            slug: 'details',
        }
    ];

    return (
        <ModalLayout
            className={"fixed h-dvh w-full lg:w-[800px] ltr:right-0 rtl:left-0 top-0 z-[1011]"}
            close={close}
        >
            <div className="shadow-lg bg-white animation-01 animation-04 w-full h-full">
                <div className="grid lg:grid-cols-[250px_1fr] grid-cols-1 h-full">
                    <SideBar
                        clientData={clientData}
                        contentSection={contentSection}
                        setContentSection={addSectionQuery}
                        closeModal={close}
                    />

                    {/* SideBar for Mobile */}
                    {['pay'].includes(contentSection)
                        ? <div className="lg:hidden border-t border-primaryBorder/60"></div>
                        : <div className="lg:hidden w-full sticky top-0">
                            <div className="w-full overflow-x-auto bg-white border-y border-primaryBorder/60 md:px-4 px-2 flex items-center scrollBar0">
                                {linksList?.map((link, index) => {

                                    const chackIsActive = contentSection === link.slug;

                                    return (
                                        <button
                                            key={index}
                                            onClick={() => addSectionQuery(link.slug)}
                                            className={`px-3 py-3 flex items-center whitespace-nowrap border-b ${chackIsActive ? 'text-primaryTextColor border-primary textBody-xs1' : 'text-primaryTextColor/70 border-transparent textBody-xs2'}`}>
                                            {link.label}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    }

                    <Content
                        clientData={clientData}
                        contentSection={contentSection}
                        setContentSection={addSectionQuery}
                        closeModal={close}
                        refreshClients={refresh}
                        showNotification={showNotification}
                    />
                </div>
            </div>
        </ModalLayout>
    )
}