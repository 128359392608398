import { useState } from "react";
import Heading from "../../components/Pages/Subscriptions/Heading";
import SubscriptionsList from "../../components/Pages/Subscriptions/SubscriptionsList";
import CreateSubscription from "../../components/Pages/Subscriptions/CreateSubscription";
import { useAlerts } from "../../context/AlertsContext";
import { isNull, n, paramsList } from "../../Helpers/utils";
import UpdateSubscription from "../../components/Pages/Subscriptions/UpdateSubscription";
import SubscriptionsSuspense from "../../components/SuspensePages/SubscriptionsSuspense";
import Pagination from "../../components/Pagination";
import { DeleteSubscriptionById, GetAllSubscriptions } from "../../lib/api";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";


const Subscriptions = () => {

    const { t } = useTranslation()

    const [pageNumber, setPageNumber] = useState(1)

    const paramsData = { page: pageNumber };

    // Get subscriptions list
    const { data, loading, refresh } = GetAllSubscriptions(paramsData);


    // Call alerts provider
    const { addAlert } = useAlerts();


    //Start------------------------------ Create New Subscription Modal ------------------------------//

    const [searchParams, setSearchParams] = useSearchParams();
    const queryName = paramsList()['action'];
    const queryName2 = paramsList()['id'];

    const addActionQuery = () => {
        searchParams.set(queryName, 'add');
        setSearchParams(searchParams);
    }

    const removeActionQuery = () => {
        searchParams.delete(queryName);
        setSearchParams(searchParams);
    }

    const getActionQuery = () => {
        return searchParams.get(queryName) ?? '';
    };

    const showAddNewModal = getActionQuery() === "add";

    //End------------------------------ Create New Subscription Modal ------------------------------//



    //Start------------------------------ Edit Subscription Modal ------------------------------//

    const addAction2Query = (v = '') => {
        searchParams.set(queryName, 'edit');
        searchParams.set(queryName2, n(v));
        setSearchParams(searchParams);
    }

    const removeAction2Query = () => {
        searchParams.delete(queryName);
        searchParams.delete(queryName2);
        setSearchParams(searchParams);
    }

    const getAction2Query = () => {
        const actionVal = searchParams.get(queryName) ?? '';
        const sportIdVal = searchParams.get(queryName2) ?? '';
        return !isNull(actionVal) && !isNull(sportIdVal) ? sportIdVal : '';
    };

    const selectedSubscriptionID = getAction2Query();

    // find selected item by "selectedSubscriptionID"
    const selectedItem = !isNull(selectedSubscriptionID) ? data?.data?.data?.find(i => i.id === n(selectedSubscriptionID)) : {};

    //End------------------------------ Edit Subscription Modal ------------------------------//




    // handle remove subscription
    const handleRemoveSubscriptionPlan = async (id) => {
        if (isNull(id)) return;

        const { is_ok, message, type } = await DeleteSubscriptionById({ id });

        if (type === "validation") {
            addAlert(t('Something was wrong!'),'error');
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh()
        }
    }

    return (
        <div className="w-full max-w-5xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <Heading
                totalRows={data?.data?.total ?? 0}
                openModal={addActionQuery}
                paramsData={paramsData}
                canExport={true}
            />

            {loading
                ? <SubscriptionsSuspense />
                : <SubscriptionsList
                    subscriptions={data?.data?.data ?? []}
                    selectItem={addAction2Query}
                />
            }

            <Pagination
                className="mb-20 mt-4 py-4 border-t border-primary/10"
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />

            {/* Create */}
            {showAddNewModal &&
                <CreateSubscription
                    closeModal={removeActionQuery}
                    refresh={refresh}
                />
            }

            {!isNull(selectedItem) && (
                <UpdateSubscription
                    selectedItem={selectedItem}
                    closeModal={() => removeAction2Query("")}
                    refresh={refresh}
                    handleRemove={handleRemoveSubscriptionPlan}
                    canRemove={true}
                />
            )}
        </div>
    )
}
export default Subscriptions;