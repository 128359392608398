import React, { useMemo, useReducer } from "react";
import { AddNew4, ArrowBottom1, ArrowTop1, Calendar, Spinner } from "../../../../../icons";
import dayjs from "../../../../../lib/dayjs";
import { isNull } from "../../../../../Helpers/utils";
import { GetPaidAndUnPaidMonthsByClientId } from "../../../../../lib/api";
import { useTranslation } from "react-i18next";


export default function Overview({ clientData, setContentSection }) {

    const { t } = useTranslation()

    const { id, created_at } = clientData;

    // Get paid/unPaid months
    const { data, loading } = GetPaidAndUnPaidMonthsByClientId({ client_id: id });


    return (
        <div className="min-h-screen border-l border-primaryBorder/60">
            {/* Notes Header */}
            <div className="max-lg:hidden sticky top-0 lg:py-4 py-2 lg:px-6 md:px-4 p-2 flex items-center justify-between border-b border-primaryBorder/50 bg-primary2 z-50">
                <h2 className="textHeader-s">{t("Overview")}</h2>
                <button
                    onClick={() => setContentSection('pay')}
                    className="bg-transparent hover:bg-primaryBorder/30 lg:border-[1.5px] border border-primaryBorder text-primaryTextColor rounded-lg py-1 px-3 transition-all duration-100 ease-in active:scale-[.99] flex items-center gap-1 lg:textBody-m1 textBody-s1">
                    {t("Pay Now")}
                    <AddNew4 className="w-4 h-4" />
                </button>
            </div>
            <div className="">
                {loading
                    ? <Suspense />
                    : <RenderMonths data={data?.data?.months ?? []} joinedAt={created_at} />
                }
            </div>
        </div>
    )
}


const RenderMonths = ({ data, joinedAt }) => {

    const get12MonthsByYear = (year) => {
        const months = [];
        for (let month = 0; month < 12; month++) {
            months.push({
                month: dayjs(`${year}-${month + 1}-01`).format('YYYY-MM-DD'),
            });
        }
        return months;
    };

    const initialState = {
        currentYear: dayjs().year(),
        months: get12MonthsByYear(dayjs().year()),
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_YEAR':
                return {
                    ...state,
                    currentYear: action.payload,
                    months: get12MonthsByYear(action.payload),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const months = useMemo(() => {
        const prevYear__ = get12MonthsByYear(state.currentYear - 2);
        const prevYear_ = get12MonthsByYear(state.currentYear - 1);
        const currentYear_ = get12MonthsByYear(state.currentYear);
        const nextYear_ = get12MonthsByYear(state.currentYear + 1);
        const nextYear__ = get12MonthsByYear(state.currentYear + 2);
        const nextYear___ = get12MonthsByYear(state.currentYear + 3);

        return [...prevYear__, ...prevYear_, ...currentYear_, ...nextYear_, ...nextYear__, ...nextYear___].reverse();
    }, [state.currentYear]);

    const navigateToPrevYear = () => {
        dispatch({ type: 'SET_YEAR', payload: state.currentYear - 1 });
    };

    const navigateToCurrentYear = () => {
        dispatch({ type: 'SET_YEAR', payload: dayjs().year() });
    };

    const navigateToNextYear = () => {
        dispatch({ type: 'SET_YEAR', payload: state.currentYear + 1 });
    };


    return (
        <div className="">
            <div className="lg:py-2 py-2 lg:px-6 md:px-4 p-2 border-b border-primaryBorder/60 flex items-center gap-1 justify-between">
                <div className="flex items-center gap-1">
                    <button
                        onClick={navigateToPrevYear}
                        className="p-2 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <ArrowBottom1 className="w-5 h-5" />
                    </button>
                    <button
                        onClick={navigateToNextYear}
                        className="p-2 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                        <ArrowTop1 className="w-5 h-5" />
                    </button>
                </div>
                <button
                    onClick={navigateToCurrentYear}
                    className="p-2 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                    <Calendar className="w-5 h-5" />
                </button>
            </div>
            <div className="grid grid-cols-6">
                {months.map((d, index) => {
                    const isPaidMonth = !isNull(data) ? data?.some(i => dayjs(i.month).isSame(dayjs(d.month), 'month')) : false;
                    const isCurrentMonth = dayjs(d.month).isSame(dayjs(), 'month');
                    const isJoinedAtDate = dayjs(d.month).isSame(dayjs(joinedAt), 'month');

                    return (
                        <div key={index} className="relative">
                            <div
                                className={`py-4 lg:px-4 px-2 border-r border-b border-primaryBorder/60 flex flex-col gap-1 select-none lg:textBody-xs2 textBody-xs3 whitespace-nowrap overflow-hidden ${isPaidMonth ? 'bg-green-500/5 cursor-pointer' : 'bg-white cursor-context-menu'}`}>
                                <span className="textBody-xs2">{dayjs(d.month).format('MMMM')}</span>
                                <span className={`textBody-2xs3 ${isCurrentMonth ? 'bg-primary rounded-md text-white px-1' : ''}`}>
                                    {dayjs(d.month).format('MM, YYYY')}
                                </span>
                            </div>
                            <div className="absolute top-1 ltr:right-1 rtl:left-1 flex flex-col gap-2">
                                {isPaidMonth &&
                                    <div className="group relative w-2 h-2 rounded-full bg-green-500 shadow-md">
                                        <span className="hidden group-hover:block absolute top-0 ltr:right-2 rtl:left-2 bg-black/80 text-white px-1.5 py-0.5 rounded-2xl textBody-2xs3">Paid</span>
                                    </div>
                                }
                                {isJoinedAtDate &&
                                    <div className="group relative w-2 h-2 rounded-full bg-primary shadow-md">
                                        <span className="hidden group-hover:block absolute top-0 ltr:right-2 rtl:left-2 bg-black/80 text-white px-1.5 py-0.5 rounded-2xl textBody-2xs3">Joined</span>
                                    </div>
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};


const Suspense = () => {
    return (
        <div className="p-6 min-h-96 flex items-center justify-center">
            <Spinner className="w-10 h-10 animate-spin" />
        </div>
    )
}