import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function AuthLayout() {

    const { isAuth } = useAuth()

    const navigate = useNavigate()

    /**
     * redirect auth users to the home page
     */
    useEffect(() => {
        const checkAuthStatus = () => {
            if (isAuth) {
                navigate('/')
            }
        };

        checkAuthStatus();
    }, [isAuth, navigate])
    

    return (
        <main>
            <Outlet />
        </main>
    )
}