import { useTranslation } from "react-i18next";
import { isNull, n } from "../../../../Helpers/utils";
import { CreateNewSubscription } from "../../../../lib/api";
import FormModal from "../../../Shared/FormModal";
import { useState } from "react";
import { useCurrency } from "../../../../hooks/useCurrency";
import { useAlerts } from "../../../../context/AlertsContext";


export default function CreateSubscription({ closeModal, refresh }) {

    const { t } = useTranslation()
    
    const { addAlert } = useAlerts();

    const { Currency } = useCurrency()

    const [price, setPrice] = useState(100)
    const [months, setMonths] = useState(1)
    const [status, setStatus] = useState("active")

    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)


    // handle click 'saveNewSubscription'
    const saveNewSubscription = async () => {

        const data = {
            price,
            months,
            status,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await CreateNewSubscription(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refresh()
            closeModal();
        }
    }


    return (
        <FormModal
            close={closeModal}
            title={t('Add new subscription')}
            save={saveNewSubscription}
            isDisabled={inProgress}
            isLoading={inProgress}
        >
            <div className="w-full grid grid-cols-1 gap-6">
                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">{t("Price")}</span>
                    <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.price) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                        <input
                            type="number"
                            className={`w-full p-3 outline-none`}
                            onChange={(e) => setPrice(n(e.target.value))}
                            value={price}
                        />
                        <div className="flex items-center justify-center w-24 border-l border-primaryBorder/60 text-secondaryTextColor select-none">{Currency}</div>
                    </div>
                    {!isNull(errors.price) && <p className="textBody-s2 text-red-600">{errors.price}</p>}
                </label>

                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">{t("Duration")}</span>
                    <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.price) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                        <select
                            value={months}
                            onChange={(e) => setMonths(n(e.target.value))}
                            className={`w-full p-3 outline-none appearance-none`}
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                        </select>
                        <div className="flex items-center justify-center w-24 border-l border-primaryBorder/60 text-secondaryTextColor select-none">
                            {months > 1 ? t('Months') : t('Month')}
                        </div>
                    </div>
                    {!isNull(errors.months) && <p className="textBody-s2 text-red-600">{errors.months}</p>}
                </label>

                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">{t("Status")}</span>
                    <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.status) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                        <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            className={`w-full p-3 outline-none appearance-none`}
                        >
                            <option value="active">{t("active")}</option>
                            <option value="inactive">{t("inactive")}</option>
                        </select>
                    </div>
                    {!isNull(errors.status) && <p className="textBody-s2 text-red-600">{errors.status}</p>}
                </label>
            </div>
        </FormModal>
    )
}