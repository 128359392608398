import { useState } from "react";
import Button from "../../../../Shared/Button";
import { isNull } from "../../../../../Helpers/utils";
import { SaveNewNote } from "../../../../../lib/api";
import { useTranslation } from "react-i18next";


export default function AddNewNote({ clientId, refreshNotes, addAlert }) {

    const { t } = useTranslation()


    const [noteText, setNoteText] = useState("");
    const [errors, setErrors] = useState({});
    const [inProgress, setInProgress] = useState(false);

    const sendRequest = async () => {
        setErrors({})
        setInProgress(true)

        const data = {
            client_id: clientId,
            note: noteText,
        };

        const { is_ok, message, type, errors } = await SaveNewNote(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            addAlert(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            refreshNotes();
            setNoteText("")
        }
    }

    return (
        <div className="py-4 lg:px-6 md:px-4 p-2 flex flex-col gap-2">
            <div className="p-4 bg-white border border-primaryBorder/50 rounded-lg flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                    <input
                        onChange={(e) => setNoteText(e.target.value)}
                        value={noteText}
                        placeholder={t("Type a note for this client...")}
                        type="text"
                        className="outline-none w-full py-3 px-4 textBody-s3 bg-white rounded-lg border border-primaryBorder/50 hover:border-primaryBorder/80 focus:border-primary transition-all duration-200 ease-in"
                    />
                    {!isNull(errors.note) && <p className="textBody-s2 text-red-600">{errors.note}</p>}
                </div>
                <div className="flex flex-row-reverse">
                    <Button
                        name={t("Save note")}
                        onClick={sendRequest}
                        disabled={inProgress}
                        isLoading={inProgress}
                        styleType="1"
                    />
                </div>
            </div>
        </div>
    )
}