import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        fallbackLng: 'en',
        detection: {
            order: [
                'cookie',
                'htmlTag',
                'localStorage',
                'sessionStorage',
                'navigator',
                'path',
                'subdomain'
            ],
            caches: [
                'cookie'
            ],
            lookupCookie: 'aratlyLocale',
            cookieOptions: {
                path: '/',
                domain: process.env.REACT_APP_COOKIES_URL,
            },
        },
        // debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        }
    });

export default i18n;