
import { Bar } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from "chart.js";
import GlobalSuspense from "../../SuspensePages/GlobalSuspense";


export default function StatisticsBar({ period, statsData, loading }) {

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const data = {
        labels: period,
        datasets: [
            {
                label: "Sales",
                data: statsData,
                backgroundColor: "#00a36d",
            }
        ],
    };

    const options = {
        indexAxis: "x",
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return loading ? <GlobalSuspense /> : (
        <div className="mt-6">
            <Bar data={data} options={options} />
        </div>
    )
}