
const Reports = () => {
    return (
        <div className="xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="">
                <i>Soon!</i>
            </div>
        </div>
    )
}
export default Reports;