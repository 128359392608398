import { useTranslation } from "react-i18next";
import { Clients, Subscriptions } from "../../../../icons";
import { useCurrency } from "../../../../hooks/useCurrency";

export default function SubscriptionsCard({ data, selectItem = () => { } }) {
    const { id, price, months, status, total_clients } = data;

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    const renderBgByStatus = (s) => {
        switch (s) {
            case 'active':
                return "text-[#1f8900] bg-[#e5fae0] border border-[#d2f6c9]";
            case 'inactive':
                return "text-[#acacac] bg-[#f2f2f2] border border-[#e9e9e9]";
            default:
                return "text-[#acacac] bg-[#f2f2f2] border border-[#e9e9e9]";
        }
    }

    return (
        <div onClick={() => selectItem(id)} className="bg-white border border-primaryBorder rounded-lg px-4 py-5 transition-all duration-200 ease-in cursor-pointer select-none">
            <div className="relative w-full flex flex-col gap-3">
                <Subscriptions className="w-6 h-6" />
                <span className="textBody-m1">{`${price} ${Currency}`}</span>
                <span className="textBody-s3 text-secondaryTextColor">{`${months} ${months > 1 ? t('Months') : t('Month')}`}</span>

                <div className="">
                    <span className={`${renderBgByStatus(status)} textBody-s1 rounded font-medium text-[13px] leading-[16px] py-1 px-2`}>
                        {status === "active" ? t("active") : t("inactive")}
                    </span>
                </div>

                <div className="absolute top-0 ltr:right-0 rtl:left-0 flex items-center gap-1">
                    <span className={`textBody-xs3 text-secondaryTextColor`}>{total_clients}</span>
                    <Clients className="w-5 h-5 text-secondaryTextColor" />
                </div>
            </div>
        </div>
    )
}