import CountUp from 'react-countup';
import GlobalSuspense from '../../SuspensePages/GlobalSuspense';
import { useTranslation } from 'react-i18next';


export default function StatisticsByNumbers({ data, loading, Currency }) {

    const { t } = useTranslation()

    const { total_unpaid_clients, total_paid_clients, total_paid_price, total_new_clients } = data;

    return loading ? <GlobalSuspense /> : (
        <div className="grid-cols-2 lg:gap-4 gap-2 grid h-full mt-4">
            <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                <h3 className='textBody-ml1'>{t("Paid clients")}</h3>
                <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in">
                    <CountUp delay={0} duration={1} end={total_paid_clients} />
                </span>
            </div>

            <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                <h3 className='textBody-ml1'>{t("Unpaid clients")}</h3>
                <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in">
                    <CountUp delay={0} duration={1} end={total_unpaid_clients} />
                </span>
            </div>

            <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                <h3 className='textBody-ml1'>{t("Paid price")}</h3>
                <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in whitespace-nowrap">
                    <CountUp delay={0} duration={1} end={total_paid_price} /> {Currency}
                </span>
            </div>

            <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                <h3 className='textBody-ml1'>{t("New Clients")}</h3>
                <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in whitespace-nowrap">
                    +<CountUp delay={0} duration={1} end={total_new_clients} />
                </span>
            </div>
        </div>
    )
}