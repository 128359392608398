import { useTranslation } from "react-i18next";
import ContactDetails from "../../components/Pages/Profile/ContactDetails";
import ProfileInformations from "../../components/Pages/Profile/ProfileInformations";
import Security from "../../components/Pages/Profile/Security";
import { useAlerts } from "../../context/AlertsContext";
import { useAuth } from "../../hooks/useAuth";
import { n } from "../../Helpers/utils";

export default function Profile() {

    const { t } = useTranslation()

    const { authUser } = useAuth();

    const { addAlert } = useAlerts();

    const setAllAlerts = (text, number) => {
        addAlert(text, n(number) === 1 ? 'success' : 'error')
    }

    return (
        <div className="bg-primary2/80 min-h-[inherit]">
            <div className="w-full max-w-3xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
                <div className="w-full">
                    <div className="w-full flex justify-between items-center">
                        {/* Text */}
                        <div className="flex flex-col gap-2">
                            <h2 className="flex items-center textHeader-l">
                                {t("Profile")}
                            </h2>
                            <p className="textBody-s3">{t("Manage all your personal informations.")}</p>
                        </div>
                    </div>
                </div>
                {/* Content */}
                <div className="mt-6 flex flex-col gap-8">

                    {/* personal info */}
                    <ProfileInformations
                        authUser={authUser}
                        setNotif={setAllAlerts}
                    />


                    {/* contact details */}
                    <ContactDetails
                        authUser={authUser}
                        setNotif={setAllAlerts}
                    />


                    {/* password */}
                    <Security
                        authUser={authUser}
                        setNotif={setAllAlerts}
                    />

                </div>
            </div>
        </div>
    )
}