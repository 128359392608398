import { useNavigate } from "react-router-dom";
import { isNull } from "../../../../../Helpers/utils";
import { AddNew1, Edit1, Hide1, Spinner } from "../../../../../icons";
import dayjs from "../../../../../lib/dayjs";
import { useTranslation } from "react-i18next";
import Confirmation from "../../../../Shared/Confirmation";
import { BlockCLientById } from "../../../../../lib/api";
import { useState } from "react";
import { useCurrency } from "../../../../../hooks/useCurrency";

export default function ClientDetailsSection({ clientData, closeModal, refreshClients, showNotification }) {

    const { t } = useTranslation()

    const { Currency } = useCurrency()

    const navigate = useNavigate()

    const { id, membership_number, firstname, lastname, email, phone, gender, sport, subscription, created_at, payment_day } = clientData;

    const moveToEditPage = () => navigate(`/clients/edit/${id}`);


    const [inProgress, setInProgress] = useState(false)
    const [showConfirmationModalToBlock, setShowConfirmationModalToBlock] = useState(false)


    const handleBlockCLient = async () => {

        setInProgress(true)

        const { is_ok, message, type } = await BlockCLientById({}, { id });

        setInProgress(false)

        if (type === "validation") {
            // 
        } else {
            showNotification(message ?? (is_ok ? t('Success!') : t('Something was wrong!')), is_ok ? 'success' : 'success');
        }

        if (is_ok) {
            refreshClients()
            closeModal()
        }
    }

    return (
        <>
            <div className="min-h-screen">
                <div className="max-lg:hidden sticky top-0 lg:py-4 py-2 lg:px-6 md:px-4 p-2 flex items-center justify-between border-b border-primaryBorder/50 bg-primary2">
                    <h2 className="textHeader-s">{t("Client Details")}</h2>
                    <button onClick={moveToEditPage} className="bg-transparent hover:bg-primaryBorder/30 lg:border-[1.5px] border border-primaryBorder text-primaryTextColor rounded-lg py-1 px-3 transition-all duration-100 ease-in active:scale-[.97] flex items-center gap-1 textBody-m1">
                        {t("Edit")}
                        <Edit1 className="w-4 h-4" />
                    </button>
                </div>

                <div className="py-4 lg:px-6 md:px-4 p-2">
                    <div className="p-4 bg-white border border-primaryBorder/50 rounded-lg grid lg:grid-cols-2 grid-cols-1 gap-2">
                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Name")}</label>
                            <span className="textBody-s3 text-secondaryTextColor line-clamp-1">{`${firstname} ${lastname}`}</span>
                        </div>

                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Gender")}</label>
                            {!isNull(gender)
                                ? <span className="textBody-s3 text-secondaryTextColor line-clamp-1">{gender}</span>
                                : <span onClick={moveToEditPage} className="textBody-s3 text-primary hover:text-primary/90 hover:underline cursor-pointer flex items-center gap-0.5">
                                    {t("Add")}
                                    <AddNew1 className="w-4 h-4" />
                                </span>
                            }
                        </div>

                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Email")}</label>
                            {!isNull(email)
                                ? <span className="textBody-s3 text-secondaryTextColor line-clamp-1">{email}</span>
                                : <span onClick={moveToEditPage} className="textBody-s3 text-primary hover:text-primary/90 hover:underline cursor-pointer flex items-center gap-0.5">
                                    {t("Add")}
                                    <AddNew1 className="w-4 h-4" />
                                </span>
                            }
                        </div>

                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Phone number")}</label>
                            {!isNull(phone)
                                ? <span className="textBody-s3 text-secondaryTextColor line-clamp-1">{phone}</span>
                                : <span onClick={moveToEditPage} className="textBody-s3 text-primary hover:text-primary/90 hover:underline cursor-pointer flex items-center gap-0.5">
                                    {t("Add")}
                                    <AddNew1 className="w-4 h-4" />
                                </span>
                            }
                        </div>

                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Sport")}</label>
                            <span className="textBody-s3 text-secondaryTextColor line-clamp-1">{sport?.name ?? ''}</span>
                        </div>

                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Subscription")}</label>
                            <span className="textBody-s3 text-secondaryTextColor line-clamp-1">
                                {`${subscription?.months} ${subscription?.months > 1 ? t('Months') : t('Month')} (${subscription?.price} ${Currency})`}
                            </span>
                        </div>

                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Membership Number")}</label>
                            <span className="textBody-s3 text-secondaryTextColor line-clamp-1">
                                <i className="textBody-xs3">{`#${membership_number}`}</i>
                            </span>
                        </div>

                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Payment Day")}</label>
                            <span className="textBody-s3 text-secondaryTextColor line-clamp-1">
                                <i className="textBody-xs3">{`${payment_day}`}</i>
                            </span>
                        </div>

                        <div className="flex flex-col mb-3 [&>*]:whitespace-nowrap">
                            <label className="textBody-s1">{t("Joined at")}</label>
                            <span className="textBody-s3 text-secondaryTextColor line-clamp-1">
                                {dayjs(created_at).format("D MMMM, YYYY")}
                                {" "}
                                (<i className="textBody-xs3">{dayjs(created_at).fromNow()}</i>)
                            </span>
                        </div>
                    </div>


                    <div className="mt-6 flex flex-col gap-2">
                        <h3 className="textBody-m1">{t("Settings")}</h3>
                        <div className="p-4 bg-white border border-primaryBorder/50 rounded-lg">
                            <div className="flex items-center justify-between">
                                <div className="flex flex-col gap-1">
                                    <label className="textBody-s1">{t("Block")}</label>
                                    <span className="textBody-s3">{t("Hide client until manually restored.")}</span>
                                </div>
                                <button
                                    disabled={inProgress}
                                    onClick={() => setShowConfirmationModalToBlock(true)}
                                    className="bg-primary/5 border border-primary/10 rounded-full p-2 hover:bg-primary/10 hover:border-primary/15">
                                    {!inProgress
                                        ? <Hide1 className="w-4 h-4" />
                                        : <Spinner className="w-4 h-4 animate-spin" />
                                    }
                                </button>
                            </div>

                            {/* <div className="my-3 border-b border-primaryBorder/60"></div> */}

                            {/* <div className="flex items-center justify-between">
                                <div className="flex flex-col gap-1">
                                    <label className="textBody-s1">Delete</label>
                                    <span className="textBody-s3">Permanently remove client and data.</span>
                                </div>
                                <button
                                    className="bg-primary/5 border border-primary/10 rounded-full p-2 hover:bg-primary/10 hover:border-primary/15">
                                    <Delete1 className="w-4 h-4" />
                                </button>
                            </div> */}

                            <div className="my-4 border-b border-primaryBorder/60"></div>

                            <div className="flex items-center justify-between">
                                <div className="flex flex-col gap-1">
                                    <label className="textBody-s1">{t("Edit Informations")}</label>
                                    <span className="textBody-s3">{t("Update client's details or information.")}</span>
                                </div>
                                <button
                                    onClick={moveToEditPage}
                                    className="bg-primary/5 border border-primary/10 rounded-full p-2 hover:bg-primary/10 hover:border-primary/15">
                                    <Edit1 className="w-4 h-4" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmationModalToBlock &&
                <Confirmation
                    onClose={() => setShowConfirmationModalToBlock(false)}
                    onConfirm={() => {
                        handleBlockCLient()
                    }}
                    headingLine={t("Block Client")}
                    message={t("Are you sure you want to block this client?")}
                    confirmationBtnName={t("Block")}
                />}
        </>
    )
}