import { n, renderImage } from "../../../../Helpers/utils";
import dayjs from "../../../../lib/dayjs";
import Avatar from "../../../Shared/Avatar";


export default function ClientCard1({ data = {}, handleSelectClient, Currency }) {

    const { membership_number, firstname, lastname, email, phone, avatar, total_paid_price, sport, created_at } = data;

    return (
        <tr onClick={() => handleSelectClient(n(membership_number))} className="[&>*]:px-2 [&>*]:py-5 border-t border-primary/10 cursor-pointer select-none transition-all duration-200 ease-in hover:bg-primary2">
            <td className="max-lg:hidden">
                <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${membership_number}`}</span>
            </td>
            <td>
                <div className="flex lg:items-center gap-2">
                    <Avatar
                        src={renderImage(avatar)}
                        alt={`${firstname} ${lastname}`}
                    />
                    <div className="flex flex-col">
                        <h3 className="lg:textBody-m3 textBody-s2">{`${firstname} ${lastname}`}</h3>
                        <span className="text-primary hover:text-primary/90 textBody-xs3 lg:hidden">{email ?? 'null'}</span>
                        <span className="text-primary hover:text-primary/90 textBody-xs3 lg:hidden">{phone ?? 'null'}</span>
                    </div>
                </div>
            </td>
            <td className="max-lg:hidden">
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{email ?? 'null'}</span>
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{phone ?? 'null'}</span>
                </div>
            </td>
            <td className="max-lg:hidden">
                <span className="lg:textBody-s3 textBody-xs3">{sport?.name ?? ''}</span>
            </td>
            <td className="max-lg:hidden">
                <span className="text-[#4CAF50] lg:textBody-s2 textBody-xs2 whitespace-nowrap">{`${total_paid_price ?? 0} ${Currency}`}</span>
            </td>
            <td className="ltr:text-right rtl:text-left">
                <span className="lg:textBody-xs3 textBody-2xs3">{dayjs(created_at).format("D MMMM, YYYY")} <span className="max-lg:hidden">({dayjs(created_at).fromNow()})</span></span>
            </td>
        </tr>
    )
}