import { useTranslation } from "react-i18next";
import { useExport } from "../../../../hooks/useExport";
import MobileDropDownMenu from "./MobileDropDownMenu";
import OptionsDropDown from "./OptionsDropDown";


export default function Heading({ totalRows, paramsData }) {

    const { t } = useTranslation()

    const { exportPaymentsAsPdf, isLoadingPdf } = useExport()

    return (
        <div className="w-full">
            <div className="w-full flex lg:justify-between lg:items-center max-lg:flex-col-reverse">
                {/* Text */}
                <div className="flex flex-col gap-1">
                    <h2 className="flex items-center textHeader-l">
                        {t("Payments")}
                        <span className="text-sm border border-primaryBorder bg-white text-secondaryTextColor py-1 px-4 flex w-fit rounded-3xl select-none mx-2 textBody-xs3">
                            {totalRows}
                        </span>
                    </h2>
                    <p className="textBody-s3">{t("View, filter and export the transactions and cash movement.")}</p>
                </div>

                {/* Buttons */}
                <div className="lg:block hidden">
                    <div className="flex items-center gap-2">
                        <OptionsDropDown
                            paramsData={paramsData}
                            exportPaymentsAsPdf={exportPaymentsAsPdf}
                            isLoadingPdf={isLoadingPdf}
                            totalRows={totalRows}
                        />
                    </div>
                </div>

                <MobileDropDownMenu
                    paramsData={paramsData}
                    exportPaymentsAsPdf={exportPaymentsAsPdf}
                    isLoadingPdf={isLoadingPdf}
                    totalRows={totalRows}
                />
            </div>
        </div>
    )
}