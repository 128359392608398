import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";
import { Check, Hide1, Spinner } from "../../icons";
import { saveNewClubPayment } from "../../lib/api";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { isNull } from "../../Helpers/utils";


export default function Checkout() {

    const { authUser } = useAuth();

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [inProgress, setInProgress] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)




    // savePaymentData
    const savePaymentData = async (data_) => {

        setInProgress(true)
        setSuccessMessage(false)
        setErrorMessage(false)

        const { is_ok } = await saveNewClubPayment(data_);

        setInProgress(false)

        if (is_ok) {
            setSuccessMessage(true)
            authUser.mutate()
        } else {
            setErrorMessage(true)
        }
    }

    useEffect(() => {
        if (!isNull(authUser?.data)) {
            if (authUser?.data?.role !== "owner") {
                navigate('/clients')
            }
        }
    }, [authUser, navigate])

    return inProgress ? <PaymentLoading /> : successMessage ? <SuccessMessage /> : errorMessage ? <ErrorMessage /> : (
        <div className="my-10 w-full max-w-xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            {/* Text */}
            <div className="w-full flex items-start gap-1 justify-between">
                <h2 className="flex items-center textHeader-l">
                    {t("Complete payment")}
                </h2>
                <div className="flex flex-col items-end">
                    <span className="textHeader-m text-[#52b035]">$10.00</span>
                    <span className="textBody-s2 text-secondaryTextColor/80">1 {t("month")}</span>
                </div>
            </div>

            {/* Content */}
            <div className="w-full mt-6 mx-auto flex flex-col">
                <PayPalScriptProvider
                    options={{
                        clientId: "Ach1xtrouga4iyRXhqtaORxsR61FyVrm8dKvRWEoYQ59nfR-WvfHDKRMuBr3JCyP9ZpvStf5LmyOZZGC",
                        currency: "USD",
                    }}
                >
                    <PayPalButtons
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        value: "10.00",
                                    },
                                }],
                            });
                        }}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                                const formData = {
                                    price: '10.00',
                                    currency: 'USD',
                                    months: 1,
                                    details: details
                                };

                                savePaymentData(formData)
                            });
                        }}
                        onCancel={() => { }}
                        onError={() => { }}
                    />
                </PayPalScriptProvider>
            </div>
        </div>
    )
}


const PaymentLoading = () => {
    const { t } = useTranslation()
    return (
        <div className="my-10 w-full max-w-xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="flex flex-col items-center justify-center gap-4 p-8 text-center">
                <Spinner className="w-8 h-8 animate-spin mx-auto" />
                <p className="textBody-l1">{t("Please wait!")}</p>
                <p className="textBody-s3">{t("Do not refresh the page while we process your payment.")}</p>
            </div>
        </div>
    )
}


const SuccessMessage = () => {
    const { t } = useTranslation()
    return (
        <div className="my-10 w-full max-w-xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="p-4 min-h-96 max-w-4xl mx-auto w-full">
                <div className="flex flex-col items-center justify-center gap-4 p-8 text-center">
                    <div className="w-fit h-fit p-4 bg-green-200 rounded-full mx-auto shadow-sm sm:shadow-md animation-03">
                        <div className="w-fit h-fit p-4 bg-green-300 rounded-full">
                            <div className="w-fit h-fit p-4 bg-green-400 rounded-full text-white">
                                <Check className="w-16 h-w-16" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 text-center animation-01">
                        <p className="textBody-l1">{t("Payment Successful")}</p>
                        <p className="textBody-s3">{t("Your payment is completed successful.")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


const ErrorMessage = () => {
    const { t } = useTranslation()
    return (
        <div className="my-10 w-full max-w-xl mx-auto xl:p-8 lg:p-6 sm:p-4 p-2">
            <div className="p-4 min-h-96 max-w-4xl mx-auto w-full">
                <div className="flex flex-col items-center justify-center gap-4 py-8 px-4 text-center">
                    <div className="w-fit h-fit p-4 bg-red-200 rounded-full mx-auto shadow-sm sm:shadow-md animation-03">
                        <div className="w-fit h-fit p-4 bg-red-300 rounded-full">
                            <div className="w-fit h-fit p-4 bg-red-400 rounded-full text-white">
                                <Hide1 className="w-16 h-w-16" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 text-center animation-01">
                        <p className="textBody-l1">{t("Payment Failed")}</p>
                        <p className="textBody-s3">{t("Something went wrong! Please contact us.")}</p>
                        <p className="mt-4">
                            <a
                                className="bg-primary text-white rounded-lg py-3 px-6"
                                href="mailto:contact@aratly.com?subject=Payment%20Failed&body=Dear%20Support%20Team,%0A%0AI%20attempted%20to%20make%20a%20payment%20on%20your%20website,%20but%20it%20seems%20the%20transaction%20has%20failed.%20Could%20you%20please%20assist%20me%20in%20resolving%20this%20issue?%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A">
                                contact@aratly.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}